import { __awaiter, __generator } from "tslib";
import { FolderService } from "@/services/models/document/folder";
var moduleFolder = {
    namespaced: true,
    state: {
        folder: [],
        loading: false,
    },
    getters: {
        getFolder: function (state) {
            return state.folder;
        },
        getLoading: function (state) {
            return state.loading;
        }
    },
    mutations: {
        setFolder: function (state, folder) {
            state.folder = folder;
        },
        setLoading: function (state, loading) {
            state.loading = loading;
        }
    },
    actions: {
        getFolder: function (_a, user_id) {
            var commit = _a.commit;
            return __awaiter(this, void 0, Promise, function () {
                var folder;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, FolderService.getByUserId(user_id)];
                        case 1:
                            folder = _b.sent();
                            commit("setFolder", folder);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        }
    }
};
export default moduleFolder;
