import CreateDispatchLocation from "@/components/dispatchLocation/CreateDispatchLocation.vue";
import TableDispatchLocation from "@/components/dispatchLocation/TableDispatchLocation.vue";
import TemplateAdministrator from "@/templates/TemplateAdministrator.vue";
import Vue from "vue";
export default Vue.extend({
    components: {
        TemplateAdministrator: TemplateAdministrator,
        TableDispatchLocation: TableDispatchLocation,
        CreateDispatchLocation: CreateDispatchLocation,
    },
});
