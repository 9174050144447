import { __awaiter, __generator, __spreadArrays } from "tslib";
import { GroupService } from '@/services/models/group/group';
// import { PurchaseRequestService } from "@/services/models/purchaseRequest/purchaseRequest";
// import { PurchaseRequestStateService } from '@/services/models/purchaseRequest/purchaseRequestState'
// import PurchaseRequest from "@/types/purchaseRequest/purchaseRequest";
import logoRedondoSSM from "@/other/base64Logo";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
// function numberWithCommas(x: number) {
// 	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
// }
export var generatePDFTickets = function (reportData) { return __awaiter(void 0, void 0, void 0, function () {
    var pdfMake, pdfFonts_1, title, group, tableTickets, docDefinition;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, import("pdfmake/build/pdfmake.js")];
            case 1:
                pdfMake = _a.sent();
                if (!(pdfMake.vfs == undefined)) return [3 /*break*/, 3];
                return [4 /*yield*/, import("pdfmake/build/vfs_fonts.js")];
            case 2:
                pdfFonts_1 = _a.sent();
                pdfMake.vfs = pdfFonts_1.pdfMake.vfs;
                _a.label = 3;
            case 3:
                console.log(reportData);
                if (!reportData.groupId) return [3 /*break*/, 5];
                return [4 /*yield*/, GroupService.getById(reportData.groupId)];
            case 4:
                group = _a.sent();
                title = "Reporte de tickets " + group.name + " " + moment(reportData.dateStart).format("DD-MM-YYYY") + " / " + moment(reportData.dateEnd).format("DD-MM-YYYY");
                return [3 /*break*/, 6];
            case 5:
                title = "Reporte de tickets " + reportData.user.name + " " + reportData.user.pather_lastname + " " + reportData.user.mother_lastname + " " + moment(reportData.dateStart).format("DD-MM-YYYY") + " / " + moment(reportData.dateEnd).format("DD-MM-YYYY");
                _a.label = 6;
            case 6:
                tableTickets = [];
                reportData.tickets.forEach(function (ticket) {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
                    //Autogenerado, ni idea que es esto
                    var responsable = [];
                    if (ticket.state != "solicitado" && ((_a = ticket.tickets_responsables) === null || _a === void 0 ? void 0 : _a.length)) {
                        responsable = [
                            { text: 'Responsable apertura', style: 'tableHeader' },
                            { text: ((_b = ticket.tickets_responsables[0].user) === null || _b === void 0 ? void 0 : _b.name) + " " + ((_c = ticket.tickets_responsables[0].user) === null || _c === void 0 ? void 0 : _c.pather_lastname) + " " + ((_d = ticket.tickets_responsables[0].user) === null || _d === void 0 ? void 0 : _d.mother_lastname) + " (" + moment(ticket.tickets_responsables[0].created_at).format("DD-MM-YYYY") + ")" },
                        ];
                    }
                    else {
                        responsable = [
                            { text: 'Responsable apertura', style: 'tableHeader' },
                            { text: "Sin responsable" },
                        ];
                    }
                    //Autogenerado, ni idea que es esto
                    var responsableClosing = [];
                    if ((ticket.state == "cerrado" || ticket.state == "cerrado permanentemente") && ((_e = ticket.tickets_responsables) === null || _e === void 0 ? void 0 : _e.length)) {
                        var lastResponsable = ticket.tickets_responsables[ticket.tickets_responsables.length - 1];
                        responsableClosing = [
                            { text: 'Responsable cierre', style: 'tableHeader' },
                            { text: ((_f = lastResponsable.user) === null || _f === void 0 ? void 0 : _f.name) + " " + ((_g = lastResponsable.user) === null || _g === void 0 ? void 0 : _g.pather_lastname) + " " + ((_h = lastResponsable.user) === null || _h === void 0 ? void 0 : _h.mother_lastname) + " (" + moment(lastResponsable.created_at).format("DD-MM-YYYY") + ")" },
                        ];
                    }
                    else {
                        responsableClosing = [
                            { text: 'Responsable cierre', style: 'tableHeader' },
                            { text: "Sin responsable" },
                        ];
                    }
                    tableTickets.push({
                        margin: [0, 10, 0, 0],
                        table: {
                            widths: ['*', '*'],
                            body: [
                                [
                                    { text: "Ticket #" + ticket.id, style: 'title', colSpan: 2, alignment: 'center' },
                                    {}
                                ],
                                [
                                    { text: 'Estado', style: 'tableHeader' },
                                    { text: "" + ticket.state },
                                ],
                                [
                                    { text: 'Solicitante', style: 'tableHeader' },
                                    { text: ((_j = ticket.user) === null || _j === void 0 ? void 0 : _j.name) + " " + ((_k = ticket.user) === null || _k === void 0 ? void 0 : _k.pather_lastname) + " " + ((_l = ticket.user) === null || _l === void 0 ? void 0 : _l.mother_lastname) },
                                ],
                                [
                                    { text: 'Fecha de solicitud', style: 'tableHeader' },
                                    { text: "" + moment(ticket.created_at).format("DD-MM-YYYY") },
                                ],
                                [
                                    { text: 'Título', style: 'tableHeader' },
                                    { text: "" + ticket.title },
                                ],
                                [
                                    { text: 'Descripción', style: 'tableHeader' },
                                    { text: "" + ticket.subject },
                                ],
                                responsable,
                                responsableClosing,
                            ],
                        }
                    });
                });
                console.log(tableTickets);
                docDefinition = {
                    pageMargins: [40, 30, 40, 60],
                    content: __spreadArrays([
                        {
                            columns: [
                                {
                                    image: logoRedondoSSM,
                                    width: 70,
                                },
                                [
                                    {
                                        text: title,
                                        style: 'titleHeader',
                                        margin: [0, 10, 40, 0]
                                    },
                                ],
                            ]
                        }
                    ], tableTickets),
                    styles: {
                        title: {
                            bold: true,
                            fontSize: 15,
                            color: 'white',
                            fillColor: '#EE3A3A'
                        },
                        tableHeader: {
                            bold: true,
                            fontSize: 11,
                            color: 'black',
                            fillColor: '#e4e4e4'
                        },
                        tableExample: {
                            alignment: "justify",
                            margin: [0, -150, 0, 15],
                        },
                        toTheRight: {
                            alignment: "right",
                        },
                        header: {
                            fontSize: 17,
                            alignment: "left",
                            bold: true,
                        },
                        titleHeader: {
                            fontSize: 18,
                            bold: true,
                            alignment: 'center'
                        },
                        subtitleHeader: {
                            fontSize: 9,
                            alignment: 'center'
                        }
                    },
                };
                // pdfMake.createPdf(docDefinition).download("Reporte.pdf");
                pdfMake.createPdf(docDefinition).open();
                return [2 /*return*/];
        }
    });
}); };
