import { __awaiter, __generator } from "tslib";
import Vue from "vue";
import { TicketFileService } from "@/services/models/ticket/ticketFile";
export default Vue.extend({
    name: "AddFileToTicket",
    props: {
        ticketId: {
            type: Number,
            required: true
        },
        loadTickets: {
            type: Function,
            required: true
        }
    },
    data: function () {
        return {
            loading: false,
            file: null,
        };
    },
    methods: {
        storeFile: function () {
            return __awaiter(this, void 0, Promise, function () {
                var formData;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.loading = true;
                            formData = new FormData();
                            formData.append("ticket_id", this.ticketId.toString());
                            formData.append("file", this.file);
                            formData.append("user_id", this.getCurrentUserLoggedIn().id.toString());
                            return [4 /*yield*/, TicketFileService.create(formData, {
                                    "Content-Type": "multipart/form-data",
                                })];
                        case 1:
                            _a.sent();
                            this.loadTickets();
                            this.$emit('changeDialog', false);
                            this.loading = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
});
