import { __awaiter, __extends, __generator } from "tslib";
import { BaseService } from './../../base';
var DocumentDetailFileService = /** @class */ (function (_super) {
    __extends(DocumentDetailFileService, _super);
    function DocumentDetailFileService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(DocumentDetailFileService, "entity", {
        get: function () {
            return 'document_detail_file';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DocumentDetailFileService, "responseName", {
        get: function () {
            return 'document_detail_file';
        },
        enumerable: false,
        configurable: true
    });
    DocumentDetailFileService.getByDocumentDetail = function (detail_id) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_document_detail_id/" + detail_id
                    })];
            });
        });
    };
    return DocumentDetailFileService;
}(BaseService()));
export { DocumentDetailFileService };
