import { __assign, __awaiter, __generator, __spreadArrays } from "tslib";
import { StablishmentService } from "@/services/models/stablishment";
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default Vue.extend({
    name: "InputSelectStablishment",
    data: function () {
        return {
            stablishment_id: null,
            stablishments: [],
            loadingStablishments: false,
        };
    },
    watch: {
        stablishment_id: function () {
            console.log(this.stablishment_id);
            this.setUsersFilterByStateAndStablishment({ stablishment_id: this.stablishment_id, stateUser: "Active" });
        }
    },
    mounted: function () {
        this.loadStablishments();
    },
    computed: __assign({}, mapGetters({
        users: "users/getUsersFilterByStateAndStablishment",
        loading: "users/getLoading",
    })),
    methods: __assign(__assign(__assign({}, mapMutations({
        setStablishmentId: "users/setStablishmentIdSelected",
    })), mapActions({
        setUsersFilterByStateAndStablishment: "users/getUsersFilterByStateAndStablishment",
    })), { loadStablishments: function () {
            return __awaiter(this, void 0, void 0, function () {
                var stablishmentsResponse, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.loadingStablishments = true;
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            this.stablishments.push({
                                id: 0,
                                name: "Todos",
                            });
                            return [4 /*yield*/, StablishmentService.getAll()];
                        case 2:
                            stablishmentsResponse = _a.sent();
                            this.stablishments = __spreadArrays(this.stablishments, stablishmentsResponse);
                            return [3 /*break*/, 4];
                        case 3:
                            error_1 = _a.sent();
                            console.log(error_1);
                            return [3 /*break*/, 4];
                        case 4:
                            this.loadingStablishments = false;
                            return [2 /*return*/];
                    }
                });
            });
        } }),
});
