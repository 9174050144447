var moduleToast = {
    namespaced: true,
    state: {
        show: false,
    },
    getters: {
        getRelogin: function (state) {
            return state.show;
        }
    },
    mutations: {
        setRelogin: function (state) {
            state.show = true;
        },
        resetRelogin: function (state) {
            state.show = false;
        }
    }
};
export default moduleToast;
