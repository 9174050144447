import Vue from "vue";
import FormCdp from "../cdpForm/FormCdp.vue";
export default Vue.extend({
    components: { FormCdp: FormCdp },
    props: {
        purchaseProp: {
            type: Object
        },
        add: {
            type: Function
        }
    },
    data: function () {
        return {
            dialog: false,
        };
    },
    methods: {
        addCdp: function () {
            this.add(this.$refs.formCdp.data);
            this.dialog = false;
        }
    }
});
