import { __assign, __awaiter, __generator } from "tslib";
import { UserService } from "@/services/models/user/user";
import Vue from "vue";
import { mapActions } from "vuex";
import UserInfo from "./UserInfo.vue";
import UserProfiles from "./UserProfiles.vue";
import { UserLocationService } from "@/services/models/user/userLocation";
import { UserModuleService } from "@/services/models/user/userModule";
import { UserModulePermissionService } from "@/services/models/user/userModulePermission";
import UserModules from "./UserModules.vue";
import compressImage from "@/others/compressFile";
export default Vue.extend({
    components: { UserInfo: UserInfo, UserProfiles: UserProfiles, UserModules: UserModules },
    data: function () {
        return {
            loading: false,
            user: {},
            usersLocations: [],
            usersModules: [],
            step: 1,
            invalidStep: 0,
        };
    },
    mounted: function () {
        this.getRoles();
        this.getStablishment();
    },
    methods: __assign(__assign({}, mapActions({
        getRoles: "roles/getRoles",
        getStablishment: "stablishments/getStablishments",
        getUsers: "users/getUsers",
        getUsersFilter: "users/getUsersFilterByState",
    })), { validateStep: function (isValid, step) {
            if (!isValid) {
                this.invalidStep = step;
            }
        },
        createUser: function () {
            return __awaiter(this, void 0, void 0, function () {
                var areFormsValid, user, avatarCompressed, formData, i, userLocation, i, userModule, userModuleCreated, j, permission, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.loading = true;
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 18, , 19]);
                            return [4 /*yield*/, this.validateMultipleForms()];
                        case 2:
                            areFormsValid = _a.sent();
                            if (!areFormsValid) {
                                this.loading = false;
                                return [2 /*return*/];
                            }
                            return [4 /*yield*/, UserService.create(__assign(__assign({}, this.user), { state: 'active', run: this.user.run.split(".").join("") }))];
                        case 3:
                            user = _a.sent();
                            if (!this.user.avatar) return [3 /*break*/, 6];
                            return [4 /*yield*/, compressImage(this.user.avatar)];
                        case 4:
                            avatarCompressed = _a.sent();
                            formData = new FormData();
                            console.log(avatarCompressed);
                            formData.append("file", avatarCompressed);
                            return [4 /*yield*/, UserService.updateAvatar(user.id, formData)];
                        case 5:
                            _a.sent();
                            _a.label = 6;
                        case 6:
                            i = 0;
                            _a.label = 7;
                        case 7:
                            if (!(i < this.usersLocations.length)) return [3 /*break*/, 10];
                            userLocation = this.usersLocations[i];
                            return [4 /*yield*/, UserLocationService.create(__assign(__assign({}, userLocation), { user_id: user.id }))];
                        case 8:
                            _a.sent();
                            _a.label = 9;
                        case 9:
                            i++;
                            return [3 /*break*/, 7];
                        case 10:
                            i = 0;
                            _a.label = 11;
                        case 11:
                            if (!(i < this.usersModules.length)) return [3 /*break*/, 17];
                            console.log('Insert en module');
                            userModule = this.usersModules[i];
                            return [4 /*yield*/, UserModuleService.create(__assign(__assign({}, userModule), { user_id: user.id }))];
                        case 12:
                            userModuleCreated = _a.sent();
                            if (!(userModule.module && userModule.permissions)) return [3 /*break*/, 16];
                            j = 0;
                            _a.label = 13;
                        case 13:
                            if (!(j < userModule.permissions.length)) return [3 /*break*/, 16];
                            permission = userModule.permissions[j];
                            return [4 /*yield*/, UserModulePermissionService.create({
                                    id: 0,
                                    user_module_id: userModuleCreated.id,
                                    permission_id: permission.id,
                                })];
                        case 14:
                            _a.sent();
                            _a.label = 15;
                        case 15:
                            j++;
                            return [3 /*break*/, 13];
                        case 16:
                            i++;
                            return [3 /*break*/, 11];
                        case 17:
                            this.displayToast({
                                type: "success",
                                message: "El usuario se ha creado correctamente",
                            });
                            this.$emit("changeDialog", false);
                            // this.dialog = false;
                            this.getUsersFilter({ state: "active", stablishment: JSON.parse(window.localStorage.getItem("user") || "{}").stablishment_id });
                            this.getUsers();
                            return [3 /*break*/, 19];
                        case 18:
                            error_1 = _a.sent();
                            this.displayToast({
                                type: "error",
                                message: "Lo sentimos, ha ocurrido un error. Favor intentar más tarde",
                            });
                            console.log(error_1);
                            return [3 /*break*/, 19];
                        case 19:
                            this.loading = false;
                            return [2 /*return*/];
                    }
                });
            });
        } }),
    watch: {
        invalidStep: function () {
            if (this.invalidStep != 0) {
                this.step = this.invalidStep;
            }
        },
    },
});
