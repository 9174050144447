import { __extends } from "tslib";
import { BaseService } from './../../base';
var HeaderService = /** @class */ (function (_super) {
    __extends(HeaderService, _super);
    function HeaderService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(HeaderService, "entity", {
        get: function () {
            return 'header';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(HeaderService, "responseName", {
        get: function () {
            return 'header';
        },
        enumerable: false,
        configurable: true
    });
    return HeaderService;
}(BaseService()));
export { HeaderService };
