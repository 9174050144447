import { __awaiter, __extends, __generator } from "tslib";
import { BaseService } from './../../base';
var UserModuleService = /** @class */ (function (_super) {
    __extends(UserModuleService, _super);
    function UserModuleService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(UserModuleService, "entity", {
        get: function () {
            return 'user_module';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserModuleService, "responseName", {
        get: function () {
            return 'user_module';
        },
        enumerable: false,
        configurable: true
    });
    UserModuleService.getByUserId = function (userId) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_user_id/" + userId
                    })];
            });
        });
    };
    return UserModuleService;
}(BaseService()));
export { UserModuleService };
