import { __awaiter, __generator } from "tslib";
import { TicketCloseService } from "@/services/models/ticket/ticketClose";
import Vue from "vue";
export default Vue.extend({
    props: {
        itemProp: {
            type: Object,
        },
    },
    data: function () {
        return {
            dialog: false,
            ticketClose: []
        };
    },
    methods: {
        loadData: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _a = this;
                            return [4 /*yield*/, TicketCloseService.getByTicketId(this.itemProp.id)];
                        case 1:
                            _a.ticketClose = _b.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    },
    watch: {
        dialog: function () {
            if (this.dialog) {
                this.displayLoader(true);
                this.loadData();
                this.displayLoader(false);
            }
        }
    },
});
