import Vue from "vue";
import ResetPasswordContent from "./ResetPasswordContent.vue";
export default Vue.extend({
    props: {
        user: {
            required: true,
            type: Object,
        },
    },
    components: { ResetPasswordContent: ResetPasswordContent },
    data: function () {
        return {
            dialog: false,
        };
    },
});
