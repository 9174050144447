import { __assign } from "tslib";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import GroupButtons from "../buttonsPurchaseRequest/GroupButtons.vue";
import DetailTablePurchaseRequest from "../DetailTablePurchaseRequest.vue";
export default Vue.extend({
    components: {
        DetailTablePurchaseRequest: DetailTablePurchaseRequest,
        GroupButtons: GroupButtons,
    },
    data: function () {
        return {
            page: 1,
            dataStateStep: {
                user_id: this.getCurrentUserLoggedIn().id,
                state: "pendiente",
                step: "authorizer",
            },
            headers: [
                {
                    text: "Id",
                    sortable: false,
                    value: "id",
                },
                {
                    text: "Estado",
                    sortable: false,
                    value: "state",
                },
                {
                    text: "Creación",
                    sortable: false,
                    value: "created_at",
                },
                {
                    text: "Modificación",
                    sortable: false,
                    value: "updated_at",
                },
                {
                    text: "Acciones",
                    sortable: false,
                    align: "center",
                    value: "actions",
                },
            ],
        };
    },
    computed: __assign({}, mapGetters({
        getPending: "authorizer/getPending",
        getLoading: "authorizer/getLoading",
    })),
    methods: __assign(__assign({}, mapActions({
        setPending: "authorizer/getPending",
        setReady: "authorizer/getReady",
    })), { checkFinalAuthorizer: function () {
            var dataSend = { step: 'supplyManager', state: 'pendiente' };
            if (this.getCurrentUserLoggedIn().is_final_authorizer) {
                dataSend = { step: 'supplyManager', state: 'pendiente' };
            }
            else {
                dataSend = { step: 'authorizer', state: 'pendiente' };
            }
            return dataSend;
        },
        changePage: function () {
            this.$store.commit("authorizer/setPagePending", this.page);
            this.setPending(__assign({}, this.dataStateStep));
        },
        recharge: function () {
            this.setPending({
                user_id: this.getCurrentUserLoggedIn().id,
                state: "pendiente",
                step: "authorizer",
            });
            this.setReady({
                user_id: this.getCurrentUserLoggedIn().id,
                state: "enviado",
                step: "authorizer",
            });
        } }),
    mounted: function () {
        this.changePage();
    },
});
