import Vue from "vue";
import Documentation from "@/components/sideMenu/Documentation.vue";
import Contact from "@/components/sideMenu/Contact.vue";
import Dispatch from "@/components/sideMenu/Dispatch.vue";
import DispatchLocation from "@/components/sideMenu/DispatchLocation.vue";
import Ticket from "@/components/sideMenu/Ticket.vue";
export default Vue.extend({
    components: {
        Documentation: Documentation,
        Contact: Contact,
        Dispatch: Dispatch,
        DispatchLocation: DispatchLocation,
        Ticket: Ticket,
    },
    data: function () {
        return {
            starter: [
                {
                    text: "Inicio",
                    icon: "fas fa-home",
                    to: { name: "opartesHome" },
                },
            ],
        };
    },
});
