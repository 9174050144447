import { __assign } from "tslib";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import EditDispatchLocation from "./EditDispatchLocation.vue";
export default Vue.extend({
    components: { EditDispatchLocation: EditDispatchLocation },
    data: function () {
        return {
            listLocations: [],
            headers: [
                {
                    text: "ID",
                    align: "start",
                    value: "id",
                    sortable: false,
                },
                { text: "Nombre", sortable: false, align: "start", value: "name" },
                {
                    text: "Fecha",
                    sortable: false,
                    align: "start",
                    value: "created_at",
                },
                { text: "Acciones", sortable: false, align: "start", value: "action" },
            ],
        };
    },
    computed: __assign({}, mapGetters({
        getDispatchLocation: "dispatchLocation/getDispatchLocation",
        getLoading: "dispatchLocation/getLoading",
    })),
    mounted: function () {
        this.setDispatchLocation(this.getCurrentUserLoggedIn().stablishment_id);
    },
    methods: __assign({}, mapActions({
        setDispatchLocation: "dispatchLocation/getDispatchLocation",
    })),
});
