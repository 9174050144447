import { __assign } from "tslib";
import ModalFirmDocument from "@/components/purchaseRequest/firmDocument/ModalFirmDocument.vue";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
export default Vue.extend({
    components: {
        ModalFirmDocument: ModalFirmDocument
    },
    props: {
        purchaseBuyerProps: {
            type: Object,
        },
    },
    data: function () {
        return {
            headers: [
                {
                    text: "Id",
                    sorteable: false,
                    value: "id",
                },
                {
                    text: "Comprador",
                    sorteable: false,
                    value: "user",
                },
                {
                    text: "Acciones",
                    sorteable: false,
                    value: "actions",
                },
            ],
        };
    },
    computed: __assign({}, mapGetters({
        getItems: 'cdp/getCdp',
        getLoading: 'cdp/getLoading'
    })),
    methods: __assign({}, mapActions({
        setItems: 'cdp/getCdp',
    })),
    mounted: function () {
        this.setItems(this.purchaseBuyerProps.id);
    },
});
