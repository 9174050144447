import Vue from "vue";
export default Vue.extend({
    props: {
        profile: {
            type: String,
            required: true,
        },
    },
    data: function () {
        return {
            document: [
                {
                    text: "Jefe Unidad",
                    icon: "fas fa-box",
                    to: { name: "unityManager" + this.profile },
                },
            ],
        };
    },
});
