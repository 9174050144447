import { __awaiter, __extends, __generator } from "tslib";
import { BaseService } from '../../base';
var DocumentStateService = /** @class */ (function (_super) {
    __extends(DocumentStateService, _super);
    function DocumentStateService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(DocumentStateService, "entity", {
        get: function () {
            return 'document_state';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DocumentStateService, "responseName", {
        get: function () {
            return 'document_state';
        },
        enumerable: false,
        configurable: true
    });
    /**
     *
     * @param document_id Number
     * @param user_id Number
     * @returns
     */
    DocumentStateService.getByDocumentIdUserId = function (document_id, user_id) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_document_id_user_id/" + document_id + "/" + user_id
                    })];
            });
        });
    };
    return DocumentStateService;
}(BaseService()));
export { DocumentStateService };
