import Vue from "vue";
export default Vue.extend({
    name: "FormFiles",
    data: function () {
        return {
            fileSelected: null,
            files: [],
            headers: [
                {
                    text: "name",
                    sortable: false,
                    align: "start",
                    value: "name",
                },
                {
                    text: "Acciones",
                    sortable: false,
                    align: "start",
                    value: "action",
                },
            ],
        };
    },
    methods: {
        addFile: function () {
            if (this.fileSelected) {
                this.files.push(this.fileSelected);
                this.fileSelected = null;
            }
        },
        removeFile: function (item) {
            this.files = this.files.filter(function (data) { return data != item; });
        },
    },
});
