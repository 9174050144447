import { __assign, __awaiter, __generator } from "tslib";
import { DispatchService } from "@/services/models/dispatch/dispatch";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
    props: {
        dispatchProps: {
            type: Object,
        },
    },
    data: function () {
        return {
            dialog: false,
        };
    },
    methods: __assign(__assign({}, mapActions({
        setDocument: "dispatch/getDispatch",
    })), { removeDispatch: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.displayLoader(true);
                            return [4 /*yield*/, DispatchService.remove(this.dispatchProps.id)];
                        case 1:
                            _a.sent();
                            this.setDocument(this.getCurrentUserLoggedIn().stablishment_id);
                            this.displayToast({
                                type: 'success',
                                message: 'Se eliminó el despacho con éxito'
                            });
                            this.displayLoader(false);
                            return [2 /*return*/];
                    }
                });
            });
        } }),
});
