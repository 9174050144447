import { __assign } from "tslib";
import Vue from "vue";
import GroupButtons from "@/components/purchaseRequest/tables/buttonsPurchaseRequest/GroupButtons.vue";
import DetailTablePurchaseRequest from '@/components/purchaseRequest/tables/DetailTablePurchaseRequest.vue';
import { mapActions, mapGetters } from "vuex";
export default Vue.extend({
    components: {
        GroupButtons: GroupButtons,
        DetailTablePurchaseRequest: DetailTablePurchaseRequest
    },
    data: function () {
        return {
            page: 1,
            headers: [
                {
                    text: "Id Solicitud",
                    sortable: false,
                    value: "purchase_request_id",
                },
                {
                    text: "Persona que lo asigno",
                    sortable: false,
                    value: "user_id",
                },
                {
                    text: "Estado",
                    sortable: false,
                    value: "state",
                },
                {
                    text: "Acciones",
                    sortable: false,
                    value: "actions",
                },
            ],
        };
    },
    computed: __assign({}, mapGetters({
        getBuyers: "buyer/getBuyerInProcess",
        getLoading: "buyer/getLoading",
    })),
    methods: __assign(__assign({}, mapActions({
        setBuyer: "buyer/getBuyerInProcess",
    })), { changePage: function () {
            this.$store.commit("buyer/setPageBuyerInProcess", this.page);
            this.setBuyer({
                userId: this.getCurrentUserLoggedIn().id,
                state: "en proceso",
            });
        } }),
    mounted: function () {
        this.changePage();
    },
});
