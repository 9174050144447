import { __awaiter, __extends, __generator } from "tslib";
import { BaseService } from './../../base';
var PurchaseBuyerStateService = /** @class */ (function (_super) {
    __extends(PurchaseBuyerStateService, _super);
    function PurchaseBuyerStateService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(PurchaseBuyerStateService, "entity", {
        get: function () {
            return 'purchase_buyer_state';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PurchaseBuyerStateService, "responseName", {
        get: function () {
            return 'purchase_buyer_state';
        },
        enumerable: false,
        configurable: true
    });
    PurchaseBuyerStateService.getByPurchaseBuyerIdUserId = function (purchaseBuyerId, userId) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_purchase_buyer_id_user_id/" + purchaseBuyerId + "/" + userId
                    })];
            });
        });
    };
    return PurchaseBuyerStateService;
}(BaseService()));
export { PurchaseBuyerStateService };
