import Vue from "vue";
import TableReaders from "./TableReaders.vue";
export default Vue.extend({
    components: { TableReaders: TableReaders },
    props: {
        detailProp: {
            type: Object,
        },
    },
    data: function () {
        return {
            menu: false,
        };
    },
});
