import { __assign } from "tslib";
import moment from "moment";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
export default Vue.extend({
    props: {
        itemProp: {
            type: Object,
        },
    },
    computed: __assign({}, mapGetters({
        getMessages: "ticketMessage/getTicketMessage",
        getLoading: "ticketMessage/getLoading"
    })),
    methods: __assign(__assign({}, mapActions({
        setMessage: "ticketMessage/getTicketMessage",
    })), { currentDate: function () {
            return moment().format("DD-MM-YYYY hh:mm");
        } }),
    mounted: function () {
        this.setMessage(this.itemProp.id);
    },
});
