import { __assign, __awaiter, __generator } from "tslib";
import Vue from "vue";
import { TicketService } from "@/services/models/ticket/ticket";
import { TicketFileService } from "@/services/models/ticket/ticketFile";
import FormFiles from "@/components/general/FormFiles.vue";
import FormTicket from "./FormTicket.vue";
import { mapActions } from "vuex";
import { TicketHistoryService } from "@/services/models/ticket/ticketHistory";
export default Vue.extend({
    name: "createTicket",
    components: {
        FormTicket: FormTicket,
        FormFiles: FormFiles,
    },
    props: {
        selectUser: {
            type: Boolean
        }
    },
    data: function () {
        return {
            tab: null,
            dialog: false,
        };
    },
    methods: __assign(__assign({}, mapActions({
        setTickets: "ticket/getTicketsUser",
        setTicketFilter: "ticket/getTicketFilter"
    })), { saveTicket: function () {
            return __awaiter(this, void 0, void 0, function () {
                var formTicket, formFile, response, i, formData, dataPost, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            formTicket = this.$refs.formTicket;
                            formFile = this.$refs.formFile;
                            if (!formTicket.validateForm()) {
                                this.displayToast({
                                    type: "warning",
                                    message: "Favor de llenar todos los campos requeridos",
                                });
                                return [2 /*return*/];
                            }
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 8, 9, 10]);
                            this.displayLoader(true);
                            return [4 /*yield*/, TicketService.create(formTicket.ticket)];
                        case 2:
                            response = _a.sent();
                            if (!formFile) return [3 /*break*/, 6];
                            if (!(formFile.files.length != 0)) return [3 /*break*/, 6];
                            i = 0;
                            _a.label = 3;
                        case 3:
                            if (!(i < formFile.files.length)) return [3 /*break*/, 6];
                            formData = new FormData();
                            formData.append("ticket_id", "" + response.id);
                            formData.append("file", formFile.files[i]);
                            formData.append("user_id", this.getCurrentUserLoggedIn().id.toString());
                            return [4 /*yield*/, TicketFileService.create(formData, {
                                    "Content-Type": "multipart/form-data",
                                })];
                        case 4:
                            _a.sent();
                            _a.label = 5;
                        case 5:
                            i++;
                            return [3 /*break*/, 3];
                        case 6:
                            dataPost = {
                                user_id: this.getCurrentUserLoggedIn().id,
                                ticket_id: response.id || 0,
                                state: "Ticket Generado",
                            };
                            return [4 /*yield*/, TicketHistoryService.create(dataPost)];
                        case 7:
                            _a.sent();
                            this.setTickets(this.getCurrentUserLoggedIn().id);
                            this.setTicketFilter();
                            this.displayToast({
                                type: "success",
                                message: "Ticket ingresado sin problemas",
                            });
                            this.dialog = false;
                            return [3 /*break*/, 10];
                        case 8:
                            error_1 = _a.sent();
                            console.log(error_1);
                            this.displayToast({
                                type: "error",
                                message: "Hubo un error al guardar, favor de reintentar",
                            });
                            return [3 /*break*/, 10];
                        case 9:
                            this.displayLoader(false);
                            return [7 /*endfinally*/];
                        case 10: return [2 /*return*/];
                    }
                });
            });
        } }),
});
