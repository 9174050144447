import { __awaiter, __generator } from "tslib";
import { TicketHistoryService } from "@/services/models/ticket/ticketHistory";
import Vue from "vue";
export default Vue.extend({
    props: {
        itemProp: {
            type: Object,
        },
    },
    data: function () {
        return {
            dialog: false,
            isLoading: false,
            listHistory: [],
            headers: [
                {
                    text: "Usuario",
                    sortable: false,
                    align: "start",
                    value: "user",
                },
                {
                    text: "Estado",
                    sortable: false,
                    align: "start",
                    value: "state",
                },
                {
                    text: "Fecha",
                    sortable: "false",
                    align: "start",
                    value: "created_at",
                },
            ],
        };
    },
    watch: {
        dialog: function () {
            if (this.dialog) {
                this.loadData();
            }
        },
    },
    methods: {
        loadData: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            this.isLoading = true;
                            _a = this;
                            return [4 /*yield*/, TicketHistoryService.getByTicketId(this.itemProp.id)];
                        case 1:
                            _a.listHistory = _b.sent();
                            this.isLoading = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
});
