import { __assign, __awaiter, __generator } from "tslib";
import { DocumentDetailService } from "@/services/models/document/documentDetail";
import { DocumentDetailReadService } from "@/services/models/document/documentDetailRead";
import { DocumentStateService } from "@/services/models/document/documentState";
import { DocumentStateLocationService } from "@/services/models/document/documentStateLocation";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
    props: {
        documentProp: {
            required: true,
            type: Object,
        },
    },
    data: function () {
        return {
            isLoading: false,
        };
    },
    methods: __assign(__assign({}, mapActions({
        loadTable: "tabsDocument/getLoadTable",
        getRequestedDocumentsNotRead: "documents/getRequestedDocumentsNotRead",
    })), { checkDocumentDetailRead: function () {
            return __awaiter(this, void 0, void 0, function () {
                var documentDetails, i, document, hasDocumentDetailRead, error_1;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 6, , 7]);
                            return [4 /*yield*/, DocumentDetailService.getByDocumentId(this.documentProp.id)];
                        case 1:
                            documentDetails = _a.sent();
                            i = 0;
                            _a.label = 2;
                        case 2:
                            if (!(i < documentDetails.length)) return [3 /*break*/, 5];
                            document = documentDetails[i];
                            hasDocumentDetailRead = false;
                            if (document.documents_details_reads) {
                                hasDocumentDetailRead = !!document.documents_details_reads.find(function (read) { return read.user_id == _this.getCurrentUserLoggedIn().id; });
                            }
                            if (!!hasDocumentDetailRead) return [3 /*break*/, 4];
                            return [4 /*yield*/, DocumentDetailReadService.create({
                                    user_id: this.getCurrentUserLoggedIn().id,
                                    document_detail_id: document.id,
                                    location_id: this.getUserLocation().id,
                                    id: 0,
                                })];
                        case 3:
                            _a.sent();
                            _a.label = 4;
                        case 4:
                            i++;
                            return [3 /*break*/, 2];
                        case 5: return [3 /*break*/, 7];
                        case 6:
                            error_1 = _a.sent();
                            throw new Error("Error al revisar document_detail_read - message: " + error_1);
                        case 7: return [2 /*return*/];
                    }
                });
            });
        },
        updateDocumentState: function () {
            return __awaiter(this, void 0, void 0, function () {
                var dataToUpdate, dataToUpdate, error_2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 5, , 6]);
                            if (!(this.getProfile() == "opartes")) return [3 /*break*/, 2];
                            dataToUpdate = __assign(__assign({}, this.documentProp.documents_states_locations[0]), { state_read: this.documentProp.documents_states_locations[0].state_read ==
                                    "no leido"
                                    ? "leido"
                                    : "no leido" });
                            return [4 /*yield*/, DocumentStateLocationService.update(dataToUpdate.id, dataToUpdate)];
                        case 1:
                            _a.sent();
                            return [3 /*break*/, 4];
                        case 2:
                            dataToUpdate = __assign(__assign({}, this.documentProp.documents_states[0]), { state_read: this.documentProp.documents_states[0].state_read == "no leido"
                                    ? "leido"
                                    : "no leido" });
                            return [4 /*yield*/, DocumentStateService.update(dataToUpdate.id, dataToUpdate)];
                        case 3:
                            _a.sent();
                            _a.label = 4;
                        case 4: return [3 /*break*/, 6];
                        case 5:
                            error_2 = _a.sent();
                            throw new Error("Error al actualizar el document_state - message: " + error_2);
                        case 6: return [2 /*return*/];
                    }
                });
            });
        },
        changeStateRead: function () {
            return __awaiter(this, void 0, void 0, function () {
                var dataRefresh, error_3;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 3, 4, 5]);
                            this.isLoading = true;
                            return [4 /*yield*/, this.updateDocumentState()];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, this.checkDocumentDetailRead()];
                        case 2:
                            _a.sent();
                            this.getRequestedDocumentsNotRead();
                            dataRefresh = {
                                user_id: this.getCurrentUserLoggedIn().id,
                                folder_id: this.$route.params.id,
                            };
                            this.displayToast({
                                type: "success",
                                message: "El documento se ha marcado correctamente como leído",
                            });
                            this.loadTable(dataRefresh);
                            this.isLoading = false;
                            return [3 /*break*/, 5];
                        case 3:
                            error_3 = _a.sent();
                            console.log(error_3);
                            this.displayToast({
                                type: "error",
                                message: "Lo sentimos, ha ocurrido un error al marcar como leído el documento",
                            });
                            return [3 /*break*/, 5];
                        case 4:
                            this.isLoading = false;
                            return [7 /*endfinally*/];
                        case 5: return [2 /*return*/];
                    }
                });
            });
        } }),
});
