import { __assign, __awaiter, __generator, __spreadArrays } from "tslib";
import Vue from "vue";
import ButtonCreateGroup from "./ButtonCreateGroup.vue";
import ButtonListUsers from "./addUserToGroup/ButtonListUsers.vue";
import ButtonListType from "./addTicketType/ButtonListType.vue";
import { StablishmentService } from "@/services/models/stablishment";
import { GroupService } from "@/services/models/group/group";
import { mapGetters } from "vuex";
import { SELECT_USER_STABLISHMENT } from "@/others/constants/permissions";
export default Vue.extend({
    name: "TableGroups",
    components: { ButtonCreateGroup: ButtonCreateGroup, ButtonListUsers: ButtonListUsers, ButtonListType: ButtonListType },
    data: function () {
        return {
            headers: [
                {
                    text: "Nombre",
                    sortable: false,
                    value: "name",
                },
                {
                    text: "Acciones",
                    sortable: false,
                    value: "actions",
                },
            ],
            stablishments: [],
            loadingStablishments: false,
            groups: [],
            loading: false,
            stablishmentId: 0,
            selectStablishmentPermission: SELECT_USER_STABLISHMENT
        };
    },
    computed: __assign({}, mapGetters({
        permissions: "userSession/getPermissions",
    })),
    methods: {
        loadStablishments: function () {
            return __awaiter(this, void 0, void 0, function () {
                var stablishmentsResponse, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.loadingStablishments = true;
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            this.stablishments.push({
                                id: 0,
                                name: "Todos",
                            });
                            return [4 /*yield*/, StablishmentService.getAll()];
                        case 2:
                            stablishmentsResponse = _a.sent();
                            this.stablishments = __spreadArrays(this.stablishments, stablishmentsResponse);
                            return [3 /*break*/, 4];
                        case 3:
                            error_1 = _a.sent();
                            console.log(error_1);
                            return [3 /*break*/, 4];
                        case 4:
                            this.loadingStablishments = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
        loadGroups: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            this.loading = true;
                            if (!(this.stablishmentId == 0)) return [3 /*break*/, 2];
                            _a = this;
                            return [4 /*yield*/, GroupService.getAll()];
                        case 1:
                            _a.groups = _c.sent();
                            return [3 /*break*/, 4];
                        case 2:
                            _b = this;
                            return [4 /*yield*/, GroupService.getByStablishmentId(this.stablishmentId)];
                        case 3:
                            _b.groups = _c.sent();
                            _c.label = 4;
                        case 4:
                            this.loading = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
    mounted: function () {
        this.loadGroups();
        this.loadStablishments();
    },
});
