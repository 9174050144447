import { __awaiter, __generator, __spreadArrays } from "tslib";
import logoRedondoSSM from "@/other/base64Logo";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";
import { CdpService } from "@/services/models/purchaseRequest/cdp";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
export var generatePdfCdp = function (cdp, print) { return __awaiter(void 0, void 0, void 0, function () {
    var pdfMake, pdfFonts_1, dataCdp, tableArticle, i, docDefinition, asdf_1, promise;
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    return __generator(this, function (_s) {
        switch (_s.label) {
            case 0: return [4 /*yield*/, import("pdfmake/build/pdfmake.js")];
            case 1:
                pdfMake = _s.sent();
                if (!(pdfMake.vfs == undefined)) return [3 /*break*/, 3];
                return [4 /*yield*/, import("pdfmake/build/vfs_fonts.js")];
            case 2:
                pdfFonts_1 = _s.sent();
                pdfMake.vfs = pdfFonts_1.pdfMake.vfs;
                _s.label = 3;
            case 3: return [4 /*yield*/, CdpService.getById(cdp.id)];
            case 4:
                dataCdp = _s.sent();
                console.log(dataCdp);
                tableArticle = [];
                if (dataCdp.cdps_articles) {
                    for (i = 0; i < dataCdp.cdps_articles.length; i++) {
                        tableArticle.push([
                            { text: "" + ((_b = (_a = dataCdp.cdps_articles[i].purchase_request_detail) === null || _a === void 0 ? void 0 : _a.article) === null || _b === void 0 ? void 0 : _b.name) },
                            { text: "" + ((_c = dataCdp.cdps_articles[i].purchase_request_detail) === null || _c === void 0 ? void 0 : _c.cant) },
                            { text: "" + numberWithCommas((_d = dataCdp.cdps_articles[i].purchase_request_detail) === null || _d === void 0 ? void 0 : _d.price) },
                            { text: "" + ((_e = dataCdp.cdps_articles[i].purchase_request_detail) === null || _e === void 0 ? void 0 : _e.observation) }
                        ]);
                    }
                }
                docDefinition = {
                    pageMargins: [40, 30, 40, 60],
                    footer: function (currentPage, pageCount) {
                        return {
                            table: {
                                widths: [230],
                                body: [
                                    [{
                                            text: "CDP N\u00B0" + dataCdp.id + ", " + moment(dataCdp.updated_at).format('YYYY-MM-DD hh:mm') + " P\u00E1gina " + currentPage + " de " + pageCount,
                                            style: {
                                                fontSize: 10,
                                                bold: true,
                                                alignment: 'left'
                                            },
                                            margin: [40, 10, 0, 0]
                                        },]
                                ],
                                layouts: 'noBorders'
                            },
                        };
                    },
                    content: [
                        {
                            columns: [
                                {
                                    image: logoRedondoSSM,
                                    width: 70,
                                },
                            ]
                        },
                        {
                            margin: [0, 10, 0, 0],
                            table: {
                                widths: ['*'],
                                body: [
                                    [
                                        {
                                            text: "Certificado de Disponibilidad Presupuestaria N\u00B0" + dataCdp.id,
                                            alignment: 'center',
                                            style: 'title',
                                            margin: [0, 10, 40, 0]
                                        },
                                    ],
                                    [
                                        { text: "" + ((_f = dataCdp.header) === null || _f === void 0 ? void 0 : _f.description), alignment: 'center' },
                                    ],
                                ]
                            }
                        },
                        {
                            margin: [0, 10, 0, 0],
                            table: {
                                widths: ['*', '*', '*', '*'],
                                body: [
                                    [
                                        { text: "Solicitud N\u00B0", style: 'tableHeader' },
                                        { text: "" + dataCdp.n_request },
                                        { text: "Plan de Compra", style: 'tableHeader' },
                                        { text: "" + dataCdp.purchase_plan },
                                    ],
                                    [
                                        { text: "De", style: 'tableHeader' },
                                        { text: ((_g = dataCdp.user) === null || _g === void 0 ? void 0 : _g.name) + " " + ((_h = dataCdp.user) === null || _h === void 0 ? void 0 : _h.pather_lastname) + " " + ((_j = dataCdp.user) === null || _j === void 0 ? void 0 : _j.mother_lastname), colSpan: 3 },
                                        {}, {}
                                    ],
                                    [
                                        { text: "Detalle de la Compra", style: 'tableHeader' },
                                        { text: "" + dataCdp.detail, colSpan: 3 },
                                        {}, {}
                                    ],
                                    [
                                        { text: "Nombre Proveedor", style: 'tableHeader' },
                                        { text: "" + ((_k = dataCdp.provider) === null || _k === void 0 ? void 0 : _k.name) },
                                        { text: "Rut Proveedor", style: 'tableHeader' },
                                        { text: "" + ((_l = dataCdp.provider) === null || _l === void 0 ? void 0 : _l.rut) },
                                    ],
                                    [
                                        { text: "Monto Presupuesto", style: 'tableHeader' },
                                        { text: "" + dataCdp.budget_amount },
                                        { text: "Mes Devengado", style: 'tableHeader' },
                                        { text: "" + dataCdp.accrued_month },
                                    ],
                                    [
                                        { text: "Presupuesto", style: 'tableHeader' },
                                        { text: "" + ((_m = dataCdp.budget) === null || _m === void 0 ? void 0 : _m.name), colSpan: 3 },
                                        {}, {}
                                    ],
                                    [
                                        { text: "Proyecto", style: 'tableHeader' },
                                        { text: "" + ((_o = dataCdp.project) === null || _o === void 0 ? void 0 : _o.name) },
                                        { text: "Cta. Complementaria", style: 'tableHeader' },
                                        { text: "" + ((_p = dataCdp.suplementary_account) === null || _p === void 0 ? void 0 : _p.name) },
                                    ],
                                    [
                                        { text: "Monto Autorizado", style: 'tableHeader' },
                                        { text: "" + dataCdp.authorized_amount },
                                        { text: "Mes de Pago", style: 'tableHeader' },
                                        { text: "" + dataCdp.pay_month },
                                    ],
                                    [
                                        { text: "Modalidad", style: 'tableHeader' },
                                        { text: "" + ((_q = dataCdp.purchase_method) === null || _q === void 0 ? void 0 : _q.name), colSpan: 3 },
                                        {}, {}
                                    ],
                                    [
                                        { text: "Causal", style: 'tableHeader' },
                                        { text: "" + ((_r = dataCdp.causal) === null || _r === void 0 ? void 0 : _r.name), colSpan: 3 },
                                        {}, {}
                                    ],
                                    [
                                        { text: "Id N\u00B0", style: 'tableHeader' },
                                        { text: "" + dataCdp.number_id, colSpan: 3 },
                                        {}, {}
                                    ],
                                ]
                            }
                        },
                        {
                            margin: [0, 10, 0, 0],
                            table: {
                                headerRows: 2,
                                widths: [150, 50, 70, '*'],
                                body: __spreadArrays([
                                    [
                                        { text: 'Artículos/Servicios', style: 'title', colSpan: 4, alignment: 'center' }, {}, {}, {}
                                    ],
                                    [
                                        { text: 'Nombre', style: 'tableHeader' },
                                        { text: 'Cantidad', style: 'tableHeader' },
                                        { text: 'Precio Apróximado', style: 'tableHeader' },
                                        { text: 'Observación', style: 'tableHeader' },
                                    ]
                                ], tableArticle)
                            },
                        }
                    ],
                    styles: {
                        title: {
                            bold: true,
                            fontSize: 15,
                            color: 'white',
                            fillColor: '#EE3A3A'
                        },
                        tableHeader: {
                            bold: true,
                            fontSize: 11,
                            color: 'black',
                            fillColor: '#e4e4e4'
                        },
                        tableExample: {
                            alignment: "justify",
                            margin: [0, -150, 0, 15],
                        },
                        toTheRight: {
                            alignment: "right",
                        },
                        header: {
                            fontSize: 17,
                            alignment: "left",
                            bold: true,
                        },
                        titleHeader: {
                            fontSize: 18,
                            bold: true,
                            alignment: 'center'
                        },
                        subtitleHeader: {
                            fontSize: 9,
                            alignment: 'center'
                        }
                    },
                };
                if (print) {
                    /**Para IMPRIMIR */
                    pdfMake.createPdf(docDefinition).download("CDP N\u00B0" + dataCdp.id + " " + moment().format('DD-MM-YYYY'));
                }
                else {
                    asdf_1 = pdfMake.createPdf(docDefinition);
                    promise = new Promise(function (resolve) {
                        asdf_1.getBase64(function (data) {
                            resolve(data);
                        });
                    });
                    return [2 /*return*/, promise];
                }
                return [2 /*return*/];
        }
    });
}); };
