import { __awaiter, __generator } from "tslib";
import { UserGroupService } from "@/services/models/group/userGroup";
var moduleUserGroup = {
    namespaced: true,
    state: {
        userGroup: [],
        loading: false,
    },
    getters: {
        getUserGroup: function (state) {
            return state.userGroup;
        },
        getLoading: function (state) {
            return state.loading;
        }
    },
    mutations: {
        setUserGroup: function (state, userGroup) {
            state.userGroup = userGroup;
        },
        setLoading: function (state, loading) {
            state.loading = loading;
        }
    },
    actions: {
        getUserGroup: function (_a, group_id) {
            var commit = _a.commit;
            return __awaiter(this, void 0, Promise, function () {
                var UserGroup;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, UserGroupService.getByGroupId(group_id)];
                        case 1:
                            UserGroup = _b.sent();
                            commit("setUserGroup", UserGroup);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        },
    }
};
export default moduleUserGroup;
