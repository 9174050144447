import { __extends } from "tslib";
import { BaseService } from '../../base';
var RoleService = /** @class */ (function (_super) {
    __extends(RoleService, _super);
    function RoleService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(RoleService, "entity", {
        get: function () {
            return 'role';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RoleService, "responseName", {
        get: function () {
            return 'role';
        },
        enumerable: false,
        configurable: true
    });
    return RoleService;
}(BaseService()));
export { RoleService };
