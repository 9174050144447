import { __assign } from "tslib";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import ButtonRemoveBuyer from "./ButtonRemoveBuyer.vue";
export default Vue.extend({
    components: { ButtonRemoveBuyer: ButtonRemoveBuyer },
    props: {
        purchaseProp: {
            type: Object,
        },
    },
    data: function () {
        return {
            headers: [
                {
                    text: "Funcionario",
                    sortable: false,
                    value: "name",
                },
                {
                    text: "Acciones",
                    sortable: false,
                    align: "center",
                    value: "actions",
                },
            ],
        };
    },
    computed: __assign({}, mapGetters({
        getBuyer: "buyer/getBuyerAssign",
        getLoading: "buyer/getLoading",
    })),
    methods: __assign({}, mapActions({
        setBuyer: "buyer/getBuyerAssign",
    })),
    mounted: function () {
        this.setBuyer(this.purchaseProp.id);
    },
});
