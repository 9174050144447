import { __assign, __awaiter, __generator } from "tslib";
import { TicketService } from "@/services/models/ticket/ticket";
import { TicketCloseService } from "@/services/models/ticket/ticketClose";
import { TicketHistoryService } from "@/services/models/ticket/ticketHistory";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
    props: {
        itemProp: {
            type: Object,
        },
    },
    data: function () {
        return {
            dialog: false,
            ticketClose: {
                observation: "",
            },
        };
    },
    methods: __assign(__assign({}, mapActions({
        setTickets: "ticket/getTicketsUser",
        setTicketsFilter: "ticket/getTicketFilter",
    })), { save: function () {
            return __awaiter(this, void 0, void 0, function () {
                var putTicket, postTicketClose, postTicketHistory, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.displayLoader(true);
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 5, 6, 7]);
                            console.log(this.itemProp);
                            putTicket = __assign(__assign({}, this.itemProp), { state: "cerrado permanentemente" });
                            postTicketClose = __assign(__assign({}, this.ticketClose), { ticket_id: this.itemProp.id, user_id: this.getCurrentUserLoggedIn().id, id: 0 });
                            postTicketHistory = {
                                ticket_id: this.itemProp.id,
                                user_id: this.getCurrentUserLoggedIn().id,
                                state: "Ticket cerrado permanentemente"
                            };
                            return [4 /*yield*/, TicketService.update(this.itemProp.id, putTicket)];
                        case 2:
                            _a.sent();
                            return [4 /*yield*/, TicketCloseService.create(postTicketClose)];
                        case 3:
                            _a.sent();
                            return [4 /*yield*/, TicketHistoryService.create(postTicketHistory)];
                        case 4:
                            _a.sent();
                            this.dialog = false;
                            this.displayToast({
                                type: 'success',
                                message: 'Ticket validado sin problemas'
                            });
                            this.setTickets(this.getCurrentUserLoggedIn().id);
                            this.setTicketsFilter();
                            return [3 /*break*/, 7];
                        case 5:
                            error_1 = _a.sent();
                            console.log(error_1);
                            this.displayToast({
                                type: "warning",
                                message: "Hubo un error al cerrar",
                            });
                            return [3 /*break*/, 7];
                        case 6:
                            this.displayLoader(false);
                            return [7 /*endfinally*/];
                        case 7: return [2 /*return*/];
                    }
                });
            });
        } }),
});
