import { __awaiter, __extends, __generator } from "tslib";
import { BaseService } from './../../base';
var PurchaseBuyerService = /** @class */ (function (_super) {
    __extends(PurchaseBuyerService, _super);
    function PurchaseBuyerService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(PurchaseBuyerService, "entity", {
        get: function () {
            return 'purchase_buyer';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PurchaseBuyerService, "responseName", {
        get: function () {
            return 'purchase_buyer';
        },
        enumerable: false,
        configurable: true
    });
    PurchaseBuyerService.getByPurchaseId = function (purchaseId) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_purchase_request_id/" + purchaseId
                    })];
            });
        });
    };
    PurchaseBuyerService.getByPurchaseBuyerState = function (payLoad, page) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_purchase_buyer_states/" + payLoad.userId + "/" + payLoad.state + "/" + payLoad.step + "?page=" + page
                    })];
            });
        });
    };
    return PurchaseBuyerService;
}(BaseService()));
export { PurchaseBuyerService };
