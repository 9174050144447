import { __awaiter, __extends, __generator } from "tslib";
import { BaseService } from './../../base';
var PurchaseRequestFileService = /** @class */ (function (_super) {
    __extends(PurchaseRequestFileService, _super);
    function PurchaseRequestFileService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(PurchaseRequestFileService, "entity", {
        get: function () {
            return 'purchase_request_file';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PurchaseRequestFileService, "responseName", {
        get: function () {
            return 'purchase_request_file';
        },
        enumerable: false,
        configurable: true
    });
    PurchaseRequestFileService.getByPurchaseId = function (purchase_id) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_purchase_request_id/" + purchase_id
                    })];
            });
        });
    };
    return PurchaseRequestFileService;
}(BaseService()));
export { PurchaseRequestFileService };
