import { __awaiter, __generator } from "tslib";
import Vue from 'vue';
import { generatePDFPurchaseRequest } from '@/reports/reportPurchaseRequest';
export default Vue.extend({
    props: {
        purchaseProp: {
            type: Object
        }
    },
    methods: {
        print: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.displayLoader(true);
                            return [4 /*yield*/, generatePDFPurchaseRequest(this.purchaseProp)];
                        case 1:
                            _a.sent();
                            this.displayLoader(false);
                            return [2 /*return*/];
                    }
                });
            });
        }
    }
});
