import { __awaiter, __generator } from "tslib";
import Vue from "vue";
import { ArticleService } from "@/services/models/purchaseRequest/article";
export default Vue.extend({
    data: function () {
        return {
            articles: [],
            purchase_request_detail: {
                article: {},
                cant: null,
                price: null,
                observation: "",
            },
            dataArray: [],
            headers: [
                {
                    text: "Articulo",
                    sortable: false,
                    value: "article_name",
                },
                {
                    text: "Cantidad",
                    sortable: false,
                    value: "cant",
                },
                {
                    text: "Precio Apróx",
                    sortable: false,
                    value: "price",
                },
                {
                    text: "Acciones",
                    sortable: false,
                    value: "actions",
                },
            ],
        };
    },
    methods: {
        loadData: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            this.displayLoader(true);
                            _a = this;
                            return [4 /*yield*/, ArticleService.getAll()];
                        case 1:
                            _a.articles = _b.sent();
                            this.displayLoader(false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        addArticle: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (!this.$refs.formArticle.validate()) {
                        this.displayToast({
                            type: "error",
                            message: "Favor de llenar todos los campos requeridos",
                        });
                        return [2 /*return*/];
                    }
                    this.dataArray.push({
                        article_name: this.purchase_request_detail.article.name,
                        article_id: this.purchase_request_detail.article.id,
                        cant: this.purchase_request_detail.cant || 0,
                        price: this.purchase_request_detail.price || 0,
                        observation: this.purchase_request_detail.observation,
                    });
                    this.$refs.formArticle.reset();
                    return [2 /*return*/];
                });
            });
        },
        removeArticle: function (article) {
            this.dataArray = this.dataArray.filter(function (data) { return data != article; });
        },
        validateForm: function () {
            if (this.dataArray.length == 0)
                return false;
            return true;
        },
    },
    mounted: function () {
        this.loadData();
    },
});
