import { __assign } from "tslib";
import Vue from "vue";
import Inputs from "./Inputs.vue";
import { mapActions } from "vuex";
import TableSearchDocument from "./TableSearchDocument..vue";
export default Vue.extend({
    components: {
        Inputs: Inputs,
        TableSearchDocument: TableSearchDocument,
    },
    data: function () {
        return {
            dialog2: false,
            dialog: false,
            isLoading: false,
        };
    },
    watch: {
        dialog: function () {
            if (this.dialog) {
                console.log("asdasd");
            }
        },
    },
    methods: __assign(__assign({}, mapActions({
        documentSearch: 'documents/getSearchDocument'
    })), { search: function () {
            var data = __assign({}, this.$refs.inputDataUser.document);
            this.documentSearch({ data: data });
            this.dialog2 = true;
        } }),
});
