import { __assign, __awaiter, __generator } from "tslib";
import { GroupService } from "@/services/models/group/group";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
    data: function () {
        return {
            dialog: false,
            group: {
                id: 0,
                name: "",
                stablishment_id: this.getCurrentUserLoggedIn().stablishment_id
            },
        };
    },
    methods: __assign(__assign({}, mapActions({
        setGroup: "group/getGroups",
    })), { save: function () {
            return __awaiter(this, void 0, void 0, function () {
                var error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, 3, 4]);
                            this.displayLoader(true);
                            return [4 /*yield*/, GroupService.create(this.group)];
                        case 1:
                            _a.sent();
                            this.setGroup();
                            this.dialog = false;
                            return [3 /*break*/, 4];
                        case 2:
                            error_1 = _a.sent();
                            console.log(error_1);
                            this.displayToast({
                                type: "error",
                                message: "Error al guardar",
                            });
                            return [3 /*break*/, 4];
                        case 3:
                            this.displayLoader(false);
                            return [7 /*endfinally*/];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        } }),
});
