import { __assign, __awaiter, __generator } from "tslib";
import InputSelectUsers from "@/components/document/ShowDocument/panelDetail/InputSelectUsers.vue";
import { BuyerService } from "@/services/models/purchaseRequest/buyer";
import { PurchaseHistoryService } from "@/services/models/purchaseRequest/purchaseHistory";
import { PurchaseRequestStateService } from "@/services/models/purchaseRequest/purchaseRequestState";
import Vue from "vue";
import { mapActions } from "vuex";
import TableAssignBuyer from "./TableAssignBuyer.vue";
export default Vue.extend({
    components: { InputSelectUsers: InputSelectUsers, TableAssignBuyer: TableAssignBuyer },
    props: {
        purchaseProp: {
            type: Object,
        },
    },
    data: function () {
        return {
            dialog: false,
        };
    },
    methods: __assign(__assign({}, mapActions({
        setBuyer: "buyer/getBuyerAssign",
        setPending: "unityManager/getPending",
        setReady: "unityManager/getReady",
    })), { loadData: function () {
            this.setBuyer(this.purchaseProp.id);
            this.setPending({
                user_id: this.getCurrentUserLoggedIn().id,
                state: "pendiente",
                step: "unityManager",
            });
            this.setReady({
                user_id: this.getCurrentUserLoggedIn().id,
                state: "enviado",
                step: "unityManager",
            });
        },
        save: function () {
            return __awaiter(this, void 0, void 0, function () {
                var users, i, data, dataHistory, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.displayLoader(true);
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 8, 9, 10]);
                            users = this.$refs.selectedUser.usersSelects;
                            i = 0;
                            _a.label = 2;
                        case 2:
                            if (!(i < users.length)) return [3 /*break*/, 6];
                            data = {
                                id: 0,
                                user_id: this.getCurrentUserLoggedIn().id,
                                purchase_request_id: this.purchaseProp.id,
                                user_buyer_id: users[i].id,
                                state: "pendiente",
                            };
                            return [4 /*yield*/, BuyerService.create(data)];
                        case 3:
                            _a.sent();
                            dataHistory = {
                                id: 0,
                                purchase_request_id: this.purchaseProp.id,
                                state: "Comprador Asignado",
                                user_origin_id: this.getCurrentUserLoggedIn().id,
                                user_destination_id: users[i].id,
                            };
                            return [4 /*yield*/, PurchaseHistoryService.create(dataHistory)];
                        case 4:
                            _a.sent();
                            _a.label = 5;
                        case 5:
                            i++;
                            return [3 /*break*/, 2];
                        case 6: return [4 /*yield*/, PurchaseRequestStateService.update(this.purchaseProp.purchases_requests_states[0].id, __assign(__assign({}, this.purchaseProp.purchases_requests_states[0]), { state: "enviado" }))];
                        case 7:
                            _a.sent();
                            this.displayToast({
                                type: "success",
                                message: "Se asignaron los compradores con éxito",
                            });
                            this.loadData();
                            this.dialog = false;
                            return [3 /*break*/, 10];
                        case 8:
                            error_1 = _a.sent();
                            console.log(error_1);
                            return [3 /*break*/, 10];
                        case 9:
                            this.displayLoader(false);
                            return [7 /*endfinally*/];
                        case 10: return [2 /*return*/];
                    }
                });
            });
        } }),
});
