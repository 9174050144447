import { __awaiter, __generator } from "tslib";
import { PurchaseRequestService } from "@/services/models/purchaseRequest/purchaseRequest";
var modulePurchaseRequest = {
    namespaced: true,
    state: {
        purchaseRequest: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        loading: false,
    },
    getters: {
        getPurchaseRequest: function (state) {
            return state.purchaseRequest;
        },
        getLoading: function (state) {
            return state.loading;
        }
    },
    mutations: {
        setPurchaseRequest: function (state, purchaseRequest) {
            state.purchaseRequest = purchaseRequest;
        },
        setPagePurchaseRequest: function (state, page) {
            state.purchaseRequest.current_page = page;
        },
        setLoading: function (state, loading) {
            state.loading = loading;
        }
    },
    actions: {
        getPurchaseRequest: function (_a, user_id) {
            var commit = _a.commit, state = _a.state;
            return __awaiter(this, void 0, Promise, function () {
                var purchaseRequest;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, PurchaseRequestService.getByUserId(user_id, state.purchaseRequest.current_page)];
                        case 1:
                            purchaseRequest = _b.sent();
                            commit("setPurchaseRequest", purchaseRequest);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        }
    }
};
export default modulePurchaseRequest;
