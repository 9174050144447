import { __awaiter, __generator, __spreadArrays } from "tslib";
import { PurchaseRequestService } from "@/services/models/purchaseRequest/purchaseRequest";
import { PurchaseHistoryService } from '@/services/models/purchaseRequest/purchaseHistory';
import logoRedondoSSM from "@/other/base64Logo";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
export var generatePDFPurchaseRequest = function (purchaseRequest) { return __awaiter(void 0, void 0, void 0, function () {
    var pdfMake, pdfFonts_1, dataPurchase, dataHistory, tableArticle, i, tableHistory, i, docDefinition;
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    return __generator(this, function (_p) {
        switch (_p.label) {
            case 0: return [4 /*yield*/, import("pdfmake/build/pdfmake.js")];
            case 1:
                pdfMake = _p.sent();
                if (!(pdfMake.vfs == undefined)) return [3 /*break*/, 3];
                return [4 /*yield*/, import("pdfmake/build/vfs_fonts.js")];
            case 2:
                pdfFonts_1 = _p.sent();
                pdfMake.vfs = pdfFonts_1.pdfMake.vfs;
                _p.label = 3;
            case 3: return [4 /*yield*/, PurchaseRequestService.getById(purchaseRequest.id)];
            case 4:
                dataPurchase = _p.sent();
                return [4 /*yield*/, PurchaseHistoryService.getByPurchaseId(purchaseRequest.id)];
            case 5:
                dataHistory = _p.sent();
                tableArticle = [];
                if (dataPurchase.purchases_requests_details) {
                    for (i = 0; i < ((_a = dataPurchase.purchases_requests_details) === null || _a === void 0 ? void 0 : _a.length); i++) {
                        tableArticle.push([
                            { text: "" + ((_b = dataPurchase.purchases_requests_details[i].article) === null || _b === void 0 ? void 0 : _b.name) },
                            { text: "" + dataPurchase.purchases_requests_details[i].cant },
                            { text: "" + numberWithCommas(dataPurchase.purchases_requests_details[i].price) },
                            { text: "" + dataPurchase.purchases_requests_details[i].observation }
                        ]);
                    }
                }
                tableHistory = [];
                if (dataHistory) {
                    for (i = 0; i < (dataHistory === null || dataHistory === void 0 ? void 0 : dataHistory.length); i++) {
                        tableHistory.push([
                            { text: "" + dataHistory[i].created_at },
                            { text: ((_c = dataHistory[i].user_origin) === null || _c === void 0 ? void 0 : _c.name) + " " + ((_d = dataHistory[i].user_origin) === null || _d === void 0 ? void 0 : _d.pather_lastname) },
                            { text: ((_e = dataHistory[i].user_destination) === null || _e === void 0 ? void 0 : _e.name) + " " + ((_f = dataHistory[i].user_destination) === null || _f === void 0 ? void 0 : _f.pather_lastname) },
                            { text: "" + dataHistory[i].state },
                        ]);
                    }
                }
                docDefinition = {
                    pageMargins: [40, 30, 40, 60],
                    footer: function (currentPage, pageCount) {
                        return {
                            columns: [
                                [
                                    {
                                        text: "Solicitud de Compra N\u00B0" + dataPurchase.id + ", Fecha del PDF: " + moment().format('YYYY-MM-DD hh:mm'),
                                        style: {
                                            fontSize: 12,
                                            bold: true,
                                            alignment: 'center'
                                        },
                                        margin: [0, 10, 40, 0]
                                    },
                                    {
                                        text: "P\u00E1gina " + currentPage + " de " + pageCount,
                                        style: {
                                            fontSize: 12,
                                            bold: true,
                                            alignment: 'right'
                                        },
                                        margin: [0, 10, 40, 0]
                                    },
                                ],
                            ]
                        };
                    },
                    content: [
                        {
                            columns: [
                                {
                                    image: logoRedondoSSM,
                                    width: 70,
                                },
                                [
                                    {
                                        text: "Solicitud de Compra n\u00B0" + dataPurchase.id,
                                        style: 'titleHeader',
                                        margin: [0, 10, 40, 0]
                                    },
                                ],
                            ]
                        },
                        {
                            margin: [0, 10, 0, 0],
                            table: {
                                widths: ['*', '*'],
                                body: [
                                    //                         // FECHA DE SOLICITUD	2021-10-12
                                    // FECHA DE GENERACIÓN DEL REPORTE	2021-11-25
                                    // NOMBRE DEL REQUIRENTE	KAREN IVONNE AHERN MUÑOZ
                                    // CORREO ELECTRONICO
                                    [
                                        { text: 'Datos del solicitante', style: 'title', colSpan: 2, alignment: 'center' }, {}
                                    ],
                                    [
                                        { text: 'Solicitante', style: 'tableHeader' },
                                        { text: ((_g = dataPurchase.user) === null || _g === void 0 ? void 0 : _g.name) + " " + ((_h = dataPurchase.user) === null || _h === void 0 ? void 0 : _h.pather_lastname) + " " + ((_j = dataPurchase.user) === null || _j === void 0 ? void 0 : _j.mother_lastname) },
                                    ],
                                    [
                                        { text: 'Correo', style: 'tableHeader' },
                                        { text: "" + ((_k = dataPurchase.user) === null || _k === void 0 ? void 0 : _k.email) },
                                    ],
                                    [
                                        { text: 'Telefono', style: 'tableHeader' },
                                        { text: "" + (((_l = dataPurchase.user) === null || _l === void 0 ? void 0 : _l.phone) || 'Sin telefono asociado') },
                                    ],
                                    [
                                        { text: 'Fecha de solicitud', style: 'tableHeader' },
                                        { text: "" + dataPurchase.created_at }
                                    ],
                                    [
                                        { text: 'Fecha de generación del reporte', style: 'tableHeader' },
                                        { text: "" + moment().format('DD-MM-YYYY hh:mm') }
                                    ],
                                    [
                                        { text: 'Descripción general', style: 'title', colSpan: 2, alignment: 'center' }, {}
                                    ],
                                    [
                                        { text: 'Tipo de solicitud', style: 'tableHeader' },
                                        { text: "" + ((_m = dataPurchase.purchase_request_type) === null || _m === void 0 ? void 0 : _m.name) }
                                    ],
                                    [
                                        { text: 'Descripción', style: 'tableHeader' },
                                        { text: "" + dataPurchase.description },
                                    ],
                                    [
                                        { text: 'Fecha estimada de uso', style: 'tableHeader' },
                                        { text: "" + dataPurchase.estimated_use_date },
                                    ],
                                    [
                                        { text: 'Estado', style: 'tableHeader' },
                                        { text: "" + dataPurchase.state.toUpperCase() },
                                    ],
                                    [
                                        { text: 'Ubicación', style: 'tableHeader' },
                                        { text: "" + ((_o = dataPurchase.user_location) === null || _o === void 0 ? void 0 : _o.name) },
                                    ],
                                ]
                            },
                        },
                        {
                            margin: [0, 10, 0, 0],
                            table: {
                                headerRows: 2,
                                widths: [150, 50, 70, '*'],
                                body: __spreadArrays([
                                    [
                                        { text: 'Artículos/Servicios', style: 'title', colSpan: 4, alignment: 'center' }, {}, {}, {}
                                    ],
                                    [
                                        { text: 'Nombre', style: 'tableHeader' },
                                        { text: 'Cantidad', style: 'tableHeader' },
                                        { text: 'Precio Apróximado', style: 'tableHeader' },
                                        { text: 'Observación', style: 'tableHeader' },
                                    ]
                                ], tableArticle)
                            },
                            pageBreak: 'after'
                        },
                        {
                            margin: [0, 10, 0, 0],
                            table: {
                                headerRows: 2,
                                widths: [80, '*', '*', '*'],
                                body: __spreadArrays([
                                    [
                                        { text: 'Historial', style: 'title', colSpan: 4, alignment: 'center' }, {}, {}, {}
                                    ],
                                    [
                                        { text: 'Fecha', style: 'tableHeader' },
                                        { text: 'Usuario Origen', style: 'tableHeader' },
                                        { text: 'Usuario Destino', style: 'tableHeader' },
                                        { text: 'Observación', style: 'tableHeader' },
                                    ]
                                ], tableHistory)
                            },
                        }
                    ],
                    styles: {
                        title: {
                            bold: true,
                            fontSize: 15,
                            color: 'white',
                            fillColor: '#EE3A3A'
                        },
                        tableHeader: {
                            bold: true,
                            fontSize: 11,
                            color: 'black',
                            fillColor: '#e4e4e4'
                        },
                        tableExample: {
                            alignment: "justify",
                            margin: [0, -150, 0, 15],
                        },
                        toTheRight: {
                            alignment: "right",
                        },
                        header: {
                            fontSize: 17,
                            alignment: "left",
                            bold: true,
                        },
                        titleHeader: {
                            fontSize: 18,
                            bold: true,
                            alignment: 'center'
                        },
                        subtitleHeader: {
                            fontSize: 9,
                            alignment: 'center'
                        }
                    },
                };
                pdfMake.createPdf(docDefinition).download("Reporte.pdf");
                return [2 /*return*/];
        }
    });
}); };
