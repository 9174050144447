import { __assign, __awaiter, __generator } from "tslib";
import Vue from "vue";
import { UserService, } from "@/services/models/user/user";
import Loader from "@/components/customComponents/Loader.vue";
import Toast from "@/components/customComponents/Toast.vue";
import { formatRut } from "@/thirdParty/utils";
import { mapMutations } from "vuex";
import store from "@/store";
export default Vue.extend({
    components: { Loader: Loader, Toast: Toast },
    props: {
        bgColor: {
            type: String,
            default: "red",
        },
        fgColor: {
            type: String,
            default: "white",
        },
    },
    data: function () {
        return {
            step: 1,
            username: "",
            email: "",
            password: "",
            rut: "",
            login: "",
            snackbar: false,
            items: ["DSSM", "HCM"],
            loading: false,
        };
    },
    beforeCreate: function () {
        document.body.className = "intro";
    },
    methods: __assign(__assign({}, mapMutations({
        setProfile: "userSession/setProfile",
        setCurrentLocation: "userSession/setCurrentLocation",
    })), { formatRut: function () {
            this.login = formatRut(this.login);
        },
        connect: function () {
            var _a, _b;
            return __awaiter(this, void 0, void 0, function () {
                var data, response, error_1;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            this.displayLoader(true);
                            data = {
                                run: this.login.replaceAll(".", ""),
                                password: this.password,
                                grant_type: "password",
                            };
                            _c.label = 1;
                        case 1:
                            _c.trys.push([1, 3, 4, 5]);
                            store.commit('relogin/resetRelogin');
                            return [4 /*yield*/, UserService.login(data)];
                        case 2:
                            response = _c.sent();
                            localStorage.setItem("token", response.data.token);
                            localStorage.setItem("user", JSON.stringify(response.data.user));
                            localStorage.setItem("perfil", ((_a = response.data.user.role) === null || _a === void 0 ? void 0 : _a.name) || "");
                            this.setProfile((_b = response.data.user.role) === null || _b === void 0 ? void 0 : _b.name);
                            if (response.data.user.users_locations) {
                                this.setCurrentLocation(response.data.user.users_locations[0]);
                            }
                            store.commit("userSession/setUserModules");
                            store.commit("userSession/setUserPermissions");
                            switch (response.data.user.role_id) {
                                case 1: //Administrator
                                    this.$router.push({ name: "administratorHome" });
                                    break;
                                case 2: //Administrativo
                                    this.$router.push({ name: "administrativeHome" });
                                    break;
                                case 3: //Opartes
                                    this.$router.push({ name: "opartesHome" });
                                    break;
                                default:
                                    break;
                            }
                            return [3 /*break*/, 5];
                        case 3:
                            error_1 = _c.sent();
                            if (error_1.message == "Request failed with status code 401") {
                                this.displayToast({
                                    type: 'warning',
                                    message: 'Las credenciales ingresadas son incorrectas'
                                });
                            }
                            else {
                                this.displayToast({
                                    type: 'error',
                                    message: 'Ha ocurrido un error al ingresar, favor volver a intentar'
                                });
                            }
                            return [3 /*break*/, 5];
                        case 4:
                            this.displayLoader(false);
                            return [7 /*endfinally*/];
                        case 5: return [2 /*return*/];
                    }
                });
            });
        } }),
});
