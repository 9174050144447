import { __assign } from "tslib";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import DetailTablePurchaseRequest from "../DetailTablePurchaseRequest.vue";
import GroupButtons from './../buttonsPurchaseRequest/GroupButtons.vue';
export default Vue.extend({
    components: {
        DetailTablePurchaseRequest: DetailTablePurchaseRequest,
        GroupButtons: GroupButtons
    },
    data: function () {
        return {
            page: 1,
            dataStateStep: {
                userId: this.getCurrentUserLoggedIn().id,
                state: "enviado",
                step: "unityManager",
            },
            headers: [
                {
                    text: 'Id Solicitud',
                    sorteable: false,
                    value: 'purchase_request_id'
                },
                {
                    text: 'Id Registro de Compra',
                    sorteable: false,
                    value: 'id'
                },
                {
                    text: 'N° Licitación',
                    sorteable: false,
                    value: 'n_licitation'
                },
                {
                    text: 'N° Orden',
                    sorteable: false,
                    value: 'n_order'
                },
                {
                    text: 'Observación',
                    sorteable: false,
                    value: 'observation'
                },
                {
                    text: 'Comprador',
                    sorteable: false,
                    value: 'user'
                },
                {
                    text: 'Acciones',
                    sorteable: false,
                    value: 'actions'
                }
            ]
        };
    },
    computed: __assign({}, mapGetters({
        getReady: "purchaseBuyer/getPurchaseBuyerReady",
        getLoading: "purchaseBuyer/getLoading",
    })),
    methods: __assign(__assign({}, mapActions({
        setReady: "purchaseBuyer/getPurchaseBuyerReady",
    })), { changePage: function () {
            this.$store.commit("purchaseBuyer/setPagePurchaseBuyerReady", this.page);
            this.setReady(__assign({}, this.dataStateStep));
        } }),
    mounted: function () {
        this.changePage();
    },
});
