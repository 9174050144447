import { __extends } from "tslib";
import { BaseService } from './../../base';
var UserModulePermissionService = /** @class */ (function (_super) {
    __extends(UserModulePermissionService, _super);
    function UserModulePermissionService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(UserModulePermissionService, "entity", {
        get: function () {
            return 'user_module_permission';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserModulePermissionService, "responseName", {
        get: function () {
            return 'user_module_permission';
        },
        enumerable: false,
        configurable: true
    });
    return UserModulePermissionService;
}(BaseService()));
export { UserModulePermissionService };
