var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-row',{attrs:{"no-gutters":""}},[(_vm.permissions.includes(_vm.createTicketPermission))?_c('v-col',{attrs:{"cols":"2"}},[_c('create-ticket',{attrs:{"selectUser":true}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"2"}},[_c('modal-filter-tickets')],1)],1),_c('br'),_c('v-data-table',{staticClass:"elevation-5",attrs:{"headers":_vm.headers,"items":_vm.getTickets.data,"page":_vm.getTickets.current_page,"items-per-page":_vm.getTickets.per_page,"server-items-length":_vm.getTickets.total,"loading":_vm.getLoading,"hide-default-footer":"","dense":"","single-expand":false,"show-expand":"","item-class":_vm.itemColorBackground},on:{"update:page":function($event){return _vm.$set(_vm.getTickets, "current_page", $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Solicitudes de Soporte")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-alert',{staticClass:"yellow lighten-4",attrs:{"dense":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fa-info")]),_vm._v(" Los ticket con este color indican que estan tomados por usted ")],1),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""},on:{"click":_vm.refreshTable}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fa-sync")])],1)]}}])},[_c('span',[_vm._v("Refrescar Tabla")])])],1)]},proxy:true},{key:"item.data-table-expand",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('v-btn',{attrs:{"x-small":"","icon":"","color":"gray"},on:{"click":function($event){return expand(!isExpanded)}}},[(isExpanded)?_c('v-icon',[_vm._v("fa-chevron-up")]):_c('v-icon',[_vm._v("fa-chevron-down")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[(_vm.permissions.includes(_vm.takeTicketPermission) && item.state == 'solicitado')?_c('v-col',{attrs:{"cols":"2"}},[_c('button-take',{attrs:{"itemProp":item}})],1):_vm._e(),(_vm.permissions.includes(_vm.takeTicketPermission) && (item.state == 'en proceso' || item.state == 'solicitado'))?_c('v-col',{attrs:{"cols":"2"}},[_c('button-transfer',{attrs:{"itemProp":item}})],1):_vm._e(),(item.state == 'en proceso')?_c('v-col',{attrs:{"cols":"2"}},[_c('button-finish',{attrs:{"itemProp":item}})],1):_vm._e(),(item.state != 'cerrado permanentemente')?_c('v-col',{attrs:{"cols":"2"}},[_c('button-closed-ticket',{attrs:{"itemProp":item}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"2"}},[_c('button-history',{attrs:{"itemProp":item}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('generate-single-report',{attrs:{"ticketId":item.id}})],1),(item.state != 'cerrado permanentemente')?_c('v-col',{attrs:{"cols":"2"}},[_c('modal-chat-ticket',{attrs:{"itemProp":item}})],1):_vm._e(),(item.state == 'cerrado permanentemente')?_c('v-col',{attrs:{"cols":"2"}},[_c('button-show-closed-ticket',{attrs:{"itemProp":item}})],1):_vm._e()],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-chip',{attrs:{"color":_vm.getColor(item.state),"dark":"","small":""}},[_vm._v(" "+_vm._s(_vm.getState(item.state))+" ")])],1)]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.name)+" "+_vm._s(item.user.pather_lastname)+" "+_vm._s(item.user.mother_lastname)+" ("+_vm._s(item.location.name)+") ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('detail-table-request-ticket',{attrs:{"itemProp":item}})],1)]}}],null,true)}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.getTickets.last_page},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }