import { __awaiter, __extends, __generator } from "tslib";
import { BaseService } from './../../base';
var DispatchService = /** @class */ (function (_super) {
    __extends(DispatchService, _super);
    function DispatchService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(DispatchService, "entity", {
        get: function () {
            return 'dispatch';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DispatchService, "responseName", {
        get: function () {
            return 'dispatch';
        },
        enumerable: false,
        configurable: true
    });
    /**
     *
     * @param stablishment_id Id establecimiento del usuario logeado
     * @returns
     */
    DispatchService.getStablishmentId = function (stablishment_id, page) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_stablishment_id/" + stablishment_id + "?page=" + page
                    })];
            });
        });
    };
    return DispatchService;
}(BaseService()));
export { DispatchService };
