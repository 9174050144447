import { __awaiter, __extends, __generator } from "tslib";
import { BaseService } from '../../base';
var DocumentService = /** @class */ (function (_super) {
    __extends(DocumentService, _super);
    function DocumentService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(DocumentService, "entity", {
        get: function () {
            return 'document';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DocumentService, "responseName", {
        get: function () {
            return 'document';
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Api que entrega los documentos creados por el usuario
     *
     * @param user_id id usuario logeado
     * @param page n° de pagina
     * @returns
     */
    DocumentService.getUserId = function (data, page) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'post',
                        path: "find_by_user_id?page=" + page,
                        data: data
                    })];
            });
        });
    };
    /**
     * Api que entrega los documentos creados por la locación
     *
     * @param location_id id usuario logeado
     * @param page n° de pagina
     * @returns
     */
    DocumentService.getByUserLocationId = function (data, page) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'post',
                        path: "find_by_location_id?page=" + page,
                        data: data
                    })];
            });
        });
    };
    /**
     * Api que entrega los documentos que ha recibido el usuario (que no hayan sido enviados ni para visar ni para firmar)
     *
     * @param user_id id usuario logeado
     * @param page n° de pagina
     * @returns
     */
    DocumentService.getRequestId = function (data, page) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'post',
                        path: "find_by_request?page=" + page,
                        data: data
                    })];
            });
        });
    };
    /**
     * Api que entrega los documentos que ha recibido la locación (que no hayan sido enviados ni para visar ni para firmar)
     *
     * @param user_id id usuario logeado
     * @param page n° de pagina
     * @returns
     */
    DocumentService.getRequestedDocumentsByLocationId = function (data, page) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'post',
                        path: "find_by_request_location?page=" + page,
                        data: data
                    })];
            });
        });
    };
    /**
     *
     * @param user_id id usuario logeado
     * @param state estado del document_detail_checked del usuario
     * @param page n° de pagina
     * @returns
     */
    DocumentService.getByCheckedState = function (user_id, state, page) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_checked_state/" + user_id + "/" + state + "?page=" + page
                    })];
            });
        });
    };
    /**
     *
     * @param user_id id usuario logeado
     * @param state estado del document_detail_firmed del usuario
     * @param page n° de pagina
     * @returns
     */
    DocumentService.getByFirmedState = function (user_id, state, page) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_firmed_state/" + user_id + "/" + state + "?page=" + page
                    })];
            });
        });
    };
    /**
     *
     * @param user_id id usuario logeado
     * @param dataSearch data con los datos a buscar
     * @param page n° de pagina
     * @returns
     */
    DocumentService.getByAllDocument = function (user_id, dataSearch, page) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'post',
                        path: "find_by_document_all/" + user_id + "?page=" + page,
                        data: dataSearch
                    })];
            });
        });
    };
    /**
     *
     * @param location_id id de la locación del usuario logeado
     * @param dataSearch data con los datos a buscar
     * @param page n° de pagina
     * @returns
     */
    DocumentService.getByAllDocumentLocation = function (location_id, dataSearch, page) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'post',
                        path: "find_by_document_all_location_id/" + location_id + "?page=" + page,
                        data: dataSearch
                    })];
            });
        });
    };
    DocumentService.getByFolderId = function (payLoad, page) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_folder_id/" + payLoad.user_id + "/" + payLoad.folder_id + "?page=" + page,
                    })];
            });
        });
    };
    return DocumentService;
}(BaseService()));
export { DocumentService };
// export interface dataSearch {
//     id: null | number,
//     number: null | string,
//     description: null | string,
//     document_type_id: null | number
//     location_id: null | number,
//     user_id: null | number,
//     created_at: null | string,
// }
