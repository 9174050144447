import TableSupplyManagerReady from "@/components/purchaseRequest/tables/tablesSupplyManager/TableSupplyManagerReady.vue";
import TableSupplyManagerPending from "@/components/purchaseRequest/tables/tablesSupplyManager/TableSupplyManagerPending.vue";
import TemplateAdministrator from "@/templates/TemplateAdministrator.vue";
import Vue from "vue";
export default Vue.extend({
    components: {
        TemplateAdministrator: TemplateAdministrator,
        TableSupplyManagerPending: TableSupplyManagerPending,
        TableSupplyManagerReady: TableSupplyManagerReady,
    },
    data: function () {
        return {
            tab: null
        };
    }
});
