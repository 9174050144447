import EmptyRouterView from '@/views/EmptyRouterView.vue';
import Home from '@/views/opartes/Home.vue';
import Document from '@/views/opartes/Document.vue';
import Folder from '@/views/opartes/Folder.vue';
import Contact from '@/views/opartes/Contact.vue';
import Dispatch from '@/views/opartes/Dispatch.vue';
import DispatchLocation from '@/views/opartes/DispatchLocations.vue';
import Ticket from '@/views/opartes/Ticket.vue';
export var routes = [
    { path: '/opartes',
        component: EmptyRouterView, children: [
            {
                path: 'inicio',
                name: 'opartesHome',
                component: Home,
            },
            {
                path: 'documentos',
                name: 'documentOpartes',
                component: Document,
            },
            {
                path: 'carpeta/:userID/:folderName/:id',
                name: 'folderOpartes',
                component: Folder,
                // Para verificar que la persona que entre a la ruta de la carpeta sea la correcta
                // eslint-disable-next-line
                beforeEnter: function (to, from, next) {
                    console.log('to:', to, 'from', from, 'next', next);
                    if (to.params.userID == JSON.parse(window.localStorage.getItem("user") || '{}').id) {
                        next();
                    }
                    else {
                        alert('No esta autorizado para ingresar a esta ruta');
                        next('inicio');
                    }
                }
            },
            {
                path: 'Contact',
                name: 'contactOpartes',
                component: Contact,
            },
            {
                path: 'Dispatch',
                name: 'dispatchOpartes',
                component: Dispatch,
            },
            {
                path: 'DispatchLocation',
                name: 'dispatchLocationOpartes',
                component: DispatchLocation,
            },
            {
                path: 'Ticket',
                name: 'ticketOpartes',
                component: Ticket,
            },
        ]
    },
];
