import { __awaiter, __generator } from "tslib";
import { DocumentDetailFileService } from "@/services/models/document/documentDetailFile";
var moduleDocumentDetailFile = {
    namespaced: true,
    state: {
        detailFile: [],
        loading: false,
    },
    getters: {
        getDetailFile: function (state) {
            return state.detailFile;
        },
        getLoading: function (state) {
            return state.loading;
        }
    },
    mutations: {
        setDetailFile: function (state, detailFile) {
            state.detailFile = detailFile;
        },
        setLoading: function (state, loading) {
            state.loading = loading;
        }
    },
    actions: {
        getDetailFile: function (_a, id) {
            var commit = _a.commit;
            return __awaiter(this, void 0, Promise, function () {
                var detailFile;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            commit("setDetailFile", []);
                            return [4 /*yield*/, DocumentDetailFileService.getByDocumentDetail(id)];
                        case 1:
                            detailFile = _b.sent();
                            commit("setDetailFile", detailFile);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        }
    }
};
export default moduleDocumentDetailFile;
