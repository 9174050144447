import { __assign } from "tslib";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import GroupButtons from "../buttonsPurchaseRequest/GroupButtons.vue";
import DetailTablePurchaseRequest from "../DetailTablePurchaseRequest.vue";
export default Vue.extend({
    components: {
        DetailTablePurchaseRequest: DetailTablePurchaseRequest,
        GroupButtons: GroupButtons,
    },
    data: function () {
        return {
            page: 1,
            dataStateStep: {
                user_id: this.getCurrentUserLoggedIn().id,
                state: "enviado",
                step: "supplyManager",
            },
            headers: [
                {
                    text: "Id",
                    sortable: false,
                    value: "id",
                },
                {
                    text: "Estado",
                    sortable: false,
                    value: "state",
                },
                {
                    text: "Creación",
                    sortable: false,
                    value: "created_at",
                },
                {
                    text: "Modificación",
                    sortable: false,
                    value: "updated_at",
                },
                {
                    text: "Acciones",
                    sortable: false,
                    align: "center",
                    value: "actions",
                },
            ],
        };
    },
    computed: __assign({}, mapGetters({
        getReady: "supplyManager/getReady",
        getLoading: "supplyManager/getLoading",
    })),
    methods: __assign(__assign({}, mapActions({
        setReady: "supplyManager/getReady",
    })), { changePage: function () {
            this.$store.commit("supplyManager/setPageReady", this.page);
            this.setReady(__assign({}, this.dataStateStep));
        } }),
    mounted: function () {
        this.changePage();
    },
});
