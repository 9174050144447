import { __assign } from "tslib";
import ImgAvatar from "@/components/perfilUserCard/ImgAvatar.vue";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
export default Vue.extend({
    name: "InputSelectUsers",
    components: { ImgAvatar: ImgAvatar },
    props: {
        label: {
            default: false,
        },
        addCurrentUser: {
            type: Boolean,
            default: false
        },
        multipleSelect: {
            type: Boolean,
        },
        iconProp: {
            type: String,
        },
        functionChange: {
            type: Function,
            default: function () {
                console.log();
            },
        },
    },
    data: function () {
        return {
            usersSelects: [],
            menuProps: {
                disabled: false,
            },
        };
    },
    watch: {
        usersSelects: function () {
            if (this.usersSelects.length >= 1 && !this.multipleSelect) {
                this.menuProps.disabled = true;
            }
        },
    },
    computed: __assign({}, mapGetters({
        users: "users/getUsersFilterByStateAndStablishment",
        loading: "users/getLoading",
    })),
    mounted: function () {
        this.loadUsers({ stateUser: "active" });
        console.log('algo');
    },
    methods: __assign(__assign({}, mapActions({
        loadUsers: "users/getUsersFilterByStateAndStablishment",
    })), { filterWithoutCurrentUser: function (users) {
            var _this = this;
            var list;
            if (this.addCurrentUser) {
                list = users;
            }
            else {
                list = users.filter(function (data) { return data.id != _this.getCurrentUserLoggedIn().id; });
            }
            return list;
        },
        remove: function (item) {
            this.usersSelects = this.usersSelects.filter(function (data) { return data.id != item.id; });
            this.menuProps.disabled = false;
        } }),
});
