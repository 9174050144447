import { __extends } from "tslib";
import { BaseService } from '../base';
var StablishmentService = /** @class */ (function (_super) {
    __extends(StablishmentService, _super);
    function StablishmentService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(StablishmentService, "entity", {
        get: function () {
            return 'stablishment';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StablishmentService, "responseName", {
        get: function () {
            return 'stablishment';
        },
        enumerable: false,
        configurable: true
    });
    return StablishmentService;
}(BaseService()));
export { StablishmentService };
