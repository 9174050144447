var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-form',{ref:"formArticle",attrs:{"lazy-validation":""}},[_c('v-select',{attrs:{"rules":_vm.selectRules,"items":_vm.articles,"item-text":"name","item-value":function (item) { return item; },"show-expand":"","label":"Seleccionar artículo/servicio"},model:{value:(_vm.purchase_request_detail.article),callback:function ($$v) {_vm.$set(_vm.purchase_request_detail, "article", $$v)},expression:"purchase_request_detail.article"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"rules":_vm.fieldRules,"label":"Cantidad","type":"number"},model:{value:(_vm.purchase_request_detail.cant),callback:function ($$v) {_vm.$set(_vm.purchase_request_detail, "cant", $$v)},expression:"purchase_request_detail.cant"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"rules":_vm.fieldRules,"label":"Precio aproximado","type":"number"},model:{value:(_vm.purchase_request_detail.price),callback:function ($$v) {_vm.$set(_vm.purchase_request_detail, "price", $$v)},expression:"purchase_request_detail.price"}})],1)],1),_c('v-textarea',{attrs:{"rules":_vm.fieldRules,"label":"Observación"},model:{value:(_vm.purchase_request_detail.observation),callback:function ($$v) {_vm.$set(_vm.purchase_request_detail, "observation", $$v)},expression:"purchase_request_detail.observation"}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.addArticle}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fa-plus")]),_vm._v(" Agregar ")],1)],1)],1),_c('v-col',{attrs:{"cols":"7"}},[_c('v-data-table',{staticClass:"elevation-5",attrs:{"headers":_vm.headers,"items":_vm.dataArray,"hide-default-footer":"","single-expand":"","show-expand":""},scopedSlots:_vm._u([{key:"item.data-table-expand",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('v-btn',{attrs:{"x-small":"","icon":"","color":"gray"},on:{"click":function($event){return expand(!isExpanded)}}},[(isExpanded)?_c('v-icon',[_vm._v("fa-chevron-up")]):_c('v-icon',[_vm._v("fa-chevron-down")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" "+_vm._s(item.observation)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.removeArticle(item)}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("fa-trash")])],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }