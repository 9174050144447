import TableAuthorizerReady from "@/components/purchaseRequest/tables/tablesAuthorizer/TableAuthorizerReady.vue";
import TableAuthorizerPending from "@/components/purchaseRequest/tables/tablesAuthorizer/TableAuthorizerPending.vue";
import TemplateAdministrator from "@/templates/TemplateAdministrator.vue";
import Vue from "vue";
export default Vue.extend({
    components: {
        TemplateAdministrator: TemplateAdministrator,
        TableAuthorizerPending: TableAuthorizerPending,
        TableAuthorizerReady: TableAuthorizerReady,
    },
    data: function () {
        return {
            tab: null
        };
    }
});
