import { __assign, __awaiter, __generator } from "tslib";
import { BuyerService } from "@/services/models/purchaseRequest/buyer";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
    props: {
        buyerProp: {
            type: Object,
        },
    },
    data: function () {
        return {
            dialog: false,
        };
    },
    methods: __assign(__assign({}, mapActions({
        setBuyerInProcess: "buyer/getBuyerInProcess",
        setBuyerReady: "buyer/getBuyerReady",
    })), { save: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.displayLoader(true);
                            return [4 /*yield*/, BuyerService.update(this.buyerProp.id, __assign(__assign({}, this.buyerProp), { state: "finalizado" }))];
                        case 1:
                            _a.sent();
                            this.setBuyerInProcess({
                                userId: this.getCurrentUserLoggedIn().id,
                                state: "en proceso",
                            });
                            this.setBuyerReady({
                                userId: this.getCurrentUserLoggedIn().id,
                                state: "finalizado",
                            });
                            this.displayLoader(false);
                            return [2 /*return*/];
                    }
                });
            });
        } }),
});
