var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-data-table',{staticClass:"elevation-5",attrs:{"loading":_vm.getLoading,"headers":_vm.headers,"items":_vm.getPurchaseRequest.data,"page":_vm.getPurchaseRequest.current_page,"items-per-page":_vm.getPurchaseRequest.per_page,"server-items-length":_vm.getPurchaseRequest.total,"show-expand":""},on:{"update:page":function($event){return _vm.$set(_vm.getPurchaseRequest, "current_page", $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"mr-4"},[_vm._v("Solicitudes Realizadas")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('button-create-purchase-request')],1)]},proxy:true},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.color(item.state),"small":""}},[_vm._v(" "+_vm._s(item.state.toUpperCase())+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('group-buttons',{attrs:{"showSendPurchaseRequest":true,"recharge":_vm.changePage,"purchaseProp":item,"dataSend":{ step: 'authorizer', state: 'pendiente' },"showDelete":item.state=='creado'}})]}},{key:"item.data-table-expand",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('v-btn',{attrs:{"x-small":"","icon":"","color":"gray"},on:{"click":function($event){return expand(!isExpanded)}}},[(isExpanded)?_c('v-icon',[_vm._v("fa-chevron-up")]):_c('v-icon',[_vm._v("fa-chevron-down")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('detail-table-purchase-request',{staticClass:"ma-2",attrs:{"purchaseProp":item}})],1)]}}],null,true)}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.getPurchaseRequest.last_page},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }