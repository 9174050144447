import { __extends } from "tslib";
import { BaseService } from './../../base';
var PurchaseBuyerHistoryService = /** @class */ (function (_super) {
    __extends(PurchaseBuyerHistoryService, _super);
    function PurchaseBuyerHistoryService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(PurchaseBuyerHistoryService, "entity", {
        get: function () {
            return 'purchase_buyer_history';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PurchaseBuyerHistoryService, "responseName", {
        get: function () {
            return 'purchase_buyer_history';
        },
        enumerable: false,
        configurable: true
    });
    return PurchaseBuyerHistoryService;
}(BaseService()));
export { PurchaseBuyerHistoryService };
