import Vue from "vue";
import ButtonAddCdp from "./ButtonAddCdp.vue";
export default Vue.extend({
    components: { ButtonAddCdp: ButtonAddCdp },
    props: {
        purchaseProp: {
            type: Object,
        },
    },
    data: function () {
        return {
            data: [],
            headers: [
                {
                    text: "Numero de solicitud",
                    sortable: false,
                    value: "n_request",
                },
                {
                    text: "Eliminar",
                    sortable: false,
                    value: "actions",
                },
            ],
        };
    },
    methods: {
        addCdp: function (data) {
            this.data.push(data);
        },
        remove: function (data) {
            this.data = this.data.filter(function (item) { return item != data; });
        },
        validateForm: function () {
            if (this.data.length == 0)
                return false;
            return true;
        },
    },
});
