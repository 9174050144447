import { __awaiter, __generator } from "tslib";
import { DocumentTypeService } from "@/services/models/document/document_type";
import { StablishmentService } from "@/services/models/stablishment";
import { LocationService } from "@/services/models/user/location";
import Vue from "vue";
import InputSelectUsers from "../ShowDocument/panelDetail/InputSelectUsers.vue";
export default Vue.extend({
    components: { InputSelectUsers: InputSelectUsers },
    data: function () {
        return {
            menu2: false,
            stablishments: [],
            locations: [],
            documentTypes: [],
            document: {
                id: null,
                number: "",
                description: "",
                document_type_id: null || 0,
                location_id: null || 0,
                user_id: 0,
                created_at: "",
            },
            stablishmentSelected: null || 0,
            date: "",
        };
    },
    mounted: function () {
        this.loadData();
    },
    watch: {
        stablishmentSelected: function () {
            this.change();
        }
    },
    methods: {
        getUserSelected: function () {
            var _a;
            return ((_a = this.$refs.selectFunctionary.usersSelects[0]) === null || _a === void 0 ? void 0 : _a.id) || null;
        },
        loadData: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a, _b, _c, _d, _e, _f, _g;
                var _h, _j;
                return __generator(this, function (_k) {
                    switch (_k.label) {
                        case 0:
                            this.displayLoader(true);
                            _a = this;
                            return [4 /*yield*/, StablishmentService.getAll()];
                        case 1:
                            _a.stablishments = _k.sent();
                            this.stablishmentSelected = this.getCurrentUserLoggedIn().stablishment_id;
                            this.documentTypes.push({
                                name: "Sin Seleccionar",
                                id: 0
                            });
                            _c = (_b = (_h = this.documentTypes).push).apply;
                            _d = [_h];
                            return [4 /*yield*/, DocumentTypeService.getAll()];
                        case 2:
                            _c.apply(_b, _d.concat([_k.sent()]));
                            this.document.document_type_id = this.documentTypes[0].id || 0;
                            this.locations.push({
                                name: "Sin Seleccionar",
                                id: 0,
                                location_type_id: 1,
                                stablishment_id: 1,
                            });
                            _f = (_e = (_j = this.locations).push).apply;
                            _g = [_j];
                            return [4 /*yield*/, LocationService.getByStablishmentId(this.stablishmentSelected)];
                        case 3:
                            _f.apply(_e, _g.concat([(_k.sent())]));
                            this.document.location_id = this.locations[0].id || 0;
                            this.displayLoader(false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        change: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a, _b, _c;
                var _d;
                return __generator(this, function (_e) {
                    switch (_e.label) {
                        case 0:
                            this.locations = [];
                            this.locations.push({
                                name: "Sin Seleccionar",
                                id: 0,
                                location_type_id: 1,
                                stablishment_id: 1,
                            });
                            _b = (_a = (_d = this.locations).push).apply;
                            _c = [_d];
                            return [4 /*yield*/, LocationService.getByStablishmentId(this.stablishmentSelected)];
                        case 1:
                            _b.apply(_a, _c.concat([(_e.sent())]));
                            return [2 /*return*/];
                    }
                });
            });
        }
    },
});
