import Vue from "vue";
import CardDataDocument from "./CardDataDocument.vue";
import PanelDetail from "./panelDetail/PanelDetail.vue";
export default Vue.extend({
    name: "ShowDocumentDetail",
    props: {
        documentProp: {
            required: true,
            type: Object,
        },
    },
    components: {
        CardDataDocument: CardDataDocument,
        PanelDetail: PanelDetail,
    },
    data: function () {
        return {
            dialog: false,
        };
    },
});
