import { __awaiter, __extends, __generator } from "tslib";
import { BaseService } from '../../base';
var GroupService = /** @class */ (function (_super) {
    __extends(GroupService, _super);
    function GroupService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(GroupService, "entity", {
        get: function () {
            return 'group';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(GroupService, "responseName", {
        get: function () {
            return 'group';
        },
        enumerable: false,
        configurable: true
    });
    GroupService.getByStablishmentId = function (stablishment_id) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_stablishment_id/" + stablishment_id
                    })];
            });
        });
    };
    return GroupService;
}(BaseService()));
export { GroupService };
