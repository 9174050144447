import { __spreadArrays } from "tslib";
import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import { routes as Administrator } from './administrator';
import { routes as Administrative } from './administrative';
import { routes as Opartes } from './opartes';
Vue.use(VueRouter);
var routes = __spreadArrays([
    {
        path: '/',
        name: 'Login',
        component: Login
    }
], Administrator, Administrative, Opartes);
var router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes
});
export default router;
