var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list-item-group',{attrs:{"dark":""}},[_c('v-subheader',[_vm._v("Inicio")]),_vm._l((_vm.starter),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.to}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)],1)}),_c('v-divider'),(_vm.modules.includes(_vm.documentModule))?_c('section',[_c('v-subheader',[_vm._v("Documentación")]),_c('documentation',{attrs:{"profile":'Administrator'}}),_c('folders',{attrs:{"profile":'Administrator'}}),_c('v-divider')],1):_vm._e(),(
    _vm.modules.includes(_vm.purchaseRequestModule) ||
    _vm.modules.includes(_vm.purchaseAuthorizerModule) ||
    _vm.modules.includes(_vm.supplyManagerModule) ||
    _vm.modules.includes(_vm.unityManagerModule) ||
    _vm.modules.includes(_vm.buyerModule) ||
    _vm.modules.includes(_vm.budgetManagerModule) ||
    _vm.modules.includes(_vm.budgetModule) ||
    _vm.modules.includes(_vm.financialManagerModule)
  )?_c('section',[_c('v-subheader',[_vm._v("Solicitudes de Compras")]),(_vm.modules.includes(_vm.purchaseRequestModule))?_c('purchase-request',{attrs:{"profile":'Administrator'}}):_vm._e(),(_vm.modules.includes(_vm.purchaseAuthorizerModule))?_c('purchase-authorizer',{attrs:{"profile":'Administrator'}}):_vm._e(),(_vm.modules.includes(_vm.supplyManagerModule))?_c('supply-manager',{attrs:{"profile":'Administrator'}}):_vm._e(),(_vm.modules.includes(_vm.unityManagerModule))?_c('unity-manager',{attrs:{"profile":'Administrator'}}):_vm._e(),(_vm.modules.includes(_vm.buyerModule))?_c('buyer',{attrs:{"profile":'Administrator'}}):_vm._e(),(_vm.modules.includes(_vm.budgetManagerModule))?_c('budget-manager',{attrs:{"profile":'Administrator'}}):_vm._e(),(_vm.modules.includes(_vm.budgetModule))?_c('budget',{attrs:{"profile":'Administrator'}}):_vm._e(),(_vm.modules.includes(_vm.financialManagerModule))?_c('financial-manager',{attrs:{"profile":'Administrator'}}):_vm._e(),_c('v-divider')],1):_vm._e(),(
      _vm.modules.includes(_vm.ticketsModule) || _vm.modules.includes(_vm.createTicketModule)
    )?_c('v-subheader',[_vm._v("Tickets")]):_vm._e(),(_vm.modules.includes(_vm.createTicketModule))?_c('ticket',{attrs:{"profile":'Administrator'}}):_vm._e(),(_vm.modules.includes(_vm.ticketsModule))?_c('request-ticket',{attrs:{"profile":'Administrator'}}):_vm._e(),_c('ticket-reports',{attrs:{"profile":'Administrator'}}),_c('v-divider'),(_vm.modules.includes(_vm.userMaintainerModule) || _vm.modules.includes(_vm.groupMaintainerModule))?_c('section',[_c('v-subheader',[_vm._v("Mantenedores")]),_c('maintainers',{attrs:{"modules":_vm.modules,"profile":'Administrator'}}),_c('v-divider')],1):_vm._e(),_c('v-subheader',[_vm._v("Contactos")]),_c('contact',{attrs:{"profile":'Administrator'}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }