var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-data-table',{staticClass:"elevation-5",attrs:{"headers":_vm.headers,"items":_vm.getBuyers.data,"page":_vm.getBuyers.current_page,"items-per-page":_vm.getBuyers.per_page,"server-items-length":_vm.getBuyers.total,"loading":_vm.getLoading,"hide-default-footer":"","show-expand":"","dense":""},on:{"update:page":function($event){return _vm.$set(_vm.getBuyers, "current_page", $event)}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('group-buttons',{attrs:{"recharge":_vm.changePage,"buyerProp":item,"showCloseBuyer":true,"purchaseProp":item.purchase_request,"showButtonPurchaseBuyer":true}})]}},{key:"item.purchase_request_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.purchase_request.id)+" ")]}},{key:"item.user_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.name)+" "+_vm._s(item.user.pather_lastname)+" "+_vm._s(item.user.mother_lastname)+" ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.state.toUpperCase())+" ")]}},{key:"item.data-table-expand",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('v-btn',{attrs:{"x-small":"","icon":"","color":"gray"},on:{"click":function($event){return expand(!isExpanded)}}},[(isExpanded)?_c('v-icon',[_vm._v("fa-chevron-up")]):_c('v-icon',[_vm._v("fa-chevron-down")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('detail-table-purchase-request',{staticClass:"ma-2",attrs:{"purchaseProp":item.purchase_request}})],1)]}}],null,true)}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.getBuyers.last_page},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }