import Vue from "vue";
import ModalFirmDocument from "../../firmDocument/ModalFirmDocument.vue";
import ButtonChecked from "./ButtonChecked.vue";
import ButtonInvalidDetail from "./ButtonInvalidDetail.vue";
import ButtonSeeFile from "./ButtonSeeFile.vue";
import DialogDetailFiles from "./DetailFiles/DialogDetailFiles.vue";
import PanelCheckeds from "./PanelCheckeds.vue";
import PanelFirms from "./PanelFirms.vue";
import ButtonListReaders from "./tableReaders/ButtonListReaders.vue";
import EditTemplateDocument from "../createTemplateDocument/EditTemplateDocument.vue";
export default Vue.extend({
    name: "GroupButtons",
    components: {
        ButtonChecked: ButtonChecked,
        ButtonInvalidDetail: ButtonInvalidDetail,
        ButtonSeeFile: ButtonSeeFile,
        ModalFirmDocument: ModalFirmDocument,
        DialogDetailFiles: DialogDetailFiles,
        ButtonListReaders: ButtonListReaders,
        PanelCheckeds: PanelCheckeds,
        PanelFirms: PanelFirms,
        EditTemplateDocument: EditTemplateDocument,
    },
    props: {
        documentDetail: {
            type: Object,
        },
        documentProp: {
            type: Object,
        },
        open: {
            type: Number,
        },
    },
    data: function () { return ({
        direction: "left",
        fab: false,
        fling: false,
        tabs: null,
        top: false,
        right: true,
        bottom: true,
        left: false,
        transition: "slide-y-reverse-transition",
    }); },
    methods: {
        canCheck: function (documentDetail) {
            var _this = this;
            var canFirm = false;
            if (documentDetail.documents_details_checked) {
                canFirm = !!documentDetail.documents_details_checked.find(function (detail) {
                    return detail.state == "por visar" &&
                        detail.user_id == _this.getCurrentUserLoggedIn().id;
                });
            }
            return canFirm;
        },
        canFirm: function (documentDetail) {
            var _this = this;
            var canFirm = false;
            if (documentDetail.documents_details_firmed) {
                canFirm = !!documentDetail.documents_details_firmed.find(function (detail) {
                    return detail.state == "por firmar" &&
                        detail.user_id == _this.getCurrentUserLoggedIn().id;
                });
            }
            return canFirm;
        },
    },
    watch: {
        top: function (val) {
            this.bottom = !val;
        },
        right: function (val) {
            this.left = !val;
        },
        bottom: function (val) {
            this.top = !val;
        },
        left: function (val) {
            this.right = !val;
        },
    },
});
