import { __awaiter, __decorate, __extends, __generator } from "tslib";
import { formatRut } from './../thirdParty/utils';
import moment from "moment";
import store from "./../store";
import { Component, Vue } from "vue-property-decorator";
var myMixin = /** @class */ (function (_super) {
    __extends(myMixin, _super);
    function myMixin() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.fieldRules = [function (v) { return !!v || "Este campo es obligatorio"; }];
        _this.selectRules = [function (v) { return !!v || "Debe seleccionar una opción"; }];
        return _this;
        // getUserModules(): UserModule[] {
        // 	let modules: UserModule[] = [];
        // 	const user = this.getCurrentUserLoggedIn();
        // 	const userModules = user.users_modules;
        // 	if (userModules) {
        // 		modules = userModules;
        // 	}
        // 	return modules;
        // }
        // getUserPermissions(): UserModulePermission[] {
        // 	let permissions: UserModulePermission[] = [];
        // 	const modules = this.getUserModules();
        // 	modules.forEach(module => {
        // 		let userModulePermissions: UserModulePermission[] = [];
        // 		if (module.users_modules_permissions) {
        // 			userModulePermissions = module.users_modules_permissions;
        // 		}
        // 		permissions = [...permissions, ...userModulePermissions];
        // 	});
        // 	return permissions;
        // }
    }
    myMixin.prototype.numberWithCommas = function (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };
    myMixin.prototype.getUrlFile = function (file_name) {
        var url = window.location.hostname;
        var url2 = url.split("");
        if (url2[url2.length - 1] == "w") {
            return process.env.VUE_APP_API_URL_LOCAL + "/" + file_name;
        }
        else {
            return process.env.VUE_APP_API_URL + "/" + file_name;
        }
    };
    myMixin.prototype.getProfile = function () {
        return this.$store.getters["userSession/getProfile"];
    };
    myMixin.prototype.getCurrentState = function (document) {
        var currentState = {
            document_id: 0,
            id: 0,
            state: "creado",
            state_read: 'leido',
            user_id: 0,
            location_id: 0
        };
        if (this.getProfile() == "opartes") {
            if (document.documents_states_locations) {
                currentState = document.documents_states_locations[0];
            }
        }
        else {
            if (document.documents_states) {
                currentState = document.documents_states[0];
            }
        }
        return currentState;
    };
    myMixin.prototype.getUserLocation = function () {
        var _a;
        var userLoggedIn = this.getCurrentUserLoggedIn();
        var userLocation = {
            location_id: 0,
            user_id: 0,
            position_id: 0,
            id: 0
        };
        if ((_a = userLoggedIn.users_locations) === null || _a === void 0 ? void 0 : _a.length) {
            userLocation = userLoggedIn.users_locations[0];
        }
        return userLocation;
    };
    myMixin.prototype.getUserGroup = function () {
        var _a;
        var userLoggedIn = this.getCurrentUserLoggedIn();
        var userGroup = {
            group_id: 0,
            user_id: 0,
            id: 0
        };
        if ((_a = userLoggedIn.users_groups) === null || _a === void 0 ? void 0 : _a.length) {
            userGroup = userLoggedIn.users_groups[0];
        }
        return userGroup;
    };
    myMixin.prototype.getCurrentUserLoggedIn = function () {
        var userLoggedIn = {
            name: "",
            pather_lastname: "",
            mother_lastname: "",
            email: "",
            role_id: 0,
            run: "",
            stablishment_id: 0,
            avatar: "",
            avatar_name: "",
            users_locations: [],
            state: 'active',
            is_final_authorizer: null,
            id: 0
        };
        var userJson = JSON.parse(window.localStorage.getItem("user") || JSON.stringify(userLoggedIn));
        userLoggedIn.id = userJson.id;
        userLoggedIn.name = userJson.name;
        userLoggedIn.pather_lastname = userJson.pather_lastname;
        userLoggedIn.mother_lastname = userJson.mother_lastname;
        userLoggedIn.email = userJson.email;
        userLoggedIn.run = userJson.run;
        userLoggedIn.role_id = userJson.role_id;
        userLoggedIn.avatar = userJson.avatar;
        userLoggedIn.avatar_name = userJson.avatar_name;
        userLoggedIn.stablishment_id = userJson.stablishment_id;
        userLoggedIn.users_locations = userJson.users_locations;
        userLoggedIn.users_modules = userJson.users_modules;
        userLoggedIn.users_groups = userJson.users_groups;
        userLoggedIn.is_final_authorizer = userJson.is_final_authorizer;
        return userLoggedIn;
    };
    myMixin.prototype.getRutWithoutFormat = function (data) {
        var rut = data.replace(/[.-]/g, "");
        return rut;
    };
    myMixin.prototype.formatRut = function (rut) {
        var rutFormatted;
        if (rut) {
            rutFormatted = formatRut(rut);
        }
        else {
            rutFormatted = "Sin información";
        }
        return rutFormatted;
    };
    myMixin.prototype.formatDate = function (date) {
        var result = date.split(' ');
        var newDate;
        if (result.length == 2) {
            newDate = moment(date).format('DD-MM-YYYY HH:mm');
        }
        else {
            newDate = moment(date).format('DD-MM-YYYY');
        }
        return newDate;
    };
    myMixin.prototype.showDate = function (date) {
        var dateDifferenceInDays = moment().diff(moment(date), "days", false);
        var formattedDate = moment(date).format("DD-MM-YYYY");
        var daysText = dateDifferenceInDays == 1 ? "día" : "días";
        var textDifference = dateDifferenceInDays == 0
            ? "Hoy"
            : "Hace " + dateDifferenceInDays + " " + daysText;
        return formattedDate + " (" + textDifference + ")";
    };
    myMixin.prototype.displayToast = function (data) {
        store.commit("toast/showToast", data);
    };
    myMixin.prototype.displayLoader = function (data) {
        store.commit('loader/setLoader', data);
    };
    myMixin.prototype.displayConfirm = function (data) {
        store.commit("confirm/setConfirm", data);
    };
    myMixin.prototype.cleanForm = function (context, formName) {
        if (context.$refs[formName]) {
            // eslint-disable-next-line
            context.$refs[formName].reset();
        }
    };
    //Valida todos los refs del componente padre que tengan la función validateForm
    myMixin.prototype.validateMultipleForms = function () {
        return __awaiter(this, void 0, Promise, function () {
            var isValid, _a, _b, _i, prop, formValidation;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        isValid = true;
                        _a = [];
                        for (_b in this.$refs)
                            _a.push(_b);
                        _i = 0;
                        _c.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 4];
                        prop = _a[_i];
                        if (!this.$refs[prop].validateForm) return [3 /*break*/, 3];
                        // eslint-disable-next-line
                        return [4 /*yield*/, this.$refs[prop].validateForm()];
                    case 2:
                        formValidation = 
                        // eslint-disable-next-line
                        _c.sent();
                        console.log(formValidation);
                        if (!formValidation.isValid) {
                            isValid = false;
                            this.displayToast({
                                type: "warning",
                                message: formValidation.message || "Por favor complete los campos faltantes",
                            });
                            return [2 /*return*/, isValid];
                        }
                        _c.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/, isValid];
                }
            });
        });
    };
    // eslint-disable-next-line
    myMixin.prototype.isValidCharacter = function (evt) {
        var keysAllowed = [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "K",
            "k",
        ];
        var keyPressed = evt.key;
        if (!keysAllowed.includes(keyPressed)) {
            evt.preventDefault();
        }
    };
    myMixin.prototype.generateCorrelative = function (length) {
        var result = '';
        var CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var CHARACTERSLENGTH = CHARACTERS.length;
        for (var i = 0; i < length; i++) {
            result += CHARACTERS.charAt(Math.floor(Math.random() *
                CHARACTERSLENGTH));
        }
        return result;
    };
    myMixin = __decorate([
        Component
    ], myMixin);
    return myMixin;
}(Vue));
export default myMixin;
