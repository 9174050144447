import { __assign } from "tslib";
import Vue from "vue";
import TableDetailFiles from "./TableDetailFiles.vue";
import BtnCreateDetailFile from "./BtnCreateDetailFile.vue";
import { mapActions, mapGetters } from "vuex";
export default Vue.extend({
    name: "DialogDetailFiles",
    props: {
        detailProp: {
            required: true,
            type: Object,
        },
    },
    components: {
        TableDetailFiles: TableDetailFiles,
        BtnCreateDetailFile: BtnCreateDetailFile,
    },
    computed: __assign({}, mapGetters({
        detailFiles: "documentDetailFile/getDetailFile",
    })),
    methods: __assign({}, mapActions({
        getDetailFile: "documentDetailFile/getDetailFile",
    })),
    mounted: function () {
        this.getDetailFile(this.detailProp.id);
    },
    data: function () {
        return {
            dialog: false,
        };
    },
});
