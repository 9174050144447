import Vue from "vue";
export default Vue.extend({
    props: {
        observation: {
            type: String,
        },
    },
    data: function () {
        return {
            dialog: false
        };
    },
});
