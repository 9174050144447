var moduleConfirm = {
    namespaced: true,
    state: {
        showConfirm: false,
        type: '',
        title: '',
        message: '',
        functionCall: Function
    },
    getters: {
        getShowConfirm: function (state) {
            return state;
        }
    },
    mutations: {
        setConfirm: function (state, payload) {
            state.type = payload.type || '';
            state.title = payload.title || '';
            state.message = payload.message || '';
            if (payload.functionCall) {
                state.functionCall = payload.functionCall;
            }
            state.showConfirm = payload.state;
        },
    }
};
export default moduleConfirm;
