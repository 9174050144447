import { __assign, __awaiter, __generator } from "tslib";
import { UserService } from "@/services/models/user/user";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
    props: {
        allowChange: {
            default: false,
        },
        data: {
            type: String,
        },
        size: {
            type: Number,
        },
    },
    data: function () {
        return {
            newFile: {},
        };
    },
    methods: __assign(__assign({}, mapActions({
        setUser: "users/getUser",
    })), { onButtonClick: function () {
            this.$refs.uploader.click();
        },
        onFileChanged: function (e) {
            return __awaiter(this, void 0, void 0, function () {
                var formData, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            // this.isSelecting = true;
                            this.newFile = e.target.files[0];
                            formData = new FormData();
                            formData.append("file", this.newFile);
                            return [4 /*yield*/, UserService.updateAvatar(this.getCurrentUserLoggedIn().id, formData)];
                        case 1:
                            _a.sent();
                            this.setUser(this.getCurrentUserLoggedIn().id);
                            return [3 /*break*/, 3];
                        case 2:
                            error_1 = _a.sent();
                            console.log(error_1);
                            throw error_1;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        } }),
});
