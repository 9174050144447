import { __assign } from "tslib";
import Vue from "vue";
import CreateDocument from "./CreateDocument.vue";
import TableMyDocuments from "./TableMyDocuments.vue";
import TableRequest from "./TableRequest.vue";
import FilterColorsDocument from "./FilterColorsDocument.vue";
import SearchDocument from "./searchDocument/SearchDocument.vue";
import TabChecked from "./documentChecked/TabChecked.vue";
import TabFirmed from "./documentFirmed/TabFirmed.vue";
import ButtonRefreshTable from "./ButtonRefreshTable.vue";
import { mapActions, mapGetters } from "vuex";
export default Vue.extend({
    components: {
        TableMyDocuments: TableMyDocuments,
        TableRequest: TableRequest,
        CreateDocument: CreateDocument,
        FilterColorsDocument: FilterColorsDocument,
        SearchDocument: SearchDocument,
        TabChecked: TabChecked,
        TabFirmed: TabFirmed,
        ButtonRefreshTable: ButtonRefreshTable,
    },
    data: function () {
        return {
            tab: "tab-request",
        };
    },
    computed: __assign({}, mapGetters({
        getByFirm: "documents/getByFirmedDocument",
        getByVisated: "documents/getByCheckedDocument",
        getRequest: "documents/getRequestDocument",
    })),
    mounted: function () {
        if (this.getProfile() !== "opartes") {
            this.byFirms(this.getCurrentUserLoggedIn().id);
            this.byVisated(this.getCurrentUserLoggedIn().id);
        }
        this.request({ id: this.getCurrentUserLoggedIn().id });
        //será necesario esto???
        this.$store.commit("tabsDocument/setTabNumber", this.tab);
    },
    methods: __assign(__assign({}, mapActions({
        byFirms: "documents/getByFirmedDocument",
        byVisated: "documents/getByCheckedDocument",
        request: "documents/getRequestDocument",
        setMyDocuments: "documents/getMyDocument",
    })), { changeTab: function () {
            this.$store.commit("tabsDocument/setTabNumber", this.tab);
        } }),
});
