var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"dense":"","loading":_vm.getLoading,"headers":_vm.headers,"items":_vm.getItems},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"mr-4"},[_vm._v("Historial de movimientos")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1)]},proxy:true},{key:"item.origin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user_origin.name.toUpperCase())+" "+_vm._s(item.user_origin.pather_lastname.toUpperCase())+" "+_vm._s(item.user_origin.mother_lastname.toUpperCase())+" ")]}},{key:"item.destination",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user_destination.name.toUpperCase())+" "+_vm._s(item.user_destination.pather_lastname.toUpperCase())+" "+_vm._s(item.user_destination.mother_lastname.toUpperCase())+" ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.state.toUpperCase())+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.updated_at))+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }