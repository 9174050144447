import { __awaiter, __generator } from "tslib";
import { TicketTypeService } from "@/services/models/ticket/ticketType";
var moduleTicketType = {
    namespaced: true,
    state: {
        ticketType: [],
        loading: false,
    },
    getters: {
        getTicketType: function (state) {
            return state.ticketType;
        },
        getLoading: function (state) {
            return state.loading;
        }
    },
    mutations: {
        setTicketType: function (state, ticketType) {
            state.ticketType = ticketType;
        },
        setLoading: function (state, loading) {
            state.loading = loading;
        }
    },
    actions: {
        getTicketType: function (_a, id) {
            var commit = _a.commit;
            return __awaiter(this, void 0, Promise, function () {
                var TICKETTYPE;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, TicketTypeService.getByGroupId(id)];
                        case 1:
                            TICKETTYPE = _b.sent();
                            commit("setTicketType", TICKETTYPE);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        },
    }
};
export default moduleTicketType;
