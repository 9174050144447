import { __awaiter, __extends, __generator } from "tslib";
import { BaseService } from './../../base';
var TicketMessageService = /** @class */ (function (_super) {
    __extends(TicketMessageService, _super);
    function TicketMessageService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(TicketMessageService, "entity", {
        get: function () {
            return 'ticket_message';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TicketMessageService, "responseName", {
        get: function () {
            return 'ticket_message';
        },
        enumerable: false,
        configurable: true
    });
    TicketMessageService.getByTicketId = function (id) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_ticket_id/" + id
                    })];
            });
        });
    };
    return TicketMessageService;
}(BaseService()));
export { TicketMessageService };
