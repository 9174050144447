var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-data-table',{staticClass:"elevation-5",attrs:{"headers":_vm.headers,"items":_vm.getCdp.data,"page":_vm.getCdp.current_page,"items-per-page":_vm.getCdp.per_page,"server-items-length":_vm.getCdp.total,"loading":_vm.getLoading,"hide-default-footer":"","show-expand":"","dense":""},on:{"update:page":function($event){return _vm.$set(_vm.getCdp, "current_page", $event)}},scopedSlots:_vm._u([{key:"item.data-table-expand",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('v-btn',{attrs:{"x-small":"","icon":"","color":"gray"},on:{"click":function($event){return expand(!isExpanded)}}},[(isExpanded)?_c('v-icon',[_vm._v("fa-chevron-up")]):_c('v-icon',[_vm._v("fa-chevron-down")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('detail-table-cdp',{attrs:{"purchaseBuyerId":item.purchase_buyer_id,"cdpProps":item}})],1)]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.name)+" "+_vm._s(item.user.pather_lastname)+" "+_vm._s(item.user.mother_lastname)+" ")]}},{key:"item.firmUser",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cdp_file_firmed_user.name)+" "+_vm._s(item.cdp_file_firmed_user.pather_lastname)+" "+_vm._s(item.cdp_file_firmed_user.mother_lastname)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","icon":"","href":item.cdp_file_firmed?item.cdp_file_firmed:item.cdp_file,"target":"_blank"}},[_c('v-icon',[_vm._v("fas fa-file-pdf")])],1)]}}],null,true)}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.getCdp.last_page},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }