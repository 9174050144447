import { __awaiter, __generator } from "tslib";
import { DispatchService } from "@/services/models/dispatch/dispatch";
var moduleDispatch = {
    namespaced: true,
    state: {
        dispatch: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        loading: false,
    },
    getters: {
        getDispatch: function (state) {
            return state.dispatch;
        },
        getLoading: function (state) {
            return state.loading;
        }
    },
    mutations: {
        setDispatch: function (state, dispatch) {
            state.dispatch = dispatch;
        },
        setPageDispatch: function (state, page) {
            state.dispatch.current_page = page;
        },
        setLoading: function (state, loading) {
            state.loading = loading;
        }
    },
    actions: {
        getDispatch: function (_a, stablishment_id) {
            var commit = _a.commit, state = _a.state;
            return __awaiter(this, void 0, Promise, function () {
                var dispatch;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, DispatchService.getStablishmentId(stablishment_id, state.dispatch.current_page)];
                        case 1:
                            dispatch = _b.sent();
                            commit("setDispatch", dispatch);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        },
    }
};
export default moduleDispatch;
