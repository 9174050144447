import { __awaiter, __generator } from "tslib";
import { GroupService } from "@/services/models/group/group";
var moduleGroup = {
    namespaced: true,
    state: {
        group: [],
        loading: false,
        stablishmentSelected: null
    },
    getters: {
        getGroups: function (state) {
            return state.group;
        },
        getLoading: function (state) {
            return state.loading;
        },
        getStablishmentSelected: function (state) {
            return state.stablishmentSelected;
        },
    },
    mutations: {
        setGroups: function (state, group) {
            state.group = group;
        },
        setLoading: function (state, loading) {
            state.loading = loading;
        },
        setStablishmentSelected: function (state, stablishment) {
            state.stablishmentSelected = stablishment;
        }
    },
    actions: {
        getGroups: function (_a) {
            var commit = _a.commit;
            return __awaiter(this, void 0, Promise, function () {
                var GROUP;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, GroupService.getAll()];
                        case 1:
                            GROUP = _b.sent();
                            commit("setGroups", GROUP);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        },
    }
};
export default moduleGroup;
