import { __assign } from "tslib";
import store from "@/store";
import Vue from "vue";
import { mapGetters } from "vuex";
export default Vue.extend({
    computed: __assign({}, mapGetters({
        setConfirm: "confirm/getShowConfirm",
    })),
    methods: {
        close: function () {
            store.commit("confirm/setConfirm", { state: false });
        },
    },
});
