import { __extends } from "tslib";
import { BaseService } from './../../base';
var ModuleService = /** @class */ (function (_super) {
    __extends(ModuleService, _super);
    function ModuleService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ModuleService, "entity", {
        get: function () {
            return 'module';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ModuleService, "responseName", {
        get: function () {
            return 'module';
        },
        enumerable: false,
        configurable: true
    });
    return ModuleService;
}(BaseService()));
export { ModuleService };
