import Vue from "vue";
import FirmDocumentContent from "./FirmDocumentContent.vue";
export default Vue.extend({
    components: { FirmDocumentContent: FirmDocumentContent },
    props: {
        CdpProps: {
            required: true,
            type: Object,
        },
        PurchaseBuyerProps: {
            required: true,
            type: Object,
        },
    },
    data: function () {
        return {
            dialog: false,
        };
    },
});
