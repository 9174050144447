import { __assign, __awaiter, __generator } from "tslib";
import { TicketService } from "@/services/models/ticket/ticket";
import { TicketHistoryService } from "@/services/models/ticket/ticketHistory";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
    props: {
        itemProp: {
            type: Object,
        },
    },
    data: function () {
        return {
            dialog: false,
            observation: "",
        };
    },
    methods: __assign(__assign({}, mapActions({
        setTickets: "ticket/getTicketsUser",
    })), { save: function () {
            return __awaiter(this, void 0, void 0, function () {
                var dataPost, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.displayLoader(true);
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 4, 5, 6]);
                            return [4 /*yield*/, TicketService.update(this.itemProp.id, __assign(__assign({}, this.itemProp), { state: "solicitado" }))];
                        case 2:
                            _a.sent();
                            dataPost = {
                                user_id: this.getCurrentUserLoggedIn().id,
                                ticket_id: this.itemProp.id,
                                state: "Solicitud reanudada "
                            };
                            return [4 /*yield*/, TicketHistoryService.create(dataPost)];
                        case 3:
                            _a.sent();
                            this.setTickets(this.getCurrentUserLoggedIn().id);
                            this.dialog = false;
                            return [3 /*break*/, 6];
                        case 4:
                            error_1 = _a.sent();
                            console.log(error_1);
                            return [3 /*break*/, 6];
                        case 5:
                            this.displayLoader(false);
                            return [7 /*endfinally*/];
                        case 6: return [2 /*return*/];
                    }
                });
            });
        } }),
});
