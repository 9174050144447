import { __assign, __awaiter, __generator } from "tslib";
import { UserService } from "@/services/models/user/user";
import Vue from "vue";
import { mapActions, mapMutations } from "vuex";
import UserInfo from "./../createUser/UserInfo.vue";
import compressImage from "@/others/compressFile";
import { formatRut } from "@/thirdParty/utils";
import UserProfilesEdit from "./UserProfilesEdit.vue";
import UserModulesEdit from "./UserModulesEdit.vue";
export default Vue.extend({
    props: {
        userProp: {
            required: true,
            type: Object,
        },
    },
    components: { UserInfo: UserInfo, UserProfilesEdit: UserProfilesEdit, UserModulesEdit: UserModulesEdit },
    data: function () {
        return {
            loading: false,
            user: {},
            usersLocations: [],
            usersModules: [],
            step: 1,
            invalidStep: 0,
            avatarFile: "",
        };
    },
    mounted: function () {
        return __awaiter(this, void 0, void 0, function () {
            var userResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.getRoles();
                        this.getStablishment();
                        return [4 /*yield*/, UserService.getById(this.userProp.id)];
                    case 1:
                        userResponse = _a.sent();
                        if (userResponse.avatar) {
                            this.avatarFile = userResponse.avatar;
                        }
                        this.user = __assign(__assign({}, userResponse), { run: formatRut(userResponse.run), avatar: null });
                        this.chargeStablishment(userResponse.stablishment);
                        return [2 /*return*/];
                }
            });
        });
    },
    methods: __assign(__assign(__assign({}, mapMutations({
        chargeStablishment: "stablishments/changeSatblishmentSelected",
    })), mapActions({
        getRoles: "roles/getRoles",
        getStablishment: "stablishments/getStablishments",
        getUsers: "users/getUsers",
    })), { validateStep: function (isValid, step) {
            if (!isValid) {
                this.invalidStep = step;
            }
        },
        createUser: function () {
            return __awaiter(this, void 0, void 0, function () {
                var areFormsValid, user, avatarCompressed, formData, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.loading = true;
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 7, , 8]);
                            return [4 /*yield*/, this.validateMultipleForms()];
                        case 2:
                            areFormsValid = _a.sent();
                            if (!areFormsValid) {
                                this.loading = false;
                                return [2 /*return*/];
                            }
                            return [4 /*yield*/, UserService.update(this.userProp.id, __assign(__assign({}, this.user), { run: this.user.run.split(".").join("") }))];
                        case 3:
                            user = _a.sent();
                            if (!this.user.avatar) return [3 /*break*/, 6];
                            return [4 /*yield*/, compressImage(this.user.avatar)];
                        case 4:
                            avatarCompressed = _a.sent();
                            formData = new FormData();
                            formData.append("file", avatarCompressed);
                            return [4 /*yield*/, UserService.updateAvatar(user.id, formData)];
                        case 5:
                            _a.sent();
                            _a.label = 6;
                        case 6:
                            this.displayToast({
                                type: "success",
                                message: "El usuario se ha modificado correctamente",
                            });
                            this.$emit("changeDialog", false);
                            // this.dialog = false;
                            this.getUsers();
                            return [3 /*break*/, 8];
                        case 7:
                            error_1 = _a.sent();
                            this.displayToast({
                                type: "error",
                                message: "Lo sentimos, ha ocurrido un error. Favor intentar más tarde",
                            });
                            console.log(error_1);
                            return [3 /*break*/, 8];
                        case 8:
                            this.loading = false;
                            return [2 /*return*/];
                    }
                });
            });
        } }),
    watch: {
        invalidStep: function () {
            if (this.invalidStep != 0) {
                this.step = this.invalidStep;
            }
        },
    },
});
