import { __assign } from "tslib";
import Vue from "vue";
// import Documentation from "@/components/sideMenu/Documentation.vue";
// import Folders from "@/components/sideMenu/Folders.vue";
// import Contact from "@/components/sideMenu/Contact.vue";
import Ticket from "@/components/sideMenu/Ticket.vue";
import { mapGetters } from "vuex";
import { modulesAdministrative } from "@/others/constants/modules";
import RequestTicket from "@/components/sideMenu/RequestTicket.vue";
import Maintainers from "@/components/sideMenu/Maintainers.vue";
import TicketReports from "@/components/sideMenu/TicketReports.vue";
export default Vue.extend({
    components: {
        // Documentation,
        // Folders,
        // Contact,
        Ticket: Ticket,
        RequestTicket: RequestTicket,
        Maintainers: Maintainers,
        TicketReports: TicketReports,
    },
    data: function () {
        return __assign(__assign({}, modulesAdministrative), { starter: [
                {
                    text: "Inicio",
                    icon: "fas fa-home",
                    to: { name: "administrativeHome" },
                },
            ] });
    },
    computed: __assign({}, mapGetters({
        modules: "userSession/getModules",
    })),
});
