import Vue from "vue";
import ButtonRefreshTable from "../ButtonRefreshTable.vue";
import TableByFirm from "./TableByFirm.vue";
import TableFirm from "./TableFirm.vue";
import TableReject from "./TableReject.vue";
export default Vue.extend({
    components: {
        TableByFirm: TableByFirm,
        TableReject: TableReject,
        TableFirm: TableFirm,
        ButtonRefreshTable: ButtonRefreshTable
    },
    data: function () {
        return {
            tab: null,
        };
    },
});
