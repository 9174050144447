import Vue from "vue";
export default Vue.extend({
    props: {
        profile: {
            type: String,
            required: true,
        },
    },
    data: function () {
        return {
            document: [
                {
                    text: "Reportes",
                    icon: "fas fa-file-alt",
                    to: { name: "ticketReports" + this.profile },
                },
            ],
        };
    },
});
