import { __awaiter, __generator } from "tslib";
import { DispatchFileService } from "@/services/models/dispatch/dispatch_file";
import Vue from "vue";
export default Vue.extend({
    data: function () {
        return {
            dialog: false,
            isLoading: false,
            items: [],
            headers: [
                {
                    text: "Nombre",
                    sortable: false,
                    align: "start",
                    value: "name",
                },
                { text: "Acciones", sortable: false, align: "start", value: "action" },
            ],
        };
    },
    methods: {
        loadData: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            this.isLoading = true;
                            _a = this;
                            return [4 /*yield*/, DispatchFileService.getAll()];
                        case 1:
                            _a.items = _b.sent();
                            this.isLoading = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
        removeFile: function (item) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.isLoading = true;
                            return [4 /*yield*/, DispatchFileService.remove(item.id)];
                        case 1:
                            _a.sent();
                            this.loadData();
                            this.isLoading = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
    watch: {
        dialog: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (this.dialog) {
                        this.loadData();
                    }
                    return [2 /*return*/];
                });
            });
        },
    },
});
