import { __awaiter, __extends, __generator } from "tslib";
import { BaseService } from './../../base';
var FolderService = /** @class */ (function (_super) {
    __extends(FolderService, _super);
    function FolderService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(FolderService, "entity", {
        get: function () {
            return 'folder';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FolderService, "responseName", {
        get: function () {
            return 'folder';
        },
        enumerable: false,
        configurable: true
    });
    FolderService.getByUserId = function (user_id) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_user_id/" + user_id
                    })];
            });
        });
    };
    return FolderService;
}(BaseService()));
export { FolderService };
