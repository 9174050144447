import Vue from "vue";
import CardArticles from "../cards/CardArticles.vue";
import CardPurchaseRequest from "../cards/CardPurchaseRequest.vue";
import CardPurchaseRequestFiles from "../cards/CardPurchaseRequestFiles.vue";
import CardPurchaseRequestHistory from "../cards/CardPurchaseRequestHistory.vue";
export default Vue.extend({
    props: {
        purchaseProp: {
            type: Object,
            required: true,
        },
    },
    components: {
        CardArticles: CardArticles,
        CardPurchaseRequest: CardPurchaseRequest,
        CardPurchaseRequestHistory: CardPurchaseRequestHistory,
        CardPurchaseRequestFiles: CardPurchaseRequestFiles,
    },
});
