import { __awaiter, __generator } from "tslib";
import { ProviderService } from "@/services/models/purchaseRequest/provider";
import { BudgetService } from "@/services/models/purchaseRequest/budget";
import { PurchaseMethodService } from "@/services/models/purchaseRequest/purchaseMethod";
import { SupplementaryAccountService } from "@/services/models/purchaseRequest/supplementarieAccount";
import { CausalService } from "@/services/models/purchaseRequest/causal";
import { ProjectService } from "@/services/models/purchaseRequest/project";
import { HeaderService } from "@/services/models/purchaseRequest/header";
import Vue from "vue";
import moment from "moment";
import { CdpService } from "@/services/models/purchaseRequest/cdp";
export default Vue.extend({
    props: {
        purchaseProp: {
            type: Object,
        },
        cdpProps: {
            type: Object,
        },
    },
    data: function () {
        return {
            data: {
                id: 0,
                description: "",
                n_request: null,
                pay_month: "",
                authorized_amount: null,
                budget_amount: null,
                purchase_plan: null,
                detail: "",
                date: moment().format("DD-MM-YYYY"),
                title: "",
                accrued_month: "",
                purchase_buyer_id: 0,
                number_id: null,
                user_id: this.getCurrentUserLoggedIn().id,
                cdps_articles: [],
                header_id: 0,
                causal_id: null,
                provider_id: null,
                budget_id: null,
                project_id: null,
                suplementary_account_id: null,
                purchase_method_id: null,
            },
            headerSelected: {
                id: 0,
                name: "",
                description: "",
            },
            menuProps: {
                disabled: false,
            },
            providers: [],
            budgets: [],
            purchasesMethod: [],
            supplementariesAccounts: [],
            causals: [],
            projects: [],
            headers: [],
        };
    },
    methods: {
        changeHeader: function () {
            this.data.header_id = this.headerSelected.id;
            this.data.title = this.headerSelected.name;
            this.data.description = this.headerSelected.description;
        },
        remove: function (item) {
            this.data.cdps_articles = this.data.cdps_articles.filter(function (data) { return data.id != item.id; });
            this.menuProps.disabled = false;
        },
        loadData: function () {
            return __awaiter(this, void 0, void 0, function () {
                var promises;
                var _this = this;
                return __generator(this, function (_a) {
                    this.displayLoader(true);
                    try {
                        promises = [];
                        promises.push(new Promise(function (resolve, reject) {
                            ProviderService.getAll()
                                .then(function (response) {
                                _this.providers = response;
                                console.log(_this.providers);
                                resolve(response);
                            })
                                .catch(function (error) {
                                reject(error);
                            });
                        }));
                        promises.push(new Promise(function (resolve, reject) {
                            HeaderService.getAll()
                                .then(function (response) {
                                _this.headers = response;
                                resolve(response);
                            })
                                .catch(function (error) {
                                reject(error);
                            });
                        }));
                        promises.push(new Promise(function (resolve, reject) {
                            BudgetService.getAll()
                                .then(function (response) {
                                _this.budgets = response;
                                resolve(response);
                            })
                                .catch(function (error) {
                                reject(error);
                            });
                        }));
                        promises.push(new Promise(function (resolve, reject) {
                            PurchaseMethodService.getAll()
                                .then(function (response) {
                                _this.purchasesMethod = response;
                                resolve(response);
                            })
                                .catch(function (error) {
                                reject(error);
                            });
                        }));
                        promises.push(new Promise(function (resolve, reject) {
                            SupplementaryAccountService.getAll()
                                .then(function (response) {
                                _this.supplementariesAccounts = response;
                                resolve(response);
                            })
                                .catch(function (error) {
                                reject(error);
                            });
                        }));
                        promises.push(new Promise(function (resolve, reject) {
                            CausalService.getAll()
                                .then(function (response) {
                                _this.causals = response;
                                resolve(response);
                            })
                                .catch(function (error) {
                                reject(error);
                            });
                        }));
                        promises.push(new Promise(function (resolve, reject) {
                            ProjectService.getAll()
                                .then(function (response) {
                                _this.projects = response;
                                resolve(response);
                            })
                                .catch(function (error) {
                                reject(error);
                            });
                        }));
                        Promise.all(promises)
                            .then(function () { return __awaiter(_this, void 0, void 0, function () {
                            var result;
                            var _this = this;
                            var _a;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        if (!this.cdpProps) return [3 /*break*/, 2];
                                        this.displayLoader(true);
                                        return [4 /*yield*/, CdpService.getById(this.cdpProps.id)];
                                    case 1:
                                        result = _b.sent();
                                        this.data = result;
                                        this.headerSelected = this.data.header;
                                        this.data.description = (_a = this.data.header) === null || _a === void 0 ? void 0 : _a.description;
                                        result.cdps_articles.forEach(function (element) { return _this.data.cdps_articles.push(element.purchase_request_detail); });
                                        _b.label = 2;
                                    case 2:
                                        this.displayLoader(false);
                                        return [2 /*return*/];
                                }
                            });
                        }); })
                            .catch(function (reason) {
                            console.log(reason);
                        });
                    }
                    catch (error) {
                        console.log(error);
                    }
                    return [2 /*return*/];
                });
            });
        },
    },
    mounted: function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.loadData();
                return [2 /*return*/];
            });
        });
    },
});
