import TableBudgetStaffPending from "@/components/purchaseRequest/tables/tableBudgetStaff/TableBudgetStaffPending.vue";
import TableBudgetStaffReady from "@/components/purchaseRequest/tables/tableBudgetStaff/TableBudgetStaffReady.vue";
import TemplateAdministrator from "@/templates/TemplateAdministrator.vue";
import Vue from "vue";
export default Vue.extend({
    components: {
        TemplateAdministrator: TemplateAdministrator,
        TableBudgetStaffPending: TableBudgetStaffPending,
        TableBudgetStaffReady: TableBudgetStaffReady,
    },
    data: function () {
        return {
            tab: null,
        };
    },
});
