import { __awaiter, __generator } from "tslib";
import ChipUserWithMenu from "@/components/general/ChipUserWithMenu.vue";
import { DocumentDetailReadService } from "@/services/models/document/documentDetailRead";
import Vue from "vue";
export default Vue.extend({
    components: { ChipUserWithMenu: ChipUserWithMenu },
    props: {
        detailProp: {
            type: Object,
        },
    },
    data: function () {
        return {
            isLoading: false,
            headers: [{ text: "Usuario" }, { text: "Ultima Actualización" }],
            list: [],
        };
    },
    mounted: function () {
        this.loadData();
    },
    methods: {
        loadData: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            this.isLoading = true;
                            _a = this;
                            return [4 /*yield*/, DocumentDetailReadService.getDocumentDetailId(this.detailProp.id)];
                        case 1:
                            _a.list = _b.sent();
                            this.isLoading = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
});
