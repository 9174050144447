import { __assign, __awaiter, __generator } from "tslib";
import Vue from "vue";
import InputSelectUsers from "@/components/document/ShowDocument/panelDetail/InputSelectUsers.vue";
import { mapActions } from "vuex";
import store from "@/store";
export default Vue.extend({
    components: { InputSelectUsers: InputSelectUsers },
    data: function () {
        return {
            dialog: false,
            states: [
                {
                    value: "solicitado",
                    text: "solicitado"
                },
                {
                    value: "en proceso",
                    text: "en proceso"
                },
                {
                    value: "cerrado",
                    text: "validado"
                },
                {
                    value: "cerrado permanentemente",
                    text: "validado permanentemente"
                },
            ],
            filter: {}
        };
    },
    methods: __assign(__assign({}, mapActions({
        setTickets: "ticket/getTicketFilter",
    })), { saveFilter: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            store.commit('ticket/setDataFilterTicket', this.filter);
                            return [4 /*yield*/, this.setTickets()];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        } }),
    watch: {
        dialog: function () {
            if (this.dialog) {
                this.filter.state = null;
            }
        }
    },
});
