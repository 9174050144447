import { __awaiter, __generator } from "tslib";
import InputSelectLocation from "@/components/document/ShowDocument/panelDetail/InputSelectLocation.vue";
import InputSelectUsers from "@/components/document/ShowDocument/panelDetail/InputSelectUsers.vue";
import ModalCreateUser from "@/components/manteiners/maintainerUsers/createUser/ModalCreateUser.vue";
import { GroupService } from "@/services/models/group/group";
import { TicketTypeService } from "@/services/models/ticket/ticketType";
import Vue from "vue";
export default Vue.extend({
    name: "FormTicket",
    components: { InputSelectUsers: InputSelectUsers, ModalCreateUser: ModalCreateUser, InputSelectLocation: InputSelectLocation },
    props: {
        selectUser: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            types: [],
            group: [],
            ticket: {
                user_id: 0,
                location_id: 0,
                subject: "",
                title: "",
                ticket_type_id: 1,
                state: "solicitado",
                group_id: 1,
                created_by_id: this.getCurrentUserLoggedIn().id,
            },
        };
    },
    mounted: function () {
        this.loadData();
        if (!this.selectUser) {
            this.ticket.user_id = this.getCurrentUserLoggedIn().id;
            this.ticket.location_id = this.getUserLocation().location_id;
        }
    },
    methods: {
        changeSelect: function () {
            var _a, _b;
            this.ticket.user_id = (_a = this.$refs.selectedUser.usersSelects) === null || _a === void 0 ? void 0 : _a[0].id;
            this.$refs.selectedLocation.selectLocation((_b = this.$refs.selectedUser.usersSelects[0].users_locations) === null || _b === void 0 ? void 0 : _b[0].location);
            this.changeLocation();
        },
        changeLocation: function () {
            var _a;
            if (this.$refs.selectedLocation.selectedLocations.length) {
                this.ticket.location_id = (_a = this.$refs.selectedLocation.selectedLocations) === null || _a === void 0 ? void 0 : _a[0].id;
            }
            else {
                this.ticket.location_id = 0;
            }
        },
        loadData: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            this.displayLoader(true);
                            _a = this;
                            return [4 /*yield*/, GroupService.getByStablishmentId(this.getCurrentUserLoggedIn().stablishment_id)];
                        case 1:
                            _a.group = _c.sent();
                            _b = this;
                            return [4 /*yield*/, TicketTypeService.getByGroupId(this.ticket.group_id)];
                        case 2:
                            _b.types = _c.sent();
                            this.displayLoader(false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        ChangeGroup: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            this.displayLoader(true);
                            _a = this;
                            return [4 /*yield*/, TicketTypeService.getByGroupId(this.ticket.group_id)];
                        case 1:
                            _a.types = _b.sent();
                            this.displayLoader(false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        validateForm: function () {
            if (this.ticket.location_id == 0 || this.ticket.user_id == 0) {
                return false;
            }
            return this.$refs.formCreate.validate();
        },
    },
});
