import { __assign } from "tslib";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import DetailTableRequestTicket from "./DetailTableRequestTicket.vue";
import ButtonTake from "./ButtonTake.vue";
import ButtonTransfer from "./ButtonTransfer.vue";
import ButtonFinish from "./ButtonFinish.vue";
import ButtonHistory from "./ButtonHistory.vue";
import ModalChatTicket from "./chat/ModalChatTicket.vue";
import ButtonShowClosedTicket from "./closedTicket/ButtonShowClosedTicket.vue";
import CreateTicket from "./createTicket/CreateTicket.vue";
import ButtonClosedTicket from "./closedTicket/ButtonClosedTicket.vue";
import ModalFilterTickets from "./filter/ModalFilterTickets.vue";
import store from "@/store";
import GenerateSingleReport from "./GenerateSingleReport.vue";
import { CREATE_TICKET, TAKE_TICKET } from "@/others/constants/permissions";
export default Vue.extend({
    name: "TableRequestTickets",
    components: {
        DetailTableRequestTicket: DetailTableRequestTicket,
        ButtonTake: ButtonTake,
        ButtonTransfer: ButtonTransfer,
        ButtonFinish: ButtonFinish,
        ButtonHistory: ButtonHistory,
        ModalChatTicket: ModalChatTicket,
        ButtonShowClosedTicket: ButtonShowClosedTicket,
        CreateTicket: CreateTicket,
        ButtonClosedTicket: ButtonClosedTicket,
        ModalFilterTickets: ModalFilterTickets,
        GenerateSingleReport: GenerateSingleReport,
    },
    data: function () {
        return {
            createTicketPermission: CREATE_TICKET,
            takeTicketPermission: TAKE_TICKET,
            page: 1,
            headers: [
                {
                    text: "ID",
                    align: "start",
                    value: "id",
                    sortable: false,
                    width: 50,
                },
                { text: "Titulo", sortable: false, align: "start", value: "title" },
                {
                    text: "Tipo de ticket",
                    sortable: false,
                    align: "start",
                    value: "ticket_type.name",
                },
                {
                    text: "Solicitante",
                    sortable: false,
                    align: "start",
                    value: "user",
                },
                {
                    text: "Fecha",
                    sortable: false,
                    align: "start",
                    value: "created_at",
                },
                {
                    text: "Estado",
                    sortable: false,
                    align: "start",
                    value: "state",
                },
                {
                    text: "Acciones",
                    sortable: false,
                    align: "start",
                    value: "actions",
                },
            ],
        };
    },
    computed: __assign({}, mapGetters({
        getTickets: "ticket/getTicketFilter",
        getLoading: "ticket/getLoading",
        permissions: "userSession/getPermissions",
    })),
    mounted: function () {
        this.changePage();
    },
    methods: __assign(__assign({}, mapActions({
        setTickets: "ticket/getTicketFilter",
    })), { getState: function (state) {
            var stateToShow = "";
            if (state == "solicitado") {
                stateToShow = "solicitado";
            }
            else if (state == "en proceso") {
                stateToShow = "en proceso";
            }
            else if (state == "cerrado") {
                stateToShow = "validado";
            }
            else if (state == "cerrado permanentemente") {
                stateToShow = "validado permanentemente";
            }
            return stateToShow.toUpperCase();
        },
        itemColorBackground: function (item) {
            var _a, _b;
            var lastResponsable = ((_a = item.tickets_responsables) === null || _a === void 0 ? void 0 : _a.length) ? (_b = item.tickets_responsables) === null || _b === void 0 ? void 0 : _b[item.tickets_responsables.length - 1].user_id : 0;
            if (lastResponsable == this.getCurrentUserLoggedIn().id)
                return "yellow lighten-4";
        },
        changePage: function (page) {
            if (page) {
                this.page = page;
            }
            this.$store.commit("ticket/setPageTicketFilter", this.page);
            this.setTickets();
        },
        refreshTable: function () {
            this.page = 1;
            store.commit("ticket/setDataFilterTicket", {});
            this.changePage();
        },
        getColor: function (state) {
            if (state == "cerrado")
                return "success";
            else if (state == "en proceso")
                return "warning";
            else if (state == "cerrado permanentemente")
                return "error";
            else
                return "primary";
        } }),
});
