import { __awaiter, __extends, __generator } from "tslib";
import { BaseService } from './../../base';
var DocumentDetailReadService = /** @class */ (function (_super) {
    __extends(DocumentDetailReadService, _super);
    function DocumentDetailReadService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(DocumentDetailReadService, "entity", {
        get: function () {
            return 'document_detail_read';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DocumentDetailReadService, "responseName", {
        get: function () {
            return 'document_detail_read';
        },
        enumerable: false,
        configurable: true
    });
    DocumentDetailReadService.getDocumentDetailId = function (document_id) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_document_id/" + document_id
                    })];
            });
        });
    };
    return DocumentDetailReadService;
}(BaseService()));
export { DocumentDetailReadService };
