import Vue from 'vue'
import Axios from 'axios';
import store from '@/store';

Axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/api`;
Axios.defaults.headers.common.Accept = 'application/json';

// const axios = require('axios').create({
//   baseURL: process.env.VUE_APP_API_URL
// });

Axios.interceptors.response.use(
  response => response,
  (error) => {
    if (error.response.status === 401) {
      store.commit('relogin/setRelogin');
    }
    if (error.response.status === 500) {
      store.commit("toast/showToast", {
        type: "error",
        title: "Error",
        message: "Error con el servidor, favor de intentar más tarde",
        milliseconds: 5000
      })
    }
    return Promise.reject(error);
  },
);

Axios.interceptors.request.use((request) => {
  const token = `Bearer ${window.localStorage.getItem("token")}`;
  if (token) {
    request.headers.Authorization = token;
  }
  return request;
}, error => {
  return Promise.reject(error)
});

// Bind Axios to Vue.
Vue.$http = Axios;
Object.defineProperty(Vue.prototype, '$http2', {
  get() {
    return Axios;
  },
});
