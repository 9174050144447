import { GROUP_MAINTAINER_MODULE, USER_MAINTAINER_MODULE } from "@/others/constants/modules";
import Vue from "vue";
export default Vue.extend({
    props: {
        profile: {
            type: String,
            required: true,
        },
        modules: {
            type: Array,
            required: true,
        }
    },
    data: function () {
        return {
            manteiners: [
                {
                    text: "Mantenedor Usuarios",
                    icon: "fa fa-users",
                    to: { name: "maintainerUsers" + this.profile },
                    moduleId: USER_MAINTAINER_MODULE
                    // canShow: this.modules.includes(USER_MAINTAINER_MODULE)
                },
                {
                    text: "Mantenedor de Grupos",
                    icon: "fa fa-users-cog",
                    to: { name: "group" + this.profile },
                    moduleId: GROUP_MAINTAINER_MODULE
                    // canShow: this.modules.includes(GROUP_MAINTAINER_MODULE)
                }
            ],
        };
    },
    computed: {
        maintainersFiltered: function () {
            var _this = this;
            return this.manteiners.filter(function (maintainer) { return _this.modules.includes(maintainer.moduleId); });
        }
    }
});
