import { __assign } from "tslib";
import Vue from "vue";
import ChangePassword from "./ChangePassword.vue";
import { mapGetters } from "vuex";
import DataCurrentUser from "./DataCurrentUser.vue";
import Avatar from "./Avatar.vue";
export default Vue.extend({
    components: {
        ChangePassword: ChangePassword,
        DataCurrentUser: DataCurrentUser,
        Avatar: Avatar,
    },
    data: function () {
        return {
            items: [],
        };
    },
    computed: __assign({}, mapGetters({
        user: "users/getUser",
        loading: "users/getLoading",
    })),
});
