import { __assign } from "tslib";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import ButtonChangeRead from "./ButtonChangeRead.vue";
import ButtonAddToFolder from "./folder/ButtonAddToFolder.vue";
import ShowDocumentDetail from "./ShowDocument/ShowDocumentDetail.vue";
export default Vue.extend({
    components: {
        ShowDocumentDetail: ShowDocumentDetail,
        ButtonChangeRead: ButtonChangeRead,
        ButtonAddToFolder: ButtonAddToFolder,
    },
    data: function () {
        return {
            page: 1,
            headers: [
                {
                    text: "ID",
                    align: "start",
                    value: "id",
                    sortable: false,
                },
                { text: "Número", sortable: false, align: "start", value: "number" },
                {
                    text: "Descripción",
                    sortable: false,
                    align: "start",
                    value: "description",
                },
                {
                    text: "Tipo de Documento",
                    sortable: false,
                    align: "start",
                    value: "document_type.name",
                },
                {
                    text: "Depto.",
                    sortable: false,
                    align: "start",
                    value: "location.name",
                },
                { text: "Fecha", sortable: false, align: "start", value: "created_at" },
                {
                    text: "Última Actualización",
                    sortable: false,
                    align: "start",
                    value: "updated_at",
                },
                { text: "Acciones", sortable: false, align: "start", value: "action" },
            ],
        };
    },
    computed: __assign({}, mapGetters({
        getDocuments: "documents/getRequestDocument",
        getLoading: "documents/getLoading",
    })),
    methods: __assign({ getLocationDate: function (document) {
            var date;
            if (this.getProfile() == "opartes") {
                if (document.documents_states_locations) {
                    date = document.documents_states_locations[0].updated_at;
                }
            }
            else {
                if (document.documents_states) {
                    date = document.documents_states[0].updated_at;
                }
            }
            return date;
        },
        stateColor: function (document) {
            var classColor = "";
            var documentState = "";
            if (this.getProfile() == "opartes") {
                if (document.documents_states_locations) {
                    documentState = document.documents_states_locations[0].state;
                }
            }
            else {
                if (document.documents_states) {
                    documentState = document.documents_states[0].state;
                }
            }
            switch (documentState) {
                case "recibido":
                    classColor = "primary lighten-4";
                    break;
                case "enviado":
                    classColor = "yellow lighten-4";
                    break;
                default:
                    classColor = "";
                    break;
            }
            return classColor;
        },
        changePage: function () {
            this.$store.commit("documents/setPageRequestDocument", this.page);
            if (this.getProfile() == "opartes") {
                this.setDocument({ location_id: this.getUserLocation().location_id });
            }
            else {
                this.setDocument({ id: this.getCurrentUserLoggedIn().id });
            }
        } }, mapActions({
        setDocument: "documents/getRequestDocument",
    })),
});
