import { __assign } from "tslib";
import Vue from "vue";
import Documentation from "@/components/sideMenu/Documentation.vue";
import Folders from "@/components/sideMenu/Folders.vue";
import Maintainers from "@/components/sideMenu/Maintainers.vue";
import Contact from "@/components/sideMenu/Contact.vue";
import Ticket from "@/components/sideMenu/Ticket.vue";
import RequestTicket from "@/components/sideMenu/RequestTicket.vue";
import PurchaseRequest from "@/components/sideMenu/purchaseRequestMenu/PurchaseRequest.vue";
import TicketReports from "@/components/sideMenu/TicketReports.vue";
import { modulesAdministrator } from "@/others/constants/modules";
import { mapGetters } from "vuex";
import PurchaseAuthorizer from "@/components/sideMenu/purchaseRequestMenu/PurchaseAuthorizer.vue";
import SupplyManager from "@/components/sideMenu/purchaseRequestMenu/SupplyManager.vue";
import UnityManager from "@/components/sideMenu/purchaseRequestMenu/UnityManager.vue";
import Buyer from "@/components/sideMenu/purchaseRequestMenu/Buyer.vue";
import BudgetManager from "@/components/sideMenu/purchaseRequestMenu/BudgetManager.vue";
import Budget from "@/components/sideMenu/purchaseRequestMenu/Budget.vue";
import FinancialManager from "@/components/sideMenu/purchaseRequestMenu/FinancialManager.vue";
export default Vue.extend({
    components: {
        PurchaseAuthorizer: PurchaseAuthorizer,
        SupplyManager: SupplyManager,
        UnityManager: UnityManager,
        Buyer: Buyer,
        BudgetManager: BudgetManager,
        Budget: Budget,
        FinancialManager: FinancialManager,
        Documentation: Documentation,
        Folders: Folders,
        Maintainers: Maintainers,
        Contact: Contact,
        Ticket: Ticket,
        RequestTicket: RequestTicket,
        PurchaseRequest: PurchaseRequest,
        TicketReports: TicketReports,
    },
    data: function () {
        return __assign(__assign({}, modulesAdministrator), { starter: [
                {
                    text: "Inicio",
                    icon: "fas fa-home",
                    to: { name: "administratorHome" },
                },
            ] });
    },
    computed: __assign({}, mapGetters({
        modules: "userSession/getModules",
    })),
});
