import { __awaiter, __generator } from "tslib";
// import ImgAvatar from "@/components/perfilUserCard/ImgAvatar.vue";
import { LocationService } from "@/services/models/user/location";
import Vue from "vue";
export default Vue.extend({
    // components: { ImgAvatar },
    props: {
        label: {
            default: false,
        },
        addCurrentUser: {
            type: Boolean,
            default: false,
        },
        multipleSelect: {
            type: Boolean,
        },
        iconProp: {
            type: String,
            default: "fa-user-friends",
        },
        functionChange: {
            type: Function,
        },
    },
    data: function () {
        return {
            selectedLocations: [],
            menuProps: {
                disabled: false,
            },
            loading: false,
            locations: [],
        };
    },
    // watch: {
    //   selectedLocations() {
    //     if (this.selectedLocations.length >= 1 && !this.multipleSelect) {
    //       // this.menuProps.disabled = true;
    //       this.selectedLocations = [this.selectedLocations[1]];
    //     }
    //   },
    // },
    mounted: function () {
        this.getLocations();
    },
    methods: {
        test: function () {
            if (this.selectedLocations.length > 1 && !this.multipleSelect) {
                this.selectedLocations = [this.selectedLocations[1]];
            }
            if (this.functionChange) {
                this.functionChange();
            }
        },
        selectLocation: function (location) {
            var locationToChange = this.locations.find(function (value) { return value.id == location.id; });
            if (locationToChange) {
                this.selectedLocations = [locationToChange];
            }
        },
        getLocations: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            this.loading = true;
                            _a = this;
                            return [4 /*yield*/, LocationService.getByStablishmentId(this.getCurrentUserLoggedIn().stablishment_id)];
                        case 1:
                            _a.locations = _b.sent();
                            this.loading = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
        remove: function (locationToRemove) {
            this.selectedLocations = this.selectedLocations.filter(function (location) { return location.id != locationToRemove.id; });
            this.menuProps.disabled = false;
        },
    },
});
