import { __assign } from "tslib";
import Vue from "vue";
import { mapGetters } from "vuex";
export default Vue.extend({
    props: {
        searchDocuments: {
            required: true,
            type: Function,
        },
        isRequested: {
            type: Boolean,
        },
        hideRead: {
            type: Boolean,
        },
    },
    data: function () {
        return {
            chipSelected: "",
            loadingSent: true,
        };
    },
    computed: __assign({}, mapGetters({
        notReadDocuments: "documents/getRequestedDocumentsNotRead",
    })),
    methods: {
        loadSent: function () {
            if (this.chipSelected == "enviado") {
                this.chipSelected = "";
                this.searchDocuments({ id: this.getCurrentUserLoggedIn().id });
            }
            else {
                this.chipSelected = "enviado";
                this.searchDocuments({
                    id: this.getCurrentUserLoggedIn().id,
                    state: "enviado",
                });
            }
        },
        loadReceived: function () {
            if (this.chipSelected == "recibido") {
                this.chipSelected = "";
                this.searchDocuments({ id: this.getCurrentUserLoggedIn().id });
            }
            else {
                this.chipSelected = "recibido";
                this.searchDocuments({
                    id: this.getCurrentUserLoggedIn().id,
                    state: "recibido",
                });
            }
        },
        loadRead: function () {
            if (this.chipSelected == "leido") {
                this.chipSelected = "";
                this.searchDocuments({ id: this.getCurrentUserLoggedIn().id });
            }
            else {
                this.chipSelected = "leido";
                this.searchDocuments({
                    id: this.getCurrentUserLoggedIn().id,
                    state_read: "leido",
                });
            }
        },
        loadNoRead: function () {
            if (this.chipSelected == "no leido") {
                this.chipSelected = "";
                this.searchDocuments({ id: this.getCurrentUserLoggedIn().id });
            }
            else {
                this.chipSelected = "no leido";
                this.searchDocuments({
                    id: this.getCurrentUserLoggedIn().id,
                    state_read: "no leido",
                });
            }
        },
    },
});
