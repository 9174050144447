import { __awaiter, __generator } from "tslib";
import { PurchaseBuyerService } from "@/services/models/purchaseRequest/purchaseBuyer";
import Vue from "vue";
export default Vue.extend({
    props: {
        purchaseBuyerId: {
            type: Number,
            required: true,
        },
    },
    data: function () {
        return {
            data: {},
            show: false
        };
    },
    methods: {
        loadData: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            this.displayLoader(true);
                            this.show = false;
                            _a = this;
                            return [4 /*yield*/, PurchaseBuyerService.getById(this.purchaseBuyerId)];
                        case 1:
                            _a.data = _b.sent();
                            this.show = true;
                            this.displayLoader(false);
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
    mounted: function () {
        this.loadData();
    },
});
