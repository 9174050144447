import { __assign, __awaiter, __generator } from "tslib";
import { PurchaseRequestService } from "@/services/models/purchaseRequest/purchaseRequest";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
    props: {
        purchaseProp: {
            type: Object,
        },
    },
    data: function () {
        return {
            dialog: false
        };
    },
    methods: __assign(__assign({}, mapActions({
        recharge: "rechargeTablesPurchaseRequest/getRechargeTables",
    })), { deletePurchase: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.displayLoader(true);
                            return [4 /*yield*/, PurchaseRequestService.remove(this.purchaseProp.id)];
                        case 1:
                            _a.sent();
                            this.recharge();
                            this.dialog = false;
                            this.displayLoader(false);
                            return [2 /*return*/];
                    }
                });
            });
        } }),
});
