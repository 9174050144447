import { __extends } from "tslib";
import { BaseService } from './../../base';
var CausalService = /** @class */ (function (_super) {
    __extends(CausalService, _super);
    function CausalService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(CausalService, "entity", {
        get: function () {
            return 'causal';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CausalService, "responseName", {
        get: function () {
            return 'causal';
        },
        enumerable: false,
        configurable: true
    });
    return CausalService;
}(BaseService()));
export { CausalService };
