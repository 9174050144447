import Vue from "vue";
export default Vue.extend({
    props: {
        usersProfiles: {
            type: Array,
            required: true,
        },
        deleteProfile: {
            type: Function,
            required: true
        }
    },
    data: function () {
        return {
            headers: [
                {
                    text: "Puesto",
                    sortable: false,
                    value: "position.name",
                },
                {
                    text: "Locación",
                    sortable: false,
                    value: "location.name",
                },
                {
                    text: "Eliminar",
                    sortable: false,
                    value: "delete",
                },
            ],
        };
    },
});
