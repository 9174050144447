import { __extends } from "tslib";
import { BaseService } from './../../base';
var PurchaseBuyerFileService = /** @class */ (function (_super) {
    __extends(PurchaseBuyerFileService, _super);
    function PurchaseBuyerFileService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(PurchaseBuyerFileService, "entity", {
        get: function () {
            return 'purchase_buyer_file';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PurchaseBuyerFileService, "responseName", {
        get: function () {
            return 'purchase_buyer_file';
        },
        enumerable: false,
        configurable: true
    });
    return PurchaseBuyerFileService;
}(BaseService()));
export { PurchaseBuyerFileService };
