import { __extends } from "tslib";
import { BaseService } from './../../base';
var DispatchFileService = /** @class */ (function (_super) {
    __extends(DispatchFileService, _super);
    function DispatchFileService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(DispatchFileService, "entity", {
        get: function () {
            return 'dispatch_file';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DispatchFileService, "responseName", {
        get: function () {
            return 'dispatch_file';
        },
        enumerable: false,
        configurable: true
    });
    return DispatchFileService;
}(BaseService()));
export { DispatchFileService };
