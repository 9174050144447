import { __assign, __awaiter, __generator } from "tslib";
import { CdpService } from "@/services/models/purchaseRequest/cdp";
import { PurchaseBuyerHistoryService } from "@/services/models/purchaseRequest/purchaseBuyerHistory";
import { PurchaseBuyerStateService } from "@/services/models/purchaseRequest/purchaseBuyerState";
import { PurchaseHistoryService } from "@/services/models/purchaseRequest/purchaseHistory";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
    props: {
        CdpProps: {
            required: true,
            type: Object,
        },
        PurchaseBuyerProps: {
            required: true,
            type: Object,
        },
    },
    data: function () {
        return {
            loadingOverlay: false,
            observations: null,
        };
    },
    methods: __assign(__assign({}, mapActions({
        setPending: "purchaseBuyer/getPurchaseBuyerPending",
        setReady: "purchaseBuyer/getPurchaseBuyerReady",
    })), { loadData: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.setPending({
                        userId: this.getCurrentUserLoggedIn().id,
                        state: "pendiente",
                        step: "financialManager",
                    });
                    this.setReady({
                        userId: this.getCurrentUserLoggedIn().id,
                        state: "finalizado",
                        step: "financialManager",
                    });
                    return [2 /*return*/];
                });
            });
        },
        rejectFirm: function () {
            return __awaiter(this, void 0, void 0, function () {
                var error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.loadingOverlay = true;
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 6, , 7]);
                            //Modificar estado del CDP
                            return [4 /*yield*/, CdpService.update(this.CdpProps.id, __assign(__assign({}, this.CdpProps), { state: "rechazado" }))];
                        case 2:
                            //Modificar estado del CDP
                            _a.sent();
                            return [4 /*yield*/, this.VerifyAfterUpdateBuyerState()];
                        case 3:
                            _a.sent();
                            //Agregar history
                            return [4 /*yield*/, PurchaseHistoryService.create({
                                    id: 0,
                                    purchase_request_id: this.PurchaseBuyerProps.purchase_request.id,
                                    state: "rechazo de Cdp",
                                    user_origin_id: this.getCurrentUserLoggedIn().id,
                                    user_destination_id: this.getCurrentUserLoggedIn().id,
                                })];
                        case 4:
                            //Agregar history
                            _a.sent();
                            return [4 /*yield*/, PurchaseBuyerHistoryService.create({
                                    id: 0,
                                    state: "rechazo de Cdp",
                                    user_origin_id: 0,
                                    user_destination_id: 0,
                                    purchase_buyer_id: this.PurchaseBuyerProps.id,
                                })];
                        case 5:
                            _a.sent();
                            this.displayToast({
                                type: "success",
                                message: "La firma se ha rechazado con éxito",
                            });
                            this.loadData();
                            return [3 /*break*/, 7];
                        case 6:
                            error_1 = _a.sent();
                            console.log(error_1);
                            this.displayToast({
                                type: "error",
                                message: "Lo sentimos ha ocurrido un error",
                            });
                            return [3 /*break*/, 7];
                        case 7:
                            this.loadingOverlay = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
        VerifyAfterUpdateBuyerState: function () {
            return __awaiter(this, void 0, void 0, function () {
                var validateStateCreate, CDPS, PURCHASEBUYERSTATE;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            validateStateCreate = true;
                            return [4 /*yield*/, CdpService.getPurchaseBuyerId(this.PurchaseBuyerProps.id)];
                        case 1:
                            CDPS = _a.sent();
                            CDPS.forEach(function (cdp) {
                                if (cdp.state == "creado") {
                                    validateStateCreate = false;
                                }
                            });
                            if (!validateStateCreate) return [3 /*break*/, 4];
                            return [4 /*yield*/, PurchaseBuyerStateService.getByPurchaseBuyerIdUserId(this.PurchaseBuyerProps.id, this.getCurrentUserLoggedIn().id)];
                        case 2:
                            PURCHASEBUYERSTATE = _a.sent();
                            if (!PURCHASEBUYERSTATE.length) return [3 /*break*/, 4];
                            return [4 /*yield*/, PurchaseBuyerStateService.update(PURCHASEBUYERSTATE[0].id, __assign(__assign({}, PURCHASEBUYERSTATE[0]), { state: "finalizado" }))];
                        case 3:
                            _a.sent();
                            _a.label = 4;
                        case 4: return [2 /*return*/];
                    }
                });
            });
        } }),
});
