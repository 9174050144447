import { __awaiter, __extends, __generator } from "tslib";
import { BaseService } from './../../base';
var PurchaseRequestDetailService = /** @class */ (function (_super) {
    __extends(PurchaseRequestDetailService, _super);
    function PurchaseRequestDetailService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(PurchaseRequestDetailService, "entity", {
        get: function () {
            return 'purchase_request_detail';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PurchaseRequestDetailService, "responseName", {
        get: function () {
            return 'purchase_request_detail';
        },
        enumerable: false,
        configurable: true
    });
    PurchaseRequestDetailService.getByPurchaseId = function (id) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_purchase_request/" + id
                    })];
            });
        });
    };
    return PurchaseRequestDetailService;
}(BaseService()));
export { PurchaseRequestDetailService };
