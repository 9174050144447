import { __assign, __awaiter, __generator, __spreadArrays } from "tslib";
import { PositionService } from "@/services/models/user/position";
import Vue from "vue";
import { LocationService } from "@/services/models/user/location";
import { mapActions, mapGetters } from "vuex";
import UserProfilesTableEdit from "./UserProfilesTableEdit.vue";
import { UserLocationService } from "@/services/models/user/userLocation";
import { UserService } from "@/services/models/user/user";
var DIRECCION = 1;
var SUBDIRECCION = 2;
var DEPARTAMENTO = 3;
var UNIDAD = 4;
export default Vue.extend({
    props: {
        userProp: {
            required: true,
            type: Object
        }
    },
    components: { UserProfilesTableEdit: UserProfilesTableEdit },
    data: function () {
        return {
            positions: [],
            loadingPosition: false,
            position_id: 0,
            locationSelectedId: 0,
            direction_id: 0,
            directions: [],
            loadingDirection: false,
            subdirection_id: 0,
            subdirections: [],
            loadingSubdirection: false,
            department_id: 0,
            departments: [],
            loadingDepartment: false,
            unity_id: 0,
            unities: [],
            loadingUnity: false,
            usersLocations: [],
            loading: false,
        };
    },
    computed: __assign({}, mapGetters({
        stablishmentSelected: "stablishments/getStablishmentSelected",
    })),
    methods: __assign(__assign({}, mapActions({
        getUsers: "users/getUsers",
    })), { deleteProfile: function (userLocation) {
            return __awaiter(this, void 0, Promise, function () {
                var error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.loading = true;
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, UserLocationService.remove(userLocation.id)];
                        case 2:
                            _a.sent();
                            this.loadUsersLocations();
                            this.displayToast({
                                type: 'success',
                                message: 'El perfil se ha eliminado correctamente'
                            });
                            this.getUsers();
                            return [3 /*break*/, 4];
                        case 3:
                            error_1 = _a.sent();
                            console.error(error_1);
                            this.displayToast({
                                type: 'error',
                                message: 'Ha ocurrido un error al eliminar el perfil'
                            });
                            return [3 /*break*/, 4];
                        case 4:
                            this.loading = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
        getLocationSelected: function () {
            var _this = this;
            var locationSelected = {
                id: 0,
                name: "Sin locación",
                stablishment_id: 0,
                location_type_id: 0,
            };
            if (this.unity_id !== 0 && this.unity_id != -1) {
                var unitySelected = this.unities.find(function (unity) { return unity.id == _this.unity_id; });
                if (unitySelected) {
                    locationSelected = unitySelected;
                }
                return locationSelected;
            }
            if (this.department_id !== 0 && this.department_id != -1) {
                var departmentSelected = this.departments.find(function (department) { return department.id == _this.department_id; });
                if (departmentSelected) {
                    locationSelected = departmentSelected;
                }
                return locationSelected;
            }
            if (this.subdirection_id !== 0 && this.subdirection_id != -1) {
                var subdirectionSelected = this.subdirections.find(function (subdirection) { return subdirection.id == _this.subdirection_id; });
                if (subdirectionSelected) {
                    locationSelected = subdirectionSelected;
                }
                return locationSelected;
            }
            if (this.direction_id !== 0 && this.direction_id != -1) {
                var directionSelected = this.directions.find(function (direction) { return direction.id == _this.direction_id; });
                if (directionSelected) {
                    locationSelected = directionSelected;
                }
                return locationSelected;
            }
            return locationSelected;
        },
        addUserLocation: function () {
            return __awaiter(this, void 0, Promise, function () {
                var locationSelected_1, isLocationAdded, positionSelected, userLocationResponse, error_2;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            locationSelected_1 = this.getLocationSelected();
                            isLocationAdded = this.usersLocations.find(function (userLocation) {
                                return userLocation.position_id == _this.position_id &&
                                    userLocation.location_id == locationSelected_1.id;
                            });
                            if (locationSelected_1.id == 0) {
                                this.displayToast({
                                    type: "warning",
                                    message: "Por favor seleccionar un tipo de locación",
                                });
                                return [2 /*return*/];
                            }
                            if (this.position_id == 0) {
                                this.displayToast({
                                    type: "warning",
                                    message: "Por favor seleccionar cargo",
                                });
                                return [2 /*return*/];
                            }
                            if (isLocationAdded) {
                                this.displayToast({
                                    type: "warning",
                                    message: "Este locación y puesto ya está asignada al usuario",
                                });
                                return [2 /*return*/];
                            }
                            this.loading = true;
                            positionSelected = this.positions.find(function (position) { return position.id == _this.position_id; });
                            return [4 /*yield*/, UserLocationService.create({
                                    id: 0,
                                    location_id: locationSelected_1.id,
                                    user_id: this.userProp.id,
                                    position_id: this.position_id,
                                })];
                        case 1:
                            userLocationResponse = _a.sent();
                            this.displayToast({
                                type: 'success',
                                message: 'El perfil se ha agregado correctamente'
                            });
                            this.loadUsersLocations();
                            this.getUsers();
                            this.usersLocations.push({
                                position_id: this.position_id,
                                position: positionSelected,
                                user_id: 0,
                                location_id: locationSelected_1.id,
                                location: locationSelected_1,
                                id: userLocationResponse.id
                            });
                            this.cleanProfileData();
                            return [3 /*break*/, 3];
                        case 2:
                            error_2 = _a.sent();
                            console.error(error_2);
                            this.displayToast({
                                type: 'error',
                                message: 'Lo sentimos, ha ocurrido un error'
                            });
                            return [3 /*break*/, 3];
                        case 3:
                            this.loading = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
        cleanProfileData: function () {
            this.position_id = 0;
            this.direction_id = 0;
            this.subdirection_id = 0;
            this.subdirections = [];
            this.department_id = 0;
            this.departments = [];
            this.unity_id = 0;
            this.unities = [];
            this.$refs.myForm.resetValidation();
        },
        validateForm: function () {
            var isValid = this.usersLocations.length != 0;
            this.$emit("loadUsersLocations", this.usersLocations);
            this.$emit("isValid", isValid);
            return {
                isValid: isValid,
                message: "Por favor asignar por lo menos un perfil",
            };
        },
        loadPositions: function () {
            return __awaiter(this, void 0, Promise, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            this.loadingPosition = true;
                            _a = this;
                            return [4 /*yield*/, PositionService.getAll()];
                        case 1:
                            _a.positions = _b.sent();
                            this.loadingPosition = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
        loadDirections: function () {
            return __awaiter(this, void 0, Promise, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            this.loadingDirection = true;
                            _a = this;
                            return [4 /*yield*/, LocationService.getByLocationTypeStablishment(DIRECCION, this.stablishmentSelected.id)];
                        case 1:
                            _a.directions = _b.sent();
                            this.subdirections = [];
                            this.departments = [];
                            this.unities = [];
                            this.loadingDirection = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
        loadSubdirections: function () {
            return __awaiter(this, void 0, Promise, function () {
                var subdirectionsReponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.loadingSubdirection = true;
                            this.subdirections = [];
                            return [4 /*yield*/, LocationService.getByParentLocationTypeStablishment(SUBDIRECCION, this.stablishmentSelected.id, this.direction_id)];
                        case 1:
                            subdirectionsReponse = _a.sent();
                            if (subdirectionsReponse.length) {
                                //En caso que no se quiera elegir subdirección
                                this.subdirections = __spreadArrays([
                                    {
                                        id: -1,
                                        name: "Sin subdirección",
                                        location_type_id: 0,
                                        stablishment_id: 0,
                                    }
                                ], subdirectionsReponse);
                            }
                            this.subdirection_id = 0;
                            this.departments = [];
                            console.log(this.departments);
                            this.unities = [];
                            this.loadingSubdirection = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
        loadDepartments: function () {
            return __awaiter(this, void 0, Promise, function () {
                var departmentsResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.loadingDepartment = true;
                            this.departments = [];
                            return [4 /*yield*/, LocationService.getByParentLocationTypeStablishment(DEPARTAMENTO, this.stablishmentSelected.id, this.subdirection_id)];
                        case 1:
                            departmentsResponse = _a.sent();
                            if (departmentsResponse.length) {
                                //En caso que no se quiera elegir departament
                                this.departments = __spreadArrays([
                                    {
                                        id: -1,
                                        name: "Sin departamento",
                                        location_type_id: 0,
                                        stablishment_id: 0,
                                    }
                                ], departmentsResponse);
                            }
                            this.department_id = 0;
                            this.unities = [];
                            this.loadingDepartment = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
        loadUnities: function () {
            return __awaiter(this, void 0, Promise, function () {
                var unitiesResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.loadingUnity = true;
                            this.unities = [];
                            return [4 /*yield*/, LocationService.getByParentLocationTypeStablishment(UNIDAD, this.stablishmentSelected.id, this.department_id)];
                        case 1:
                            unitiesResponse = _a.sent();
                            if (unitiesResponse.length) {
                                //En caso que no se quiera elegir unity
                                this.unities = __spreadArrays([
                                    {
                                        id: -1,
                                        name: "Sin unidad",
                                        location_type_id: 0,
                                        stablishment_id: 0,
                                    }
                                ], unitiesResponse);
                            }
                            this.unity_id = 0;
                            this.loadingUnity = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
        loadUsersLocations: function () {
            return __awaiter(this, void 0, void 0, function () {
                var response;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, UserService.getById(this.userProp.id)];
                        case 1:
                            response = _a.sent();
                            if (response.users_locations) {
                                this.usersLocations = response.users_locations;
                            }
                            return [2 /*return*/];
                    }
                });
            });
        } }),
    mounted: function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.loadPositions();
                this.loadDirections();
                if (this.userProp.users_locations) {
                    this.usersLocations = this.userProp.users_locations;
                }
                return [2 /*return*/];
            });
        });
    },
    watch: {
        stablishmentSelected: function () {
            this.loadDirections();
        },
    },
});
