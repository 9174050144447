import { __awaiter, __generator } from "tslib";
import { DocumentDetailChangesService } from "@/services/models/document/documentDetailChanges";
import Vue from "vue";
export default Vue.extend({
    props: {
        detailProp: {
            type: Object,
        },
        selectedPreviousTemplate: {
            type: Function,
        },
    },
    watch: {
        selectedItem: function () {
            this.selectedPreviousTemplate(this.selectedItem);
        },
    },
    data: function () {
        return {
            selectedItem: {},
            listChanges: [],
        };
    },
    mounted: function () {
        this.loadData();
    },
    methods: {
        compare: function (a, b) {
            if ((a.created_at || 0) < (b.created_at || 0)) {
                return 1;
            }
            if ((a.created_at || 0) > (b.created_at || 0)) {
                return -1;
            }
            return 0;
        },
        loadData: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _a = this;
                            return [4 /*yield*/, DocumentDetailChangesService.getByDocumentDetail(this.detailProp.id)];
                        case 1:
                            _a.listChanges = _b.sent();
                            this.listChanges = this.listChanges.sort(this.compare);
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
});
