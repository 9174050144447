import { __assign, __awaiter, __generator } from "tslib";
import { DocumentDetailService } from "@/services/models/document/documentDetail";
import { StablishmentService } from "@/services/models/stablishment";
import { PDFDocument } from "pdf-lib";
import { base64ArrayBuffer } from "@/others/toBase64";
import { callSha256, setQrCode } from "./functionsToFirm";
import Vue from "vue";
import { UserService } from "@/services/models/user/user";
import Otp from "./Otp.vue";
import SelectProfile from "./SelectProfile.vue";
import SuccessPage from "./SuccessPage.vue";
import Loader from "./Loader.vue";
import { DocumentDetailFirmedService } from "@/services/models/document/documentDetailFirmed";
import { HistoryService } from "@/services/models/document/history";
import moment from "moment";
import { DocumentStateService } from "@/services/models/document/documentState";
import { mapActions } from "vuex";
import RejectFirm from "./RejectFirm.vue";
import VueHtml2pdf from "vue-html2pdf";
/* eslint-disable */
// @ts-ignore
import html2pdf from "html2pdf.js";
/* eslint-enable */
export default Vue.extend({
    components: { Otp: Otp, SelectProfile: SelectProfile, SuccessPage: SuccessPage, Loader: Loader, RejectFirm: RejectFirm, VueHtml2pdf: VueHtml2pdf },
    props: {
        documentDetailId: {
            required: true,
            type: Number,
        },
        documentId: {
            required: true,
            type: Number,
        },
    },
    data: function () {
        return {
            invalidStep: 0,
            step: 1,
            otp: null,
            loading: false,
            userLocationSelected: {},
            progress: 0,
            errorMessage: "",
            loadingOverlay: false,
            contentTemplate: "",
        };
    },
    methods: __assign(__assign({}, mapActions({
        getDocumentsDetail: "documentDetail/getByDocumentId",
    })), { changeStep: function (step) {
            this.step = step;
        },
        validateStep: function (isValid, step) {
            if (!isValid) {
                this.invalidStep = step;
            }
        },
        dataURLtoFile: function (dataurl, filename) {
            var arr = dataurl.split(","), mime = arr[0].match(/:(.*?);/)[1], bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
        },
        addFirmToPdf: function (file) {
            var _a, _b, _c;
            return __awaiter(this, void 0, Promise, function () {
                var pngImageBytes, stablishmentId, stablishment, pdfDoc, pngImage, jpgUrlLine, jpgImageLineBytes, pngLine, jpgUrlLogo, jpgImageLogoBytes, pngLogo, jpgUrFondo, jpgImageFondoBytes, pngFondo, pngDims, pages, numberOfPages, lastPage, _d, width, height, user, name, apepat, apemat, pdfBytes;
                return __generator(this, function (_e) {
                    switch (_e.label) {
                        case 0: return [4 /*yield*/, ((_a = this.$refs.qr) === null || _a === void 0 ? void 0 : _a.children[1].currentSrc)];
                        case 1:
                            pngImageBytes = _e.sent();
                            stablishmentId = this.getCurrentUserLoggedIn().stablishment_id;
                            return [4 /*yield*/, StablishmentService.getById(stablishmentId)];
                        case 2:
                            stablishment = _e.sent();
                            return [4 /*yield*/, PDFDocument.load(file)];
                        case 3:
                            pdfDoc = _e.sent();
                            return [4 /*yield*/, pdfDoc.embedPng(pngImageBytes)];
                        case 4:
                            pngImage = _e.sent();
                            jpgUrlLine = '/linea-test.png';
                            return [4 /*yield*/, fetch(jpgUrlLine).then(function (res) { return res.arrayBuffer(); })];
                        case 5:
                            jpgImageLineBytes = _e.sent();
                            return [4 /*yield*/, pdfDoc.embedPng(jpgImageLineBytes)];
                        case 6:
                            pngLine = _e.sent();
                            jpgUrlLogo = '/logoRedondo.png';
                            return [4 /*yield*/, fetch(jpgUrlLogo).then(function (res) { return res.arrayBuffer(); })];
                        case 7:
                            jpgImageLogoBytes = _e.sent();
                            return [4 /*yield*/, pdfDoc.embedPng(jpgImageLogoBytes)];
                        case 8:
                            pngLogo = _e.sent();
                            jpgUrFondo = '/fondo2.png';
                            return [4 /*yield*/, fetch(jpgUrFondo).then(function (res) { return res.arrayBuffer(); })];
                        case 9:
                            jpgImageFondoBytes = _e.sent();
                            return [4 /*yield*/, pdfDoc.embedPng(jpgImageFondoBytes)];
                        case 10:
                            pngFondo = _e.sent();
                            pngDims = pngImage.scale(0.1);
                            pages = pdfDoc.getPages();
                            numberOfPages = pdfDoc.getPageCount();
                            lastPage = pages[numberOfPages - 1];
                            _d = lastPage.getSize(), width = _d.width, height = _d.height;
                            user = this.getCurrentUserLoggedIn();
                            name = user.name;
                            apepat = user.pather_lastname;
                            apemat = user.mother_lastname;
                            lastPage.drawImage(pngFondo, {
                                x: 0,
                                y: 0,
                                // width: width - width/8,
                                width: width,
                                height: height / 8
                            });
                            lastPage.drawText(stablishment.name, {
                                x: width / 7,
                                y: height / 14 - 21 - 15,
                                size: 9,
                            });
                            lastPage.drawText(((_b = this.userLocationSelected.position) === null || _b === void 0 ? void 0 : _b.name) + " " + ((_c = this.userLocationSelected.location) === null || _c === void 0 ? void 0 : _c.name), {
                                x: width / 7,
                                y: height / 14 - 6 - 15,
                                size: 9,
                            });
                            lastPage.drawText(name + " " + apepat + " " + apemat, {
                                x: width / 7,
                                y: height / 14 + 9 - 15,
                                size: 10,
                            });
                            lastPage.drawText("Este documento ha sido firmado electrónicamente por", {
                                x: width / 7,
                                y: height / 14 + 24 - 15,
                                size: 10,
                            });
                            lastPage.drawImage(pngLine, {
                                x: 0,
                                y: height / 14 + 39,
                                // width: width - width/8,
                                width: width,
                                height: 5,
                            });
                            lastPage.drawImage(pngImage, {
                                x: width / 30,
                                y: height / 14 + -20 - 15,
                                width: pngDims.width * 2,
                                height: pngDims.height * 2,
                            });
                            lastPage.drawImage(pngLogo, {
                                x: width - width / 7,
                                y: height / 14 + -20 - 15,
                                width: pngDims.width * 2,
                                height: pngDims.height * 2,
                            });
                            return [4 /*yield*/, pdfDoc.saveAsBase64({ dataUri: false })];
                        case 11:
                            pdfBytes = _e.sent();
                            return [2 /*return*/, pdfBytes];
                    }
                });
            });
        },
        generateQr: function () {
            return __awaiter(this, void 0, Promise, function () {
                var user, qrContent;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, UserService.getById(this.getCurrentUserLoggedIn().id)];
                        case 1:
                            user = _a.sent();
                            qrContent = "Firmado por Servicio de Salud Magallanes, " + user.name + " " + user.pather_lastname + " " + user.mother_lastname + " rut: " + user.run;
                            /* eslint-disable */
                            setQrCode(this.$refs.qr, qrContent);
                            return [2 /*return*/];
                    }
                });
            });
        },
        prepareData: function () {
            return __awaiter(this, void 0, Promise, function () {
                var documentDetail, base64File, downloadedFile, blob, arrayBufferFile, pdfContent, options, result, base64WithFirm, token, checksum;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, DocumentDetailService.getById(this.documentDetailId)];
                        case 1:
                            documentDetail = _a.sent();
                            this.progress = 20;
                            base64File = "";
                            if (!documentDetail.file) return [3 /*break*/, 4];
                            return [4 /*yield*/, DocumentDetailService.downloadFile(documentDetail.id)];
                        case 2:
                            downloadedFile = _a.sent();
                            blob = new Blob([downloadedFile.data], {
                                type: "application/pdf",
                            });
                            return [4 /*yield*/, blob.arrayBuffer()];
                        case 3:
                            arrayBufferFile = _a.sent();
                            base64File = base64ArrayBuffer(arrayBufferFile);
                            return [3 /*break*/, 6];
                        case 4:
                            if (documentDetail.file_generated) {
                                this.contentTemplate = documentDetail.file_generated;
                            }
                            pdfContent = this.$refs.html2Pdf.$refs.pdfContent;
                            options = this.$refs.html2Pdf.setOptions();
                            return [4 /*yield*/, html2pdf()
                                    .set(options)
                                    .from(pdfContent)
                                    .outputPdf()];
                        case 5:
                            result = _a.sent();
                            base64File = btoa(result);
                            _a.label = 6;
                        case 6:
                            console.log(base64File);
                            return [4 /*yield*/, this.addFirmToPdf(base64File)];
                        case 7:
                            base64WithFirm = _a.sent();
                            this.progress = 30;
                            return [4 /*yield*/, UserService.getTokenKey(this.getCurrentUserLoggedIn().run.split("-")[0])];
                        case 8:
                            token = (_a.sent()).data;
                            this.progress = 40;
                            checksum = callSha256(Uint8Array.from(atob(base64WithFirm), function (c) { return c.charCodeAt(0); }));
                            /* eslint-enable */
                            return [2 /*return*/, {
                                    base64WithFirm: base64WithFirm,
                                    token: token,
                                    checksum: checksum,
                                }];
                    }
                });
            });
        },
        firmFile: function (token, base64, checksum, otp) {
            return __awaiter(this, void 0, void 0, function () {
                var resp, isValid, fileResponse, errorMessage;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, DocumentDetailService.getFileFirmed({
                                token: token,
                                content: base64,
                                checksum: checksum,
                                otp: otp,
                            })];
                        case 1:
                            resp = (_a.sent()).data;
                            isValid = false;
                            if (resp.files) {
                                // console.log(resp.files[0].status);
                                if (resp.files[0].status == "OK") {
                                    isValid = true;
                                }
                            }
                            if (isValid) {
                                fileResponse = resp.files[0].content;
                            }
                            else {
                                errorMessage = void 0;
                                if (resp.files) {
                                    errorMessage = resp.files[0].status ? resp.files[0].status : "";
                                }
                                else {
                                    errorMessage = resp.error ? " - " + resp.error : "";
                                }
                                this.errorMessage = errorMessage;
                                throw new Error("Falla del API " + errorMessage);
                            }
                            return [2 /*return*/, fileResponse];
                    }
                });
            });
        },
        updateDocumentDetailFirmed: function () {
            return __awaiter(this, void 0, void 0, function () {
                var documentDetailsFirmed, documentDetailFirmed, error_1;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 4, , 5]);
                            return [4 /*yield*/, DocumentDetailFirmedService.getByDocumentDetailId(this.documentDetailId)];
                        case 1:
                            documentDetailsFirmed = _a.sent();
                            documentDetailFirmed = documentDetailsFirmed.find(function (value) { return value.user_id == _this.getCurrentUserLoggedIn().id; });
                            if (!documentDetailFirmed) return [3 /*break*/, 3];
                            return [4 /*yield*/, DocumentDetailFirmedService.update(documentDetailFirmed.id, __assign(__assign({}, documentDetailFirmed), { date_firmed: moment().format("YYYY-MM-DD HH:mm"), state: "firmado" }))];
                        case 2:
                            _a.sent();
                            _a.label = 3;
                        case 3: return [3 /*break*/, 5];
                        case 4:
                            error_1 = _a.sent();
                            throw new Error(error_1);
                        case 5: return [2 /*return*/];
                    }
                });
            });
        },
        updateDocumentState: function () {
            return __awaiter(this, void 0, void 0, function () {
                var documentStates, error_2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 4, , 5]);
                            return [4 /*yield*/, DocumentStateService.getByDocumentIdUserId(this.documentId, this.getCurrentUserLoggedIn().id)];
                        case 1:
                            documentStates = _a.sent();
                            if (!documentStates.length) return [3 /*break*/, 3];
                            return [4 /*yield*/, DocumentStateService.update(documentStates[0].id, __assign(__assign({}, documentStates[0]), { state: "firmado" }))];
                        case 2:
                            _a.sent();
                            _a.label = 3;
                        case 3: return [3 /*break*/, 5];
                        case 4:
                            error_2 = _a.sent();
                            throw new Error(error_2);
                        case 5: return [2 /*return*/];
                    }
                });
            });
        },
        generateFirmedPdf: function (otp) {
            return __awaiter(this, void 0, void 0, function () {
                var _a, base64WithFirm, token, checksum, file, error_3;
                var _this = this;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 8, , 9]);
                            this.errorMessage = "";
                            this.loading = true;
                            this.progress = 0;
                            return [4 /*yield*/, this.generateQr()];
                        case 1:
                            _b.sent();
                            this.progress = 10;
                            return [4 /*yield*/, this.prepareData()];
                        case 2:
                            _a = _b.sent(), base64WithFirm = _a.base64WithFirm, token = _a.token, checksum = _a.checksum;
                            if (!token || !otp) {
                                return [2 /*return*/];
                            }
                            return [4 /*yield*/, this.firmFile(token, base64WithFirm, checksum, otp)];
                        case 3:
                            file = _b.sent();
                            return [4 /*yield*/, DocumentDetailService.updateImage({
                                    base64: file,
                                    documentDetailId: this.documentDetailId,
                                })];
                        case 4:
                            _b.sent();
                            this.progress = 70;
                            return [4 /*yield*/, this.updateDocumentDetailFirmed()];
                        case 5:
                            _b.sent();
                            this.progress = 80;
                            return [4 /*yield*/, this.updateDocumentState()];
                        case 6:
                            _b.sent();
                            this.progress = 90;
                            return [4 /*yield*/, HistoryService.create({
                                    user_origin_id: this.getCurrentUserLoggedIn().id,
                                    document_detail_id: this.documentDetailId,
                                    state: "firmado",
                                    location_origin_id: this.getUserLocation().location_id,
                                    user_destination_id: this.getCurrentUserLoggedIn().id,
                                    location_destination_id: this.getUserLocation().location_id,
                                })];
                        case 7:
                            _b.sent();
                            this.progress = 100;
                            this.step = 3;
                            //Esto es por que no podía recargar el documentDetail sin que desapareciera el modal y no se alcanzaba a mostrar la pantalla de éxito
                            setTimeout(function () {
                                _this.getDocumentsDetail(_this.documentId);
                            }, 2000);
                            return [3 /*break*/, 9];
                        case 8:
                            error_3 = _b.sent();
                            console.log(error_3);
                            this.loading = false;
                            this.displayToast({
                                type: "error",
                                message: "Lo sentimos, ha ocurrido un error. Favor volver a intentar",
                            });
                            return [3 /*break*/, 9];
                        case 9: return [2 /*return*/];
                    }
                });
            });
        } }),
});
