import Vue from "vue";
import AddFileToTicket from "./AddFileToTicket.vue";
export default Vue.extend({
    name: "ButtonAddFileToTicket",
    components: { AddFileToTicket: AddFileToTicket },
    props: {
        ticketId: {
            type: Number,
            required: true
        },
        loadTickets: {
            type: Function,
            required: true
        }
    },
    data: function () {
        return {
            dialog: false,
        };
    }
});
