import { __awaiter, __generator } from "tslib";
import { CdpService } from "@/services/models/purchaseRequest/cdp";
import Vue from "vue";
import ButtonEditCdp from "./ButtonEditCdp.vue";
export default Vue.extend({
    components: { ButtonEditCdp: ButtonEditCdp },
    props: {
        purchaseBuyerProps: {
            type: Object,
        },
    },
    data: function () {
        return {
            getLoading: false,
            getItems: [],
            headers: [
                {
                    text: "Id",
                    sortable: false,
                    value: "id",
                },
                {
                    text: "Id Interno",
                    sortable: false,
                    value: "number_id",
                },
                {
                    text: "Encabezado",
                    sortable: false,
                    value: "header.name",
                },
                {
                    text: "N° Solicitud",
                    sortable: false,
                    value: "n_request",
                },
                {
                    text: "Ver",
                    sortable: false,
                    value: "file",
                },
            ],
        };
    },
    methods: {
        loadData: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            this.getLoading = true;
                            _a = this;
                            return [4 /*yield*/, CdpService.getPurchaseBuyerId(this.purchaseBuyerProps.id)];
                        case 1:
                            _a.getItems = _b.sent();
                            this.getLoading = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
    mounted: function () {
        this.loadData();
    },
});
