import { __extends } from "tslib";
import { BaseService } from './../../base';
var PurchaseMethodService = /** @class */ (function (_super) {
    __extends(PurchaseMethodService, _super);
    function PurchaseMethodService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(PurchaseMethodService, "entity", {
        get: function () {
            return 'purchase_method';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PurchaseMethodService, "responseName", {
        get: function () {
            return 'purchase_method';
        },
        enumerable: false,
        configurable: true
    });
    return PurchaseMethodService;
}(BaseService()));
export { PurchaseMethodService };
