import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import '@babel/polyfill';
import './thirdParty';
import mixins from "./mixins/index";
Vue.config.productionTip = false;
Vue.mixin(mixins);
var appVersion = process.env.VUE_APP_GLOBAL_VERSION;
var browserVersion = window.localStorage.getItem("appVersion");
if (appVersion !== browserVersion) {
    window.localStorage.setItem("appVersion", appVersion);
    window.location.reload();
}
//Ingresar la locación actual del usuario
var user = JSON.parse(window.localStorage.getItem("user") || "{}");
if (user) {
    if (user.users_locations) {
        store.commit("userSession/setCurrentLocation", user.users_locations[0]);
    }
    store.commit("userSession/setUserModules");
    store.commit("userSession/setUserPermissions");
}
export var eventBus = new Vue();
new Vue({
    router: router,
    store: store,
    vuetify: vuetify,
    render: function (h) { return h(App); }
}).$mount('#app');
