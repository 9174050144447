import { render, staticRenderFns } from "./CardDataDocument.vue?vue&type=template&id=282ab162&"
import script from "./CardDataDocument.vue?vue&type=script&lang=ts&"
export * from "./CardDataDocument.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports