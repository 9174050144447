import { __extends } from "tslib";
import { BaseService } from './../../base';
var ProjectService = /** @class */ (function (_super) {
    __extends(ProjectService, _super);
    function ProjectService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ProjectService, "entity", {
        get: function () {
            return 'project';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProjectService, "responseName", {
        get: function () {
            return 'project';
        },
        enumerable: false,
        configurable: true
    });
    return ProjectService;
}(BaseService()));
export { ProjectService };
