import { __awaiter, __extends, __generator } from "tslib";
import { BaseService } from './../../base';
var TicketTypeService = /** @class */ (function (_super) {
    __extends(TicketTypeService, _super);
    function TicketTypeService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(TicketTypeService, "entity", {
        get: function () {
            return 'ticket_type';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TicketTypeService, "responseName", {
        get: function () {
            return 'ticket_type';
        },
        enumerable: false,
        configurable: true
    });
    TicketTypeService.getByGroupId = function (id) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_group_id/" + id
                    })];
            });
        });
    };
    return TicketTypeService;
}(BaseService()));
export { TicketTypeService };
