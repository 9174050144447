import { __awaiter, __generator } from "tslib";
import { PurchaseBuyerService } from "@/services/models/purchaseRequest/purchaseBuyer";
var modulePurchaseBuyer = {
    namespaced: true,
    state: {
        purchaseBuyer: [],
        purchaseBuyerPending: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        purchaseBuyerReady: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        loading: false,
    },
    getters: {
        getPurchaseBuyer: function (state) {
            return state.purchaseBuyer;
        },
        getPurchaseBuyerPending: function (state) {
            return state.purchaseBuyerPending;
        },
        getPurchaseBuyerReady: function (state) {
            return state.purchaseBuyerReady;
        },
        getLoading: function (state) {
            return state.loading;
        }
    },
    mutations: {
        setPurchaseBuyer: function (state, purchaseBuyer) {
            state.purchaseBuyer = purchaseBuyer;
        },
        setPurchaseBuyerPending: function (state, purchaseBuyerPending) {
            state.purchaseBuyerPending = purchaseBuyerPending;
        },
        setPurchaseBuyerReady: function (state, purchaseBuyerReady) {
            state.purchaseBuyerReady = purchaseBuyerReady;
        },
        //Paginación
        setPagePurchaseBuyerPending: function (state, page) {
            state.purchaseBuyerPending.current_page = page;
        },
        setPagePurchaseBuyerReady: function (state, page) {
            state.purchaseBuyerReady.current_page = page;
        },
        setLoading: function (state, loading) {
            state.loading = loading;
        }
    },
    actions: {
        getPurchaseBuyer: function (_a, id) {
            var commit = _a.commit;
            return __awaiter(this, void 0, Promise, function () {
                var PURCHASEBUYER;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, PurchaseBuyerService.getByPurchaseId(id)];
                        case 1:
                            PURCHASEBUYER = _b.sent();
                            commit("setPurchaseBuyer", PURCHASEBUYER);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        getPurchaseBuyerPending: function (_a, payload) {
            var commit = _a.commit, state = _a.state;
            return __awaiter(this, void 0, Promise, function () {
                var PURCHASEBUYER;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, PurchaseBuyerService.getByPurchaseBuyerState(payload, state.purchaseBuyerPending.current_page)];
                        case 1:
                            PURCHASEBUYER = _b.sent();
                            commit('setPurchaseBuyerPending', PURCHASEBUYER);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        getPurchaseBuyerReady: function (_a, payload) {
            var commit = _a.commit, state = _a.state;
            return __awaiter(this, void 0, Promise, function () {
                var PURCHASEBUYER;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, PurchaseBuyerService.getByPurchaseBuyerState(payload, state.purchaseBuyerReady.current_page)];
                        case 1:
                            PURCHASEBUYER = _b.sent();
                            commit('setPurchaseBuyerReady', PURCHASEBUYER);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        },
    }
};
export default modulePurchaseBuyer;
