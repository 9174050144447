import { __assign, __awaiter, __generator } from "tslib";
import Vue from "vue";
import { DocumentTypeService } from "@/services/models/document/document_type";
import { DocumentService } from "@/services/models/document/document";
import { DocumentStateService } from "@/services/models/document/documentState";
import { RemitentService } from '@/services/models/document/remitent';
import { mapActions } from "vuex";
import { DocumentStateLocationService } from "@/services/models/document/documentStateLocation";
export default Vue.extend({
    data: function () {
        return {
            dialog: false,
            listDocumentType: [],
            listRemitents: [],
            isLoading: false,
            document: {},
        };
    },
    methods: __assign(__assign({}, mapActions({
        setDocument: "documents/getMyDocument",
    })), { clearData: function () {
            this.cleanForm(this, "formCreate");
        },
        loadData: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            this.isLoading = true;
                            _a = this;
                            return [4 /*yield*/, DocumentTypeService.getAll()];
                        case 1:
                            _a.listDocumentType = _c.sent();
                            _b = this;
                            return [4 /*yield*/, RemitentService.getAll()];
                        case 2:
                            _b.listRemitents = _c.sent();
                            this.isLoading = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
        saveDocument: function () {
            var _a;
            return __awaiter(this, void 0, void 0, function () {
                var data, response, error_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (!this.$refs.formCreate.validate()) {
                                this.displayToast({
                                    type: "error",
                                    message: "Favor de llenar todos los campos requeridos",
                                });
                                return [2 /*return*/];
                            }
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, 6, 7, 8]);
                            this.isLoading = true;
                            data = __assign(__assign({}, this.document), { user_id: this.getCurrentUserLoggedIn().id, location_id: ((_a = this.getCurrentUserLoggedIn().users_locations) === null || _a === void 0 ? void 0 : _a[0].location_id) || 0 });
                            return [4 /*yield*/, DocumentService.create(data)];
                        case 2:
                            response = _b.sent();
                            if (!(this.getProfile() == "opartes")) return [3 /*break*/, 4];
                            return [4 /*yield*/, DocumentStateLocationService.create({
                                    id: 0,
                                    location_id: this.getUserLocation().id,
                                    document_id: response.id,
                                    state: 'creado',
                                    state_read: 'leido'
                                })];
                        case 3:
                            _b.sent();
                            _b.label = 4;
                        case 4: return [4 /*yield*/, DocumentStateService.create({
                                id: 0,
                                user_id: this.getCurrentUserLoggedIn().id,
                                document_id: response.id,
                                state: 'creado',
                                state_read: 'leido'
                            })];
                        case 5:
                            _b.sent();
                            this.setDocument({ id: this.getCurrentUserLoggedIn().id });
                            this.dialog = false;
                            return [3 /*break*/, 8];
                        case 6:
                            error_1 = _b.sent();
                            this.displayToast({
                                type: 'error',
                                message: 'Los sentimos, ha ocurrido un error'
                            });
                            console.log(error_1);
                            return [3 /*break*/, 8];
                        case 7:
                            this.isLoading = false;
                            return [7 /*endfinally*/];
                        case 8: return [2 /*return*/];
                    }
                });
            });
        } }),
    watch: {
        dialog: function () {
            if (this.dialog) {
                this.clearData();
                this.loadData();
            }
        },
    },
});
