import { __assign, __awaiter, __generator } from "tslib";
import Vue from "vue";
import InputSelectUsers from "@/components/document/ShowDocument/panelDetail/InputSelectUsers.vue";
import { mapActions } from "vuex";
import { PurchaseBuyerHistoryService } from "@/services/models/purchaseRequest/purchaseBuyerHistory";
import { PurchaseBuyerStateService } from "@/services/models/purchaseRequest/purchaseBuyerState";
import { PurchaseHistoryService } from "@/services/models/purchaseRequest/purchaseHistory";
export default Vue.extend({
    components: {
        InputSelectUsers: InputSelectUsers,
    },
    props: {
        purchaseBuyerProps: {
            type: Object,
        },
        dataSend: {
            type: Object
        },
        label: {
            type: String,
            default: 'Enviar Registro'
        }
    },
    data: function () {
        return {
            dialog: false,
        };
    },
    methods: __assign(__assign({}, mapActions({
        setPending: "purchaseBuyer/getPurchaseBuyerPending",
        setReady: "purchaseBuyer/getPurchaseBuyerReady",
    })), { loadData: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.setPending({
                        userId: this.getCurrentUserLoggedIn().id,
                        state: "pendiente",
                        step: this.dataSend.currentStep,
                    });
                    this.setReady({
                        userId: this.getCurrentUserLoggedIn().id,
                        state: "finalizado",
                        step: this.dataSend.currentStep,
                    });
                    return [2 /*return*/];
                });
            });
        },
        save: function () {
            return __awaiter(this, void 0, void 0, function () {
                var userSelected, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 4, 5, 6]);
                            this.displayLoader(true);
                            userSelected = this.$refs.selectUsers.usersSelects[0];
                            return [4 /*yield*/, this.postPurchaseBuyerHistory(userSelected)];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, this.postPurchaseBuyerState(userSelected)];
                        case 2:
                            _a.sent();
                            return [4 /*yield*/, this.postPurchaseHistory(this.purchaseBuyerProps.purchase_request, userSelected)];
                        case 3:
                            _a.sent();
                            this.displayToast({
                                type: "success",
                                message: "Registro enviado",
                            });
                            this.loadData();
                            this.dialog = false;
                            return [3 /*break*/, 6];
                        case 4:
                            error_1 = _a.sent();
                            console.log(error_1);
                            return [3 /*break*/, 6];
                        case 5:
                            this.displayLoader(false);
                            return [7 /*endfinally*/];
                        case 6: return [2 /*return*/];
                    }
                });
            });
        },
        postPurchaseBuyerHistory: function (user) {
            return __awaiter(this, void 0, void 0, function () {
                var dataBuyerHistory;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            dataBuyerHistory = {
                                id: 0,
                                state: "registro enviado",
                                user_origin_id: this.getCurrentUserLoggedIn().id,
                                user_destination_id: user.id,
                                purchase_buyer_id: this.purchaseBuyerProps.id,
                            };
                            return [4 /*yield*/, PurchaseBuyerHistoryService.create(dataBuyerHistory)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        postPurchaseBuyerState: function (user) {
            var _a;
            return __awaiter(this, void 0, void 0, function () {
                var currentState, dataPurchaseBuyerStatePut, sendState, dataPurchaseBuyerStatePost, dataPurchaseBuyerStatePut_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, PurchaseBuyerStateService.getByPurchaseBuyerIdUserId(this.purchaseBuyerProps.id, this.getCurrentUserLoggedIn().id)];
                        case 1:
                            currentState = _b.sent();
                            dataPurchaseBuyerStatePut = __assign(__assign({}, currentState[0]), { state: "enviado", step: this.dataSend.currentStep });
                            return [4 /*yield*/, PurchaseBuyerStateService.update(currentState[0].id, dataPurchaseBuyerStatePut)];
                        case 2:
                            _b.sent();
                            return [4 /*yield*/, PurchaseBuyerStateService.getByPurchaseBuyerIdUserId(this.purchaseBuyerProps.id, user.id)];
                        case 3:
                            sendState = _b.sent();
                            if (!(sendState.length == 0)) return [3 /*break*/, 5];
                            dataPurchaseBuyerStatePost = {
                                id: 0,
                                purchase_buyer_id: this.purchaseBuyerProps.id,
                                state: "pendiente",
                                step: this.dataSend.toStep,
                                user_id: user.id,
                                user_location_id: ((_a = user.users_locations) === null || _a === void 0 ? void 0 : _a[0].location_id) || 0,
                            };
                            return [4 /*yield*/, PurchaseBuyerStateService.create(dataPurchaseBuyerStatePost)];
                        case 4:
                            _b.sent();
                            return [3 /*break*/, 7];
                        case 5:
                            dataPurchaseBuyerStatePut_1 = __assign(__assign({}, sendState[0]), { step: this.dataSend.toStep, state: "pendiente" });
                            return [4 /*yield*/, PurchaseBuyerStateService.update(sendState[0].id, dataPurchaseBuyerStatePut_1)];
                        case 6:
                            _b.sent();
                            _b.label = 7;
                        case 7: return [2 /*return*/];
                    }
                });
            });
        },
        postPurchaseHistory: function (purchaseRequest, user) {
            return __awaiter(this, void 0, void 0, function () {
                var dataPurchaseHistory;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            dataPurchaseHistory = {
                                id: 0,
                                purchase_request_id: purchaseRequest.id,
                                state: "Registro enviado a presupuesto",
                                user_origin_id: this.getCurrentUserLoggedIn().id,
                                user_destination_id: user.id,
                            };
                            return [4 /*yield*/, PurchaseHistoryService.create(dataPurchaseHistory)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        } }),
});
