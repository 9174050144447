import { __assign, __awaiter, __generator } from "tslib";
import Vue from "vue";
import { mapActions } from "vuex";
import { DocumentStateService } from "@/services/models/document/documentState";
export default Vue.extend({
    props: {
        folder: {
            required: true,
            type: String,
        },
        document: {
            required: true,
            type: Object,
        },
    },
    data: function () {
        return {
            isLoading: false,
            dialog: false,
        };
    },
    methods: __assign(__assign({}, mapActions({
        getFolderDocuments: "documents/getFolderDocument",
    })), { invalidDetail: function () {
            var _a;
            return __awaiter(this, void 0, void 0, function () {
                var documentState, error_1;
                var _this = this;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 3, 4, 5]);
                            this.isLoading = true;
                            documentState = (_a = this.document.documents_states) === null || _a === void 0 ? void 0 : _a.find(function (state) { return state.user_id == _this.getCurrentUserLoggedIn().id; });
                            if (!documentState) return [3 /*break*/, 2];
                            return [4 /*yield*/, DocumentStateService.update(documentState.id, __assign(__assign({}, documentState), { folder_id: null }))];
                        case 1:
                            _b.sent();
                            _b.label = 2;
                        case 2:
                            this.displayToast({
                                type: "success",
                                message: "La operación se ha realizado con éxito",
                            });
                            this.getFolderDocuments({
                                user_id: this.getCurrentUserLoggedIn().id,
                                folder_id: this.$route.params.id,
                            });
                            return [3 /*break*/, 5];
                        case 3:
                            error_1 = _b.sent();
                            console.log(error_1);
                            this.displayToast({
                                type: "error",
                                message: "Lo sentimos, ha ocurrido un error al realizar la acción",
                            });
                            return [3 /*break*/, 5];
                        case 4:
                            this.isLoading = false;
                            this.dialog = false;
                            return [7 /*endfinally*/];
                        case 5: return [2 /*return*/];
                    }
                });
            });
        } }),
    watch: {
        dialog: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (this.dialog) {
                        this.cleanForm(this, "formInvalid");
                    }
                    return [2 /*return*/];
                });
            });
        },
    },
});
