import { __assign } from "tslib";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import ButtonCreatePurchaseRequest from "../createPurchaseRequest/ButtonCreatePurchaseRequest.vue";
import GroupButtons from "./buttonsPurchaseRequest/GroupButtons.vue";
import DetailTablePurchaseRequest from "./DetailTablePurchaseRequest.vue";
export default Vue.extend({
    components: {
        ButtonCreatePurchaseRequest: ButtonCreatePurchaseRequest,
        DetailTablePurchaseRequest: DetailTablePurchaseRequest,
        GroupButtons: GroupButtons,
    },
    data: function () {
        return {
            page: 1,
            headers: [
                {
                    text: "Id",
                    sortable: false,
                    value: "id",
                },
                {
                    text: "Estado",
                    sortable: false,
                    value: "state",
                },
                {
                    text: "Creación",
                    sortable: false,
                    value: "created_at",
                },
                {
                    text: "Modificación",
                    sortable: false,
                    value: "updated_at",
                },
                {
                    text: "Acciones",
                    sortable: false,
                    align: "center",
                    value: "actions",
                },
            ],
        };
    },
    computed: __assign({}, mapGetters({
        getPurchaseRequest: "purchaseRequest/getPurchaseRequest",
        getLoading: "purchaseRequest/getLoading",
    })),
    methods: __assign(__assign({}, mapActions({
        setPurchaseRequest: "purchaseRequest/getPurchaseRequest",
    })), { color: function (state) {
            if (state == "creado")
                return "success";
            else
                return "warning";
        },
        changePage: function () {
            this.$store.commit("purchaseRequest/setPagePurchaseRequest", this.page);
            this.setPurchaseRequest(this.getCurrentUserLoggedIn().id);
        } }),
    mounted: function () {
        this.setPurchaseRequest(this.getCurrentUserLoggedIn().id);
    },
});
