import { __spreadArrays } from "tslib";
var moduleUserSession = {
    namespaced: true,
    state: {
        profile: window.localStorage.getItem("perfil"),
        currentLocation: {
            id: 0,
            location_type_id: 0,
            name: "Sin locación asignada",
            stablishment_id: 0,
        },
        modules: [],
        permissions: [],
    },
    getters: {
        getProfile: function (state) {
            return state.profile;
        },
        getCurrentLocation: function (state) {
            return state.currentLocation;
        },
        getModules: function (state) {
            return state.modules.map(function (module) { return module.module_id; });
        },
        getPermissions: function (state) {
            return state.permissions.map(function (permission) { return permission.permission_id; });
        }
    },
    mutations: {
        setProfile: function (state, profile) {
            state.profile = profile;
        },
        setCurrentLocation: function (state, location) {
            state.currentLocation = location;
        },
        setUserModules: function (state) {
            var modules = [];
            var user = JSON.parse(window.localStorage.getItem("user") || "{}");
            var userModules = user.users_modules;
            console.log(user);
            if (userModules) {
                modules = userModules;
            }
            state.modules = modules;
        },
        setUserPermissions: function (state) {
            var permissions = [];
            var modules = state.modules;
            modules.forEach(function (module) {
                var userModulePermissions = [];
                if (module.users_modules_permissions) {
                    userModulePermissions = module.users_modules_permissions;
                }
                permissions = __spreadArrays(permissions, userModulePermissions);
            });
            state.permissions = permissions;
        }
    }
};
export default moduleUserSession;
