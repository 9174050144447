import { __extends } from "tslib";
/**
 * Return message for HTTP status code
 * @param {number} status - HTTP status code
 * @returns {string} Message of network operation
 */
function _getStatusMessage(status) {
    var message = '';
    switch (status) {
        case 200:
            message = 'All done. Request successfully executed';
            break;
        case 201:
            message = 'Data successfully created';
            break;
        case 400:
            message = 'Bad Request';
            break;
        case 401:
            message = 'Need auth';
            break;
        case 404:
            message = 'Not found';
            break;
        case 503:
            message = 'Service unavailable. Try again later';
            break;
        default:
            message = 'Something wrong. Client default error message';
            break;
    }
    return message;
}
//Tal vez puede ser útil
// function _getResponseErrorMessage (error) {
//   console.log(error);
//   if (error.response && error.response.data) return error.response.data.message
//   if (error.response && error.response.statusText) return error.response.statusText
//   return error.message === 'Network Error' ? 'Oops! Network Error. Try again later' : error.message
// }
/**
 * Create instant, which represent response object
 * @param {Object} [data] - custom data
 * @param {Object} [response] - axios response object
 * @param {String} [message] - custom message to display
 */
// export class ResponseWrapper {
// 	data: {}
// 	response: any
// 	statusMessage: string
// 	message: string | null
// 	status: string | number
// 	constructor(response: any, data = {}, message: string) {
// 		console.log(response, data);
// 		this.data = data
// 		this.response = response
// 		this.statusMessage = _getStatusMessage(this.status)
// 		this.message = message || null
// 	}
// }
/**
 * Create instant, which represent error object
 * @param {Object} [error] - axios error object
 * @param {String} [message] - custom message to display
 */
var ErrorWrapper = /** @class */ (function (_super) {
    __extends(ErrorWrapper, _super);
    function ErrorWrapper(error, message) {
        var _this = _super.call(this) || this;
        console.log('TOAST ERROR');
        console.log(error);
        // this.success = error.response ? error.response.data.success : false
        // this.meta = error.response ? error.response.data.meta : false
        // this.code = error.response ? error.response.data.code : false
        _this.status = error ? error.status : 0;
        _this.statusMessage = _this.status ? _getStatusMessage(_this.status) : "No message";
        // this.message = message || _getResponseErrorMessage(error)
        _this.message = message || error.data ? error.data.message : '';
        return _this;
    }
    return ErrorWrapper;
}(Error));
export { ErrorWrapper };
