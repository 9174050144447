var moduleLoader = {
    namespaced: true,
    state: {
        showLoader: false,
    },
    getters: {
        getShowLoader: function (state) {
            return state.showLoader;
        }
    },
    mutations: {
        setLoader: function (state, payLoad) {
            state.showLoader = payLoad;
        },
    },
    actions: {
        getState: function (_a, state) {
            var commit = _a.commit;
            commit('setLoader', state);
        }
    }
};
export default moduleLoader;
