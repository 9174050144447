import Vue from "vue";
import MenuAddDocument from "./MenuAddDocument.vue";
export default Vue.extend({
    name: "CardDataDocument",
    components: { MenuAddDocument: MenuAddDocument },
    props: {
        documentProp: {
            required: true,
            type: Object,
        },
    },
});
