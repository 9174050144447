import { __extends } from "tslib";
import { BaseService } from './../../base';
var RemitentService = /** @class */ (function (_super) {
    __extends(RemitentService, _super);
    function RemitentService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(RemitentService, "entity", {
        get: function () {
            return 'remitent';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RemitentService, "responseName", {
        get: function () {
            return 'remitent';
        },
        enumerable: false,
        configurable: true
    });
    return RemitentService;
}(BaseService()));
export { RemitentService };
