import { __awaiter, __generator } from "tslib";
import { TicketMessageService } from "@/services/models/ticket/ticketMessage";
var moduleTicketMessage = {
    namespaced: true,
    state: {
        message: [],
        loading: false,
    },
    getters: {
        getTicketMessage: function (state) {
            return state.message;
        },
        getLoading: function (state) {
            return state.loading;
        }
    },
    mutations: {
        setTicketMessage: function (state, message) {
            state.message = message;
        },
        setLoading: function (state, loading) {
            state.loading = loading;
        }
    },
    actions: {
        getTicketMessage: function (_a, id) {
            var commit = _a.commit;
            return __awaiter(this, void 0, Promise, function () {
                var TicketMessage;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, TicketMessageService.getByTicketId(id)];
                        case 1:
                            TicketMessage = _b.sent();
                            commit("setTicketMessage", TicketMessage);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        },
    }
};
export default moduleTicketMessage;
