import { __assign, __awaiter, __extends, __generator } from "tslib";
import { BaseService } from './../../base';
var CdpService = /** @class */ (function (_super) {
    __extends(CdpService, _super);
    function CdpService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(CdpService, "entity", {
        get: function () {
            return 'cdp';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CdpService, "responseName", {
        get: function () {
            return 'cdp';
        },
        enumerable: false,
        configurable: true
    });
    CdpService.getPurchaseBuyerId = function (purchaseBuyerId) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_purchase_buyer_id/" + purchaseBuyerId
                    })];
            });
        });
    };
    /**
     * ES solo para subir el cdp_file
     * @param dataToUpdate
     * @returns
     */
    CdpService.uploadImageCdpFile = function (dataToUpdate) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'put',
                        data: {
                            base64: "data:application/pdf;base64," + dataToUpdate.base64,
                            cdp_name: dataToUpdate.cdp_name
                        },
                        path: 'upload_image_base64/' + dataToUpdate.id,
                        showFullResponse: true
                    })];
            });
        });
    };
    /**
     * Para subir el cdp firmado
     * @param dataToUpdate
     * @returns
     */
    CdpService.updateImage = function (dataToUpdate) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'put',
                        data: {
                            base64: "data:application/pdf;base64," + dataToUpdate.base64,
                        },
                        path: 'update_image/' + dataToUpdate.id,
                        showFullResponse: true
                    })];
            });
        });
    };
    CdpService.downloadFile = function (fileId) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "download_image/" + fileId,
                        showFullResponse: true,
                        config: {
                            responseType: 'blob',
                            "Content-Type": "application/json"
                        }
                    })];
            });
        });
    };
    CdpService.getFileFirmed = function (dataToPost) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'put',
                        data: __assign(__assign({}, dataToPost), { api_token_key: "31602029-ffc8-4112-8bf7-16d028fe3635" }),
                        path: 'get_file_firmed',
                        showFullResponse: true
                    })];
            });
        });
    };
    CdpService.getByStateUser = function (payLoad, page) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_state_user_id/" + payLoad.state + "/" + payLoad.user + "?page=" + page
                    })];
            });
        });
    };
    return CdpService;
}(BaseService()));
export { CdpService };
