import { __extends } from "tslib";
import { BaseService } from './../../base';
var DocumentDetailInvalidService = /** @class */ (function (_super) {
    __extends(DocumentDetailInvalidService, _super);
    function DocumentDetailInvalidService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(DocumentDetailInvalidService, "entity", {
        get: function () {
            return 'document_detail_invalid';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DocumentDetailInvalidService, "responseName", {
        get: function () {
            return 'document_detail_invalid';
        },
        enumerable: false,
        configurable: true
    });
    return DocumentDetailInvalidService;
}(BaseService()));
export { DocumentDetailInvalidService };
