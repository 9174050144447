import { __assign, __awaiter, __generator } from "tslib";
import Vue from "vue";
import { HistoryService } from "@/services/models/document/history";
import { DocumentDetailService } from "@/services/models/document/documentDetail";
import { DocumentDetailInvalidService } from "@/services/models/document/documentDetailInvalid";
import { mapActions } from "vuex";
export default Vue.extend({
    props: {
        detailProp: {
            required: true,
            type: Object,
        },
        documentProp: {
            required: true,
            type: Object,
        },
    },
    data: function () {
        return {
            isLoading: false,
            dialog: false,
            description: "",
        };
    },
    methods: __assign(__assign({}, mapActions({
        getDocumentsDetail: "documentDetail/getByDocumentId",
    })), { invalidDetail: function () {
            return __awaiter(this, void 0, void 0, function () {
                var dataDocumentDetail, dataHistory, dataDocumentDetailInvalid, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!this.$refs.formInvalid.validate()) return [3 /*break*/, 7];
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 5, 6, 7]);
                            this.isLoading = true;
                            dataDocumentDetail = __assign(__assign({}, this.detailProp), { state: "invalido" });
                            dataHistory = {
                                document_detail_id: this.detailProp.id,
                                user_origin_id: this.getCurrentUserLoggedIn().id,
                                user_destination_id: this.getCurrentUserLoggedIn().id,
                                location_origin_id: this.getUserLocation().location_id,
                                location_destination_id: 1,
                                description: this.description,
                                state: "invalidado",
                            };
                            dataDocumentDetailInvalid = {
                                description: this.description,
                                user_id: this.getCurrentUserLoggedIn().id,
                                document_detail_id: this.detailProp.id,
                            };
                            return [4 /*yield*/, this.postHistory(dataHistory)];
                        case 2:
                            _a.sent();
                            return [4 /*yield*/, this.putDocumentDetail(dataDocumentDetail)];
                        case 3:
                            _a.sent();
                            return [4 /*yield*/, this.invalidDocumentDetail(dataDocumentDetailInvalid)];
                        case 4:
                            _a.sent();
                            this.getDocumentsDetail(this.documentProp.id);
                            this.dialog = false;
                            return [3 /*break*/, 7];
                        case 5:
                            error_1 = _a.sent();
                            console.log(error_1);
                            throw error_1;
                        case 6:
                            this.isLoading = false;
                            return [7 /*endfinally*/];
                        case 7: return [2 /*return*/];
                    }
                });
            });
        },
        postHistory: function (dataHistory) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, HistoryService.create(dataHistory)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        },
        putDocumentDetail: function (dataDocumentDetail) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, DocumentDetailService.update(this.detailProp.id, dataDocumentDetail)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        },
        invalidDocumentDetail: function (dataDocumentDetailInvalid) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, DocumentDetailInvalidService.create(dataDocumentDetailInvalid)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        } }),
    watch: {
        dialog: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (this.dialog) {
                        this.cleanForm(this, "formInvalid");
                    }
                    return [2 /*return*/];
                });
            });
        },
    },
});
