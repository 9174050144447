import { __awaiter, __generator, __spreadArrays } from "tslib";
import logoRedondoSSM from "@/other/base64Logo";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";
import { TicketService } from '@/services/models/ticket/ticket';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
export var generatePDFSingleTicket = function (reportData) { return __awaiter(void 0, void 0, Promise, function () {
    var pdfMake, pdfFonts_1, ticket, title, responsable, responsableClosing, lastResponsable, ticketTable, tableHistories, messages, tableMessages, closedTicket, closedTicketTable, docDefinition;
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    return __generator(this, function (_s) {
        switch (_s.label) {
            case 0: return [4 /*yield*/, import("pdfmake/build/pdfmake.js")];
            case 1:
                pdfMake = _s.sent();
                if (!(pdfMake.vfs == undefined)) return [3 /*break*/, 3];
                return [4 /*yield*/, import("pdfmake/build/vfs_fonts.js")];
            case 2:
                pdfFonts_1 = _s.sent();
                pdfMake.vfs = pdfFonts_1.pdfMake.vfs;
                _s.label = 3;
            case 3: return [4 /*yield*/, TicketService.getById(reportData.ticketId)];
            case 4:
                ticket = _s.sent();
                title = "Reporte ticket #" + ticket.id + " (" + moment().format("DD-MM-YYYY") + ")";
                responsable = [];
                if (ticket.state != "solicitado" && ((_a = ticket.tickets_responsables) === null || _a === void 0 ? void 0 : _a.length)) {
                    responsable = [
                        { text: 'Responsable apertura', style: 'tableHeader' },
                        { text: ((_b = ticket.tickets_responsables[0].user) === null || _b === void 0 ? void 0 : _b.name) + " " + ((_c = ticket.tickets_responsables[0].user) === null || _c === void 0 ? void 0 : _c.pather_lastname) + " " + ((_d = ticket.tickets_responsables[0].user) === null || _d === void 0 ? void 0 : _d.mother_lastname) + " (" + moment(ticket.tickets_responsables[0].created_at).format("DD-MM-YYYY") + ")" },
                    ];
                }
                else {
                    responsable = [
                        { text: 'Responsable apertura', style: 'tableHeader' },
                        { text: "Sin responsable" },
                    ];
                }
                responsableClosing = [];
                if ((ticket.state == "cerrado" || ticket.state == "cerrado permanentemente") && ((_e = ticket.tickets_responsables) === null || _e === void 0 ? void 0 : _e.length)) {
                    lastResponsable = ticket.tickets_responsables[ticket.tickets_responsables.length - 1];
                    responsableClosing = [
                        { text: 'Responsable cierre', style: 'tableHeader' },
                        { text: ((_f = lastResponsable.user) === null || _f === void 0 ? void 0 : _f.name) + " " + ((_g = lastResponsable.user) === null || _g === void 0 ? void 0 : _g.pather_lastname) + " " + ((_h = lastResponsable.user) === null || _h === void 0 ? void 0 : _h.mother_lastname) + " (" + moment(lastResponsable.created_at).format("DD-MM-YYYY") + ")" },
                    ];
                }
                else {
                    responsableClosing = [
                        { text: 'Responsable cierre', style: 'tableHeader' },
                        { text: "Sin responsable" },
                    ];
                }
                ticketTable = {
                    margin: [0, 10, 0, 0],
                    table: {
                        widths: ['*', '*'],
                        body: [
                            [
                                { text: "Detalle", style: 'title', colSpan: 2, alignment: 'center' },
                                {}
                            ],
                            [
                                { text: 'Título', style: 'tableHeader' },
                                { text: "" + ticket.title },
                            ],
                            [
                                { text: 'Descripción', style: 'tableHeader' },
                                { text: "" + ticket.subject },
                            ],
                            [
                                { text: 'Fecha de solicitud', style: 'tableHeader' },
                                { text: "" + moment(ticket.created_at).format("DD-MM-YYYY") },
                            ],
                            [
                                { text: 'Tipo de ticket', style: 'tableHeader' },
                                { text: "" + ((_j = ticket.ticket_type) === null || _j === void 0 ? void 0 : _j.name) },
                            ],
                            [
                                { text: 'Generado por', style: 'tableHeader' },
                                { text: ((_k = ticket.created_by) === null || _k === void 0 ? void 0 : _k.name) + " " + ((_l = ticket.created_by) === null || _l === void 0 ? void 0 : _l.pather_lastname) + " " + ((_m = ticket.created_by) === null || _m === void 0 ? void 0 : _m.mother_lastname) },
                            ],
                            [
                                { text: 'Estado', style: 'tableHeader' },
                                { text: "" + ticket.state },
                            ],
                            [
                                { text: 'Grupo', style: 'tableHeader' },
                                { text: "" + ((_o = ticket.group) === null || _o === void 0 ? void 0 : _o.name) },
                            ],
                            responsable,
                            responsableClosing,
                        ],
                    }
                };
                tableHistories = [];
                if (ticket.tickets_histories) {
                    ticket.tickets_histories.forEach(function (ticketHistory) {
                        var _a, _b, _c;
                        tableHistories.push([((_a = ticketHistory.user) === null || _a === void 0 ? void 0 : _a.name) + " " + ((_b = ticketHistory.user) === null || _b === void 0 ? void 0 : _b.pather_lastname) + " " + ((_c = ticketHistory.user) === null || _c === void 0 ? void 0 : _c.mother_lastname), moment(ticketHistory.created_at).format("DD-MM-YYYY HH:mm"), ticketHistory.state]);
                    });
                }
                messages = [];
                if (ticket.tickets_messages) {
                    ticket.tickets_messages.forEach(function (ticketMessage) {
                        var _a, _b, _c;
                        messages.push([((_a = ticketMessage.user) === null || _a === void 0 ? void 0 : _a.name) + " " + ((_b = ticketMessage.user) === null || _b === void 0 ? void 0 : _b.pather_lastname) + " " + ((_c = ticketMessage.user) === null || _c === void 0 ? void 0 : _c.mother_lastname), moment(ticketMessage.created_at).format("DD-MM-YYYY HH:mm"), ticketMessage.message]);
                    });
                }
                if (messages.length) {
                    tableMessages = {
                        margin: [0, 10, 0, 0],
                        table: {
                            widths: ['*', '*', '*'],
                            body: __spreadArrays([
                                [{ text: 'Mensajes', style: 'title', alignment: 'center', colSpan: 3 }, {}, {}],
                                [{ text: 'Responsable', style: 'subTitle', alignment: 'center' }, { text: 'Fecha', style: 'subTitle', alignment: 'center' }, { text: 'Mensaje', style: 'subTitle', alignment: 'center' }]
                            ], messages)
                        }
                    };
                }
                if (ticket.tickets_closed) {
                    closedTicket = ticket.tickets_closed[0];
                }
                if (closedTicket) {
                    closedTicketTable =
                        {
                            margin: [0, 10, 0, 0],
                            table: {
                                widths: ['*', '*'],
                                body: [
                                    [
                                        { text: "Detalle cierre del ticket", style: 'title', colSpan: 2, alignment: 'center' },
                                        {}
                                    ],
                                    [
                                        { text: 'Responsable', style: 'tableHeader' },
                                        { text: ((_p = closedTicket.user) === null || _p === void 0 ? void 0 : _p.name) + " " + ((_q = closedTicket.user) === null || _q === void 0 ? void 0 : _q.pather_lastname) + " " + ((_r = closedTicket.user) === null || _r === void 0 ? void 0 : _r.mother_lastname) },
                                    ],
                                    [
                                        { text: 'Fecha', style: 'tableHeader' },
                                        { text: "" + moment(closedTicket.created_at).format("DD-MM-YYYY HH:mm") },
                                    ],
                                    [
                                        { text: 'Observación', style: 'tableHeader' },
                                        { text: "" + closedTicket.observation },
                                    ],
                                ],
                            }
                        };
                }
                docDefinition = {
                    pageMargins: [40, 30, 40, 60],
                    content: [
                        {
                            columns: [
                                {
                                    image: logoRedondoSSM,
                                    width: 70,
                                },
                                [
                                    {
                                        text: title,
                                        style: 'titleHeader',
                                        margin: [0, 10, 40, 0]
                                    },
                                ],
                            ]
                        },
                        ticketTable,
                        closedTicketTable,
                        {
                            margin: [0, 10, 0, 0],
                            table: {
                                widths: ['*', '*', '*'],
                                body: __spreadArrays([
                                    [{ text: 'Acciones', style: 'title', alignment: 'center', colSpan: 3 }, {}, {}],
                                    [{ text: 'Responsable', style: 'subTitle', alignment: 'center' }, { text: 'Fecha', style: 'subTitle', alignment: 'center' }, { text: 'Acción', style: 'subTitle', alignment: 'center' }]
                                ], tableHistories)
                            }
                        },
                        tableMessages
                    ],
                    styles: {
                        title: {
                            bold: true,
                            fontSize: 15,
                            color: 'white',
                            fillColor: '#EE3A3A'
                        },
                        subTitle: {
                            bold: true,
                            fontSize: 14,
                            color: 'black',
                            fillColor: '#d9d9d9'
                        },
                        tableHeader: {
                            bold: true,
                            fontSize: 11,
                            color: 'black',
                            fillColor: '#e4e4e4'
                        },
                        tableExample: {
                            alignment: "justify",
                            margin: [0, -150, 0, 15],
                        },
                        toTheRight: {
                            alignment: "right",
                        },
                        header: {
                            fontSize: 17,
                            alignment: "left",
                            bold: true,
                        },
                        titleHeader: {
                            fontSize: 18,
                            bold: true,
                            alignment: 'center'
                        },
                        subtitleHeader: {
                            fontSize: 9,
                            alignment: 'center'
                        }
                    },
                };
                // pdfMake.createPdf(docDefinition).download(`Reporte ticket #${ticket.id}.pdf`);
                pdfMake.createPdf(docDefinition).open();
                return [2 /*return*/];
        }
    });
}); };
