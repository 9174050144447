import { __awaiter, __extends, __generator } from "tslib";
import { BaseService } from './../../base';
var UserGroupService = /** @class */ (function (_super) {
    __extends(UserGroupService, _super);
    function UserGroupService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(UserGroupService, "entity", {
        get: function () {
            return 'user_group';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserGroupService, "responseName", {
        get: function () {
            return 'user_group';
        },
        enumerable: false,
        configurable: true
    });
    UserGroupService.getByGroupId = function (group_id) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_group_id/" + group_id
                    })];
            });
        });
    };
    return UserGroupService;
}(BaseService()));
export { UserGroupService };
