import Vue from "vue";
import ButtonRefreshTable from "../ButtonRefreshTable.vue";
import TableByChecked from "./TableByChecked.vue";
import TableChecked from "./TableChecked.vue";
import TableNotRelevant from "./TableNotRelevant.vue";
export default Vue.extend({
    components: {
        TableByChecked: TableByChecked,
        TableChecked: TableChecked,
        TableNotRelevant: TableNotRelevant,
        ButtonRefreshTable: ButtonRefreshTable
    },
    data: function () {
        return {
            tab: null,
        };
    },
});
