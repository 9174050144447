import { __extends } from "tslib";
import { BaseService } from './../../base';
var UserLocationService = /** @class */ (function (_super) {
    __extends(UserLocationService, _super);
    function UserLocationService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(UserLocationService, "entity", {
        get: function () {
            return 'user_location';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserLocationService, "responseName", {
        get: function () {
            return 'user_location';
        },
        enumerable: false,
        configurable: true
    });
    return UserLocationService;
}(BaseService()));
export { UserLocationService };
