import { __assign, __awaiter, __generator } from "tslib";
import { StablishmentService } from "@/services/models/stablishment";
import { PDFDocument } from "pdf-lib";
import { base64ArrayBuffer } from "@/others/toBase64";
import { callSha256, setQrCode } from "./functionsToFirm";
import Vue from "vue";
import { UserService } from "@/services/models/user/user";
import { mapActions } from "vuex";
import RejectFirm from "./RejectFirm.vue";
import VueHtml2pdf from "vue-html2pdf";
/* eslint-enable */
import SuccessPage from "@/components/document/firmDocument/SuccessPage.vue";
import SelectProfile from "@/components/document/firmDocument/SelectProfile.vue";
import Loader from "@/components/document/firmDocument/Loader.vue";
import Otp from "@/components/document/firmDocument/Otp.vue";
import { CdpService } from "@/services/models/purchaseRequest/cdp";
import { PurchaseBuyerStateService } from "@/services/models/purchaseRequest/purchaseBuyerState";
import { PurchaseHistoryService } from "@/services/models/purchaseRequest/purchaseHistory";
export default Vue.extend({
    components: {
        RejectFirm: RejectFirm,
        VueHtml2pdf: VueHtml2pdf,
        SuccessPage: SuccessPage,
        SelectProfile: SelectProfile,
        Loader: Loader,
        Otp: Otp,
    },
    props: {
        CdpProps: {
            required: true,
            type: Object,
        },
        PurchaseBuyerProps: {
            required: true,
            type: Object,
        },
    },
    data: function () {
        return {
            invalidStep: 0,
            step: 1,
            otp: null,
            loading: false,
            userLocationSelected: {},
            progress: 0,
            errorMessage: "",
            loadingOverlay: false,
            contentTemplate: "",
        };
    },
    methods: __assign(__assign({}, mapActions({
        setPending: "purchaseBuyer/getPurchaseBuyerPending",
        setReady: "purchaseBuyer/getPurchaseBuyerReady",
        setCdp: "cdp/getCdp",
    })), { loadData: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.setPending({
                        userId: this.getCurrentUserLoggedIn().id,
                        state: "pendiente",
                        step: "financialManager",
                    });
                    this.setReady({
                        userId: this.getCurrentUserLoggedIn().id,
                        state: "finalizado",
                        step: "financialManager",
                    });
                    this.setCdp(this.PurchaseBuyerProps.id);
                    return [2 /*return*/];
                });
            });
        },
        changeStep: function (step) {
            this.step = step;
        },
        validateStep: function (isValid, step) {
            if (!isValid) {
                this.invalidStep = step;
            }
        },
        dataURLtoFile: function (dataurl, filename) {
            var arr = dataurl.split(","), mime = arr[0].match(/:(.*?);/)[1], bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
        },
        addFirmToPdf: function (file) {
            var _a, _b, _c;
            return __awaiter(this, void 0, Promise, function () {
                var pngImageBytes, stablishmentId, stablishment, pdfDoc, pngImage, pngDims, pages, numberOfPages, lastPage, _d, width, height, user, name, apepat, apemat, pdfBytes;
                return __generator(this, function (_e) {
                    switch (_e.label) {
                        case 0: return [4 /*yield*/, ((_a = this.$refs.qr) === null || _a === void 0 ? void 0 : _a.children[1].currentSrc)];
                        case 1:
                            pngImageBytes = _e.sent();
                            stablishmentId = this.getCurrentUserLoggedIn().stablishment_id;
                            return [4 /*yield*/, StablishmentService.getById(stablishmentId)];
                        case 2:
                            stablishment = _e.sent();
                            return [4 /*yield*/, PDFDocument.load(file)];
                        case 3:
                            pdfDoc = _e.sent();
                            return [4 /*yield*/, pdfDoc.embedPng(pngImageBytes)];
                        case 4:
                            pngImage = _e.sent();
                            pngDims = pngImage.scale(0.1);
                            pages = pdfDoc.getPages();
                            numberOfPages = pdfDoc.getPageCount();
                            lastPage = pages[numberOfPages - 1];
                            _d = lastPage.getSize(), width = _d.width, height = _d.height;
                            user = this.getCurrentUserLoggedIn();
                            name = user.name;
                            apepat = user.pather_lastname;
                            apemat = user.mother_lastname;
                            lastPage.drawText(stablishment.name, {
                                x: width / 2 - 65,
                                y: height / 14 - 21,
                                size: 9,
                            });
                            lastPage.drawText(((_b = this.userLocationSelected.position) === null || _b === void 0 ? void 0 : _b.name) + " " + ((_c = this.userLocationSelected.location) === null || _c === void 0 ? void 0 : _c.name), {
                                x: width / 2 - 65,
                                y: height / 14 - 6,
                                size: 9,
                            });
                            lastPage.drawText(name + " " + apepat + " " + apemat, {
                                x: width / 2 - 65,
                                y: height / 14 + 9,
                                size: 10,
                            });
                            lastPage.drawText("Este documento ha sido firmado electrónicamente por", {
                                x: width / 2 - 65,
                                y: height / 14 + 24,
                                size: 10,
                            });
                            lastPage.drawImage(pngImage, {
                                x: width - width / 7,
                                y: height / 14 + -20,
                                width: pngDims.width * 2,
                                height: pngDims.height * 2,
                            });
                            return [4 /*yield*/, pdfDoc.saveAsBase64({ dataUri: false })];
                        case 5:
                            pdfBytes = _e.sent();
                            return [2 /*return*/, pdfBytes];
                    }
                });
            });
        },
        generateQr: function () {
            return __awaiter(this, void 0, Promise, function () {
                var user, qrContent;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, UserService.getById(this.getCurrentUserLoggedIn().id)];
                        case 1:
                            user = _a.sent();
                            qrContent = "Firmado por Servicio de Salud Magallanes, " + user.name + " " + user.pather_lastname + " " + user.mother_lastname + " rut: " + user.run;
                            /* eslint-disable */
                            setQrCode(this.$refs.qr, qrContent);
                            return [2 /*return*/];
                    }
                });
            });
        },
        prepareData: function () {
            return __awaiter(this, void 0, Promise, function () {
                var CDP, base64File, DOWNLOADEDFILE, blob, arrayBufferFile, base64WithFirm, token, checksum;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, CdpService.getById(this.CdpProps.id)];
                        case 1:
                            CDP = _a.sent();
                            this.progress = 20;
                            base64File = "";
                            if (!CDP.cdp_file) return [3 /*break*/, 4];
                            return [4 /*yield*/, CdpService.downloadFile(CDP.id)];
                        case 2:
                            DOWNLOADEDFILE = _a.sent();
                            blob = new Blob([DOWNLOADEDFILE.data], {
                                type: "application/pdf",
                            });
                            return [4 /*yield*/, blob.arrayBuffer()];
                        case 3:
                            arrayBufferFile = _a.sent();
                            base64File = base64ArrayBuffer(arrayBufferFile);
                            _a.label = 4;
                        case 4:
                            //TODO Comente esto porque no se q vegas hace(Y)
                            // else {
                            //   if (documentDetail.file_generated) {
                            //     this.contentTemplate = documentDetail.file_generated;
                            //   }
                            //   let pdfContent = this.$refs.html2Pdf.$refs.pdfContent;
                            //   let options = this.$refs.html2Pdf.setOptions();
                            //   let result = await html2pdf().set(options).from(pdfContent).outputPdf();
                            //   base64File = btoa(result);
                            // }
                            console.log(base64File);
                            return [4 /*yield*/, this.addFirmToPdf(base64File)];
                        case 5:
                            base64WithFirm = _a.sent();
                            this.progress = 30;
                            return [4 /*yield*/, UserService.getTokenKey(this.getCurrentUserLoggedIn().run.split("-")[0])];
                        case 6:
                            token = (_a.sent()).data;
                            this.progress = 40;
                            checksum = callSha256(Uint8Array.from(atob(base64WithFirm), function (c) { return c.charCodeAt(0); }));
                            /* eslint-enable */
                            return [2 /*return*/, {
                                    base64WithFirm: base64WithFirm,
                                    token: token,
                                    checksum: checksum,
                                }];
                    }
                });
            });
        },
        firmFile: function (token, base64, checksum, otp) {
            return __awaiter(this, void 0, void 0, function () {
                var resp, isValid, fileResponse, errorMessage;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, CdpService.getFileFirmed({
                                token: token,
                                content: base64,
                                checksum: checksum,
                                otp: otp,
                            })];
                        case 1:
                            resp = (_a.sent()).data;
                            isValid = false;
                            if (resp.files) {
                                // console.log(resp.files[0].status);
                                if (resp.files[0].status == "OK") {
                                    isValid = true;
                                }
                            }
                            if (isValid) {
                                fileResponse = resp.files[0].content;
                            }
                            else {
                                errorMessage = void 0;
                                if (resp.files) {
                                    errorMessage = resp.files[0].status ? resp.files[0].status : "";
                                }
                                else {
                                    errorMessage = resp.error ? " - " + resp.error : "";
                                }
                                this.errorMessage = errorMessage;
                                throw new Error("Falla del API " + errorMessage);
                            }
                            return [2 /*return*/, fileResponse];
                    }
                });
            });
        },
        generateFirmedPdf: function (otp) {
            return __awaiter(this, void 0, void 0, function () {
                var _a, base64WithFirm, token, checksum, file, error_1;
                var _this = this;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 8, , 9]);
                            this.errorMessage = "";
                            this.loading = true;
                            this.progress = 0;
                            return [4 /*yield*/, this.generateQr()];
                        case 1:
                            _b.sent();
                            this.progress = 10;
                            return [4 /*yield*/, this.prepareData()];
                        case 2:
                            _a = _b.sent(), base64WithFirm = _a.base64WithFirm, token = _a.token, checksum = _a.checksum;
                            if (!token || !otp) {
                                return [2 /*return*/];
                            }
                            return [4 /*yield*/, this.firmFile(token, base64WithFirm, checksum, otp)];
                        case 3:
                            file = _b.sent();
                            return [4 /*yield*/, CdpService.updateImage({
                                    base64: file,
                                    id: this.CdpProps.id,
                                })];
                        case 4:
                            _b.sent();
                            this.progress = 70;
                            this.progress = 80;
                            return [4 /*yield*/, this.VerifyAfterUpdateBuyerState()];
                        case 5:
                            _b.sent();
                            return [4 /*yield*/, CdpService.update(this.CdpProps.id, __assign(__assign({}, this.CdpProps), { state: "firmado", cdp_file_firmed_user_id: this.getCurrentUserLoggedIn().id }))];
                        case 6:
                            _b.sent();
                            this.progress = 90;
                            return [4 /*yield*/, PurchaseHistoryService.create({
                                    id: 0,
                                    purchase_request_id: this.PurchaseBuyerProps.purchase_request.id,
                                    state: "CDP ID " + this.CdpProps.id + ", del registro " + this.CdpProps.purchase_buyer_id + ", Firmado",
                                    user_origin_id: this.getCurrentUserLoggedIn().id,
                                    user_destination_id: this.getCurrentUserLoggedIn().id,
                                })];
                        case 7:
                            _b.sent();
                            this.progress = 100;
                            this.step = 3;
                            //Esto es por que no podía recargar el documentDetail sin que desapareciera el modal y no se alcanzaba a mostrar la pantalla de éxito
                            setTimeout(function () {
                                _this.loadData();
                            }, 2000);
                            return [3 /*break*/, 9];
                        case 8:
                            error_1 = _b.sent();
                            console.log(error_1);
                            this.loading = false;
                            this.displayToast({
                                type: "error",
                                message: "Lo sentimos, ha ocurrido un error. Favor volver a intentar",
                            });
                            return [3 /*break*/, 9];
                        case 9: return [2 /*return*/];
                    }
                });
            });
        },
        VerifyAfterUpdateBuyerState: function () {
            return __awaiter(this, void 0, void 0, function () {
                var validateStateCreate, CDPS, PURCHASEBUYERSTATE;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            validateStateCreate = true;
                            return [4 /*yield*/, CdpService.getPurchaseBuyerId(this.PurchaseBuyerProps.id)];
                        case 1:
                            CDPS = _a.sent();
                            CDPS.forEach(function (cdp) {
                                if (cdp.state == "creado") {
                                    validateStateCreate = false;
                                }
                            });
                            if (!validateStateCreate) return [3 /*break*/, 4];
                            return [4 /*yield*/, PurchaseBuyerStateService.getByPurchaseBuyerIdUserId(this.PurchaseBuyerProps.id, this.getCurrentUserLoggedIn().id)];
                        case 2:
                            PURCHASEBUYERSTATE = _a.sent();
                            if (!PURCHASEBUYERSTATE.length) return [3 /*break*/, 4];
                            return [4 /*yield*/, PurchaseBuyerStateService.update(PURCHASEBUYERSTATE[0].id, __assign(__assign({}, PURCHASEBUYERSTATE[0]), { state: "finalizado" }))];
                        case 3:
                            _a.sent();
                            _a.label = 4;
                        case 4: return [2 /*return*/];
                    }
                });
            });
        } }),
});
