import { __awaiter, __generator } from "tslib";
var MAX_WIDTH = 320;
var MAX_HEIGHT = 180;
var MIME_TYPE = "image/jpeg";
var QUALITY = 0.7;
export default function compressImage(file) {
    return __awaiter(this, void 0, Promise, function () {
        var blobURL, img, blobValue;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    blobURL = URL.createObjectURL(file);
                    img = new Image();
                    img.src = blobURL;
                    img.onerror = function () {
                        // URL.revokeObjectURL(this.src);
                        // Handle the failure properly
                        console.log("Cannot load image");
                    };
                    console.log(file.size);
                    return [4 /*yield*/, onLoadImage(img, file)];
                case 1:
                    blobValue = _a.sent();
                    console.log(blobValue.size);
                    return [2 /*return*/, blobValue];
            }
        });
    });
}
function onLoadImage(img, file) {
    return __awaiter(this, void 0, Promise, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve2) {
                    img.onload = function () {
                        // URL.revokeObjectURL(this.src);
                        var _a = calculateSize(img, MAX_WIDTH, MAX_HEIGHT), newWidth = _a[0], newHeight = _a[1];
                        var canvas = document.createElement("canvas");
                        canvas.width = newWidth;
                        canvas.height = newHeight;
                        var ctx = canvas.getContext("2d");
                        if (ctx) {
                            ctx.drawImage(img, 0, 0, newWidth, newHeight);
                            var promises = [];
                            promises.push(new Promise(function (resolve) {
                                canvas.toBlob(function (blob) {
                                    // Handle the compressed image. es. upload or save in local state
                                    displayInfo("Original file", file);
                                    if (blob) {
                                        displayInfo("Compressed file", blob);
                                        resolve(blob);
                                    }
                                }, MIME_TYPE, QUALITY);
                            }));
                            Promise.all(promises).then(function (value) {
                                resolve2(value[0]);
                            });
                        }
                    };
                })];
        });
    });
}
function calculateSize(img, maxWidth, maxHeight) {
    var width = img.width;
    var height = img.height;
    // calculate the width and height, constraining the proportions
    if (width > height) {
        if (width > maxWidth) {
            height = Math.round((height * maxWidth) / width);
            width = maxWidth;
        }
    }
    else {
        if (height > maxHeight) {
            width = Math.round((width * maxHeight) / height);
            height = maxHeight;
        }
    }
    return [width, height];
}
// Utility functions for demo purpose
function displayInfo(label, file) {
    return __awaiter(this, void 0, void 0, function () {
        var p;
        return __generator(this, function (_a) {
            p = document.createElement("p");
            p.innerText = label + " - " + readableBytes(file.size);
            console.log(p);
            return [2 /*return*/];
        });
    });
}
function readableBytes(bytes) {
    return __awaiter(this, void 0, void 0, function () {
        var i, sizes;
        return __generator(this, function (_a) {
            i = Math.floor(Math.log(bytes) / Math.log(1024)), sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
            return [2 /*return*/, (bytes / Math.pow(1024, i)).toFixed(2) + " " + sizes[i]];
        });
    });
}
