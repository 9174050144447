import { __assign, __awaiter, __generator } from "tslib";
import { DocumentDetailService } from "@/services/models/document/documentDetail";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
    props: {
        documentProp: {
            required: true,
            type: Object,
        },
    },
    data: function () {
        return {
            defaultButtonText: "Agregar Archivo",
            isSelecting: false,
            selectedFile: {},
        };
    },
    methods: __assign(__assign({}, mapActions({
        getDocumentsDetail: "documentDetail/getByDocumentId",
    })), { onButtonClick: function () {
            this.$refs.uploader.click();
        },
        onFileChanged: function (e) {
            return __awaiter(this, void 0, void 0, function () {
                var formData, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 4, 5, 6]);
                            if (!(this.selectedFile.type !== "application/pdf" &&
                                this.selectedFile.type !== "application/PDF")) return [3 /*break*/, 2];
                            this.isSelecting = true;
                            this.selectedFile = e.target.files[0];
                            formData = new FormData();
                            formData.append("document_id", this.documentProp.id);
                            formData.append("user_id", JSON.parse(localStorage.getItem("user") || "{}").id);
                            formData.append("state", "disponible");
                            formData.append("file", this.selectedFile);
                            return [4 /*yield*/, DocumentDetailService.create(formData, {
                                    "Content-Type": "multipart/form-data",
                                })];
                        case 1:
                            _a.sent();
                            this.selectedFile = {};
                            this.getDocumentsDetail(this.documentProp.id);
                            return [3 /*break*/, 3];
                        case 2:
                            this.displayToast({
                                type: "error",
                                message: "Favor de seleccionar archivo PDF",
                            });
                            _a.label = 3;
                        case 3: return [3 /*break*/, 6];
                        case 4:
                            error_1 = _a.sent();
                            console.log(error_1);
                            throw error_1;
                        case 5:
                            this.isSelecting = false;
                            return [7 /*endfinally*/];
                        case 6: return [2 /*return*/];
                    }
                });
            });
        } }),
});
