import { __awaiter, __generator } from "tslib";
import { PurchaseRequestService } from "@/services/models/purchaseRequest/purchaseRequest";
import Vue from "vue";
export default Vue.extend({
    props: {
        purchaseProp: {
            type: Object,
        },
    },
    data: function () {
        return {
            purchase_request: {
                user: {},
                purchase_request_type: {},
                description: '',
                estimated_use_date: '',
                state: '',
                user_location: {}
            },
        };
    },
    methods: {
        loadData: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            this.displayLoader(true);
                            _a = this;
                            return [4 /*yield*/, PurchaseRequestService.getById(this.purchaseProp.id)];
                        case 1:
                            _a.purchase_request = _b.sent();
                            this.displayLoader(false);
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
    mounted: function () {
        this.loadData();
    },
});
