import { __assign, __awaiter, __generator } from "tslib";
import Vue from "vue";
import { TicketMessageService } from "@/services/models/ticket/ticketMessage";
import { mapActions } from "vuex";
export default Vue.extend({
    props: {
        itemProp: {
            type: Object,
        },
    },
    data: function () {
        return {
            isLoading: false,
            ticket_message: {
                file: null,
            },
        };
    },
    methods: __assign(__assign({}, mapActions({
        setMessage: "ticketMessage/getTicketMessage",
    })), { save: function () {
            return __awaiter(this, void 0, void 0, function () {
                var formData, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.displayLoader(true);
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, 4, 5]);
                            formData = new FormData();
                            formData.append("message", this.ticket_message.message || '');
                            formData.append("user_id", "" + this.getCurrentUserLoggedIn().id);
                            formData.append("ticket_id", this.itemProp.id);
                            formData.append("file", this.ticket_message.file);
                            return [4 /*yield*/, TicketMessageService.create(formData, {
                                    "Content-Type": "multipart/form-data",
                                })];
                        case 2:
                            _a.sent();
                            this.ticket_message.file = null;
                            this.setMessage(this.itemProp.id);
                            return [3 /*break*/, 5];
                        case 3:
                            error_1 = _a.sent();
                            console.log(error_1);
                            this.displayToast({
                                type: "error",
                                message: "Error al enviar el mensaje, favor de reintentar",
                            });
                            return [3 /*break*/, 5];
                        case 4:
                            this.displayLoader(false);
                            return [7 /*endfinally*/];
                        case 5: return [2 /*return*/];
                    }
                });
            });
        },
        onButtonClick: function () {
            var _this = this;
            this.isLoading = true;
            window.addEventListener("focus", function () {
                _this.isLoading = false;
            }, { once: true });
            this.$refs.uploader.click();
        },
        onFileChanged: function (e) {
            this.ticket_message.file = e.target.files[0];
            // do something
        } }),
});
