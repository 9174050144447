import { __awaiter, __generator } from "tslib";
import { PurchaseRequestFileService } from "@/services/models/purchaseRequest/purchaseRequestFile";
import Vue from "vue";
export default Vue.extend({
    props: {
        purchaseProp: {
            type: Object,
        },
    },
    data: function () {
        return {
            getLoading: false,
            getItems: [],
            headers: [
                {
                    text: "Nombre",
                    sortable: false,
                    value: "file_name",
                },
                {
                    text: "Archivo",
                    sortable: false,
                    value: "file",
                },
            ],
        };
    },
    mounted: function () {
        this.loadData();
    },
    methods: {
        loadData: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            this.getLoading = true;
                            _a = this;
                            return [4 /*yield*/, PurchaseRequestFileService.getByPurchaseId(this.purchaseProp.id)];
                        case 1:
                            _a.getItems = _b.sent();
                            this.getLoading = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
});
