import { __assign, __awaiter, __generator } from "tslib";
import FormPurchase from "./FormPurchase.vue";
import FormArticles from "./FormArticles.vue";
import FormFiles from "@/components/general/FormFiles.vue";
import Vue from "vue";
import { mapActions } from "vuex";
import { PurchaseHistoryService } from "@/services/models/purchaseRequest/purchaseHistory";
import { PurchaseRequestStateService } from "@/services/models/purchaseRequest/purchaseRequestState";
import { PurchaseRequestDetailService } from "@/services/models/purchaseRequest/purchaseRequestDetail";
import { PurchaseRequestFileService } from "@/services/models/purchaseRequest/purchaseRequestFile";
import { PurchaseRequestService } from "@/services/models/purchaseRequest/purchaseRequest";
export default Vue.extend({
    components: {
        FormPurchase: FormPurchase,
        FormArticles: FormArticles,
        FormFiles: FormFiles,
    },
    data: function () {
        return {
            step: 1,
            invalidStep: 0,
            dialog: false,
        };
    },
    methods: __assign(__assign({}, mapActions({
        recharge: "purchaseRequest/getPurchaseRequest",
    })), { validateStep: function (isValid, step) {
            if (!isValid) {
                this.invalidStep = step;
            }
        },
        save: function () {
            return __awaiter(this, void 0, void 0, function () {
                var formPurchase, formArticles, dataPurchaseRequest, responsePurchaseRequest, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.displayLoader(true);
                            formPurchase = this.$refs.formPurchase;
                            formArticles = this.$refs.formArticles;
                            // Se valida que los formularios este con todos los campos correspondientes
                            if (!formPurchase.validateForm())
                                return [2 /*return*/, (this.validateStep(false, 1), this.displayLoader(false))];
                            if (!formArticles.validateForm())
                                return [2 /*return*/, (this.validateStep(false, 2), this.displayLoader(false))];
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 7, 8, 9]);
                            dataPurchaseRequest = __assign({}, formPurchase.data);
                            return [4 /*yield*/, PurchaseRequestService.create(dataPurchaseRequest)];
                        case 2:
                            responsePurchaseRequest = _a.sent();
                            /** Estas tienen consultas en For, por ende deben estar con promesas */
                            //Insert en table purchase_request_detail
                            return [4 /*yield*/, this.postPurchaseRequestDetail(responsePurchaseRequest)];
                        case 3:
                            /** Estas tienen consultas en For, por ende deben estar con promesas */
                            //Insert en table purchase_request_detail
                            _a.sent();
                            //Insert en table purchase_request_file
                            return [4 /*yield*/, this.postPurchaseRequestFile(responsePurchaseRequest)];
                        case 4:
                            //Insert en table purchase_request_file
                            _a.sent();
                            /** //////////////////////////////////////////////////////////////// */
                            //Insert en tabla purchase_request_state
                            return [4 /*yield*/, this.postPurchaseRequestState(responsePurchaseRequest)];
                        case 5:
                            /** //////////////////////////////////////////////////////////////// */
                            //Insert en tabla purchase_request_state
                            _a.sent();
                            //Insert en tabla purchase_request_history
                            return [4 /*yield*/, this.postPurchaseHistory(responsePurchaseRequest)];
                        case 6:
                            //Insert en tabla purchase_request_history
                            _a.sent();
                            this.recharge(this.getCurrentUserLoggedIn().id);
                            this.dialog = false;
                            return [3 /*break*/, 9];
                        case 7:
                            error_1 = _a.sent();
                            console.log(error_1);
                            this.displayToast({
                                type: "error",
                                message: "Error al guardar, favor de reintentar",
                            });
                            return [3 /*break*/, 9];
                        case 8:
                            this.displayLoader(false);
                            return [7 /*endfinally*/];
                        case 9: return [2 /*return*/];
                    }
                });
            });
        },
        postPurchaseRequestDetail: function (purchaseRequest) {
            return __awaiter(this, void 0, void 0, function () {
                var formArticles, promise, _loop_1, i;
                return __generator(this, function (_a) {
                    formArticles = this.$refs.formArticles;
                    if (formArticles && formArticles.dataArray.length != 0) {
                        promise = [];
                        _loop_1 = function (i) {
                            promise.push(new Promise(function (resolve, reject) {
                                var detail = formArticles.dataArray[i];
                                var dataPurchaseRequestDetail = {
                                    id: 0,
                                    purchase_request_id: purchaseRequest.id,
                                    article_id: detail.article_id,
                                    cant: detail.cant,
                                    price: detail.price,
                                    observation: detail.observation,
                                };
                                PurchaseRequestDetailService.create(dataPurchaseRequestDetail)
                                    .then(function (response) { return resolve(response); })
                                    .catch(function (error) { return reject(error); });
                            }));
                        };
                        for (i = 0; i < formArticles.dataArray.length; i++) {
                            _loop_1(i);
                        }
                        Promise.all(promise)
                            .then(function () {
                            return;
                        })
                            .catch(function (reason) {
                            console.log(reason);
                        });
                    }
                    return [2 /*return*/];
                });
            });
        },
        postPurchaseRequestFile: function (purchaseRequest) {
            return __awaiter(this, void 0, void 0, function () {
                var formFiles, promise, _loop_2, i;
                return __generator(this, function (_a) {
                    formFiles = this.$refs.formFiles;
                    if (formFiles && formFiles.files.length != 0) {
                        promise = [];
                        _loop_2 = function (i) {
                            promise.push(new Promise(function (resolve, reject) {
                                var formData = new FormData();
                                formData.append("purchase_request_id", "" + purchaseRequest.id);
                                formData.append("file", formFiles.files[i]);
                                //BUG Hay que sacar el campo observacion de esta tabla
                                formData.append("observation", "Sin observación");
                                PurchaseRequestFileService.create(formData, {
                                    "Content-Type": "multipart/form-data",
                                })
                                    .then(function (response) { return resolve(response); })
                                    .catch(function (error) { return reject(error); });
                            }));
                        };
                        for (i = 0; i < formFiles.files.length; i++) {
                            _loop_2(i);
                        }
                        Promise.all(promise)
                            .then(function () {
                            return;
                        })
                            .catch(function (reason) {
                            console.log(reason);
                        });
                    }
                    return [2 /*return*/];
                });
            });
        },
        postPurchaseRequestState: function (purchaseRequest) {
            return __awaiter(this, void 0, void 0, function () {
                var dataPurchaseState;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            dataPurchaseState = {
                                id: 0,
                                purchase_request_id: purchaseRequest.id,
                                state: "creado",
                                step: "solicitante",
                                user_id: this.getCurrentUserLoggedIn().id,
                                user_location_id: this.getUserLocation().location_id,
                            };
                            return [4 /*yield*/, PurchaseRequestStateService.create(dataPurchaseState)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        postPurchaseHistory: function (purchaseRequest) {
            return __awaiter(this, void 0, void 0, function () {
                var dataPurchaseHistory;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            dataPurchaseHistory = {
                                id: 0,
                                purchase_request_id: purchaseRequest.id,
                                state: "solicitud creada",
                                user_origin_id: this.getCurrentUserLoggedIn().id,
                                user_destination_id: this.getCurrentUserLoggedIn().id,
                            };
                            return [4 /*yield*/, PurchaseHistoryService.create(dataPurchaseHistory)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        } }),
    watch: {
        invalidStep: function () {
            if (this.invalidStep != 0) {
                this.step = this.invalidStep;
            }
        },
    },
});
