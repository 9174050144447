import { __assign, __awaiter, __generator } from "tslib";
import { DocumentDetailFileService } from "@/services/models/document/documentDetailFile";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
    name: "BtnCreateDetailFile",
    props: {
        documentProp: {
            type: Object,
            required: true,
        },
    },
    data: function () {
        return {
            isLoading: false,
            documentDetailFile: {},
            files: [],
        };
    },
    methods: __assign(__assign({}, mapActions({
        getDetailFile: "documentDetailFile/getDetailFile",
        getDocumentsDetail: "documentDetail/getByDocumentId",
    })), { save: function () {
            return __awaiter(this, void 0, void 0, function () {
                var i, formData, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 7, 8, 9]);
                            this.isLoading = true;
                            if (this.files.length === 0) {
                                this.displayToast({
                                    type: "warning",
                                    message: "Favor de seleccionar algun archivo",
                                });
                                return [2 /*return*/];
                            }
                            if (!this.comprobateTypeFiles()) return [3 /*break*/, 5];
                            i = 0;
                            _a.label = 1;
                        case 1:
                            if (!(i < this.files.length)) return [3 /*break*/, 4];
                            formData = new FormData();
                            formData.append("file", this.files[i]);
                            formData.append("state", "creado");
                            formData.append("document_detail_id", this.documentProp.id.toString());
                            formData.append("user_id", this.getCurrentUserLoggedIn().id.toString());
                            return [4 /*yield*/, DocumentDetailFileService.create(formData, {
                                    "Content-Type": "multipart/form-data",
                                })];
                        case 2:
                            _a.sent();
                            _a.label = 3;
                        case 3:
                            i++;
                            return [3 /*break*/, 1];
                        case 4:
                            this.getDetailFile(this.documentProp.id);
                            this.files = [];
                            return [3 /*break*/, 6];
                        case 5:
                            this.displayToast({
                                type: 'error',
                                message: 'Uno de los documentos no es formato PDF'
                            });
                            _a.label = 6;
                        case 6: return [3 /*break*/, 9];
                        case 7:
                            error_1 = _a.sent();
                            console.log(error_1);
                            throw error_1;
                        case 8:
                            this.isLoading = false;
                            return [7 /*endfinally*/];
                        case 9: return [2 /*return*/];
                    }
                });
            });
        },
        comprobateTypeFiles: function () {
            var isValid = true;
            for (var i = 0; i < this.files.length; i++) {
                console.log(this.files[i].type);
                if (this.files[i].type !== "application/pdf" &&
                    this.files[i].type !== "application/PDF") {
                    isValid = false;
                }
            }
            return isValid;
        } }),
});
