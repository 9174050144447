var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-data-table',{staticClass:"elevation-5",attrs:{"loading":_vm.getLoading,"headers":_vm.headers,"items":_vm.getPending.data,"page":_vm.getPending.current_page,"items-per-page":_vm.getPending.per_page,"server-items-length":_vm.getPending.total,"show-expand":""},on:{"update:page":function($event){return _vm.$set(_vm.getPending, "current_page", $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"mr-4"},[_vm._v("Compras por autorizar")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1)]},proxy:true},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.state.toUpperCase())+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('group-buttons',{attrs:{"purchaseProp":item,"dataSend":{ step: 'autorizador', state: 'pendiente' },"showButtonAssignBuyer":true}})]}},{key:"item.data-table-expand",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('v-btn',{attrs:{"x-small":"","icon":"","color":"gray"},on:{"click":function($event){return expand(!isExpanded)}}},[(isExpanded)?_c('v-icon',[_vm._v("fa-chevron-up")]):_c('v-icon',[_vm._v("fa-chevron-down")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('detail-table-purchase-request',{staticClass:"ma-2",attrs:{"purchaseProp":item}})],1)]}}],null,true)}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.getPending.last_page},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }