import { __assign, __awaiter, __generator } from "tslib";
import { BuyerService } from "@/services/models/purchaseRequest/buyer";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
    props: {
        buyerProp: {
            type: Object,
        },
    },
    data: function () {
        return {
            dialog: false,
        };
    },
    methods: __assign(__assign({}, mapActions({
        setBuyer: "buyer/getBuyer",
    })), { removeBuyer: function () {
            return __awaiter(this, void 0, void 0, function () {
                var error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.displayLoader(true);
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, 4, 5]);
                            return [4 /*yield*/, BuyerService.remove(this.buyerProp.id)];
                        case 2:
                            _a.sent();
                            this.setBuyer();
                            this.dialog = false;
                            return [3 /*break*/, 5];
                        case 3:
                            error_1 = _a.sent();
                            console.log(error_1);
                            return [3 /*break*/, 5];
                        case 4:
                            this.displayLoader(false);
                            return [7 /*endfinally*/];
                        case 5: return [2 /*return*/];
                    }
                });
            });
        } }),
});
