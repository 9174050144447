import { __assign, __awaiter, __generator } from "tslib";
import InputSelectUsers from "@/components/document/ShowDocument/panelDetail/InputSelectUsers.vue";
import DataSendPurchase from "./DataSendPurchase.vue";
import { PurchaseHistoryService } from "@/services/models/purchaseRequest/purchaseHistory";
import { PurchaseRequestService } from "@/services/models/purchaseRequest/purchaseRequest";
import { PurchaseRequestStateService } from "@/services/models/purchaseRequest/purchaseRequestState";
import Vue from "vue";
export default Vue.extend({
    components: { InputSelectUsers: InputSelectUsers, DataSendPurchase: DataSendPurchase },
    props: {
        purchaseProp: {
            type: Object,
        },
        dataSend: {
            type: Object,
        },
        recharge: {
            type: Function
        }
    },
    data: function () {
        return {
            dialog: false,
        };
    },
    methods: {
        checkState: function (state) {
            console.log(state);
            if (state != "creado" && state != "pendiente")
                return true;
        },
        save: function () {
            return __awaiter(this, void 0, void 0, function () {
                var inputUsers, data, states, newState, currentUserState, error_1;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.displayLoader(true);
                            inputUsers = this.$refs.selectUser.usersSelects[0];
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 10, 11, 12]);
                            data = {
                                state: this.dataSend.state,
                                step: this.dataSend.step,
                                user: inputUsers,
                            };
                            return [4 /*yield*/, PurchaseRequestStateService.getByPurchaseRequestId(this.purchaseProp.id)];
                        case 2:
                            states = _a.sent();
                            newState = states.filter(function (state) {
                                return state.user_id == inputUsers.id;
                            });
                            if (!(newState.length == 0)) return [3 /*break*/, 4];
                            return [4 /*yield*/, this.postPurchaseRequestState(data)];
                        case 3:
                            _a.sent();
                            _a.label = 4;
                        case 4:
                            if (!(newState.length != 0)) return [3 /*break*/, 6];
                            return [4 /*yield*/, this.putPurchaseRequestState(__assign(__assign({}, data), { id: newState[newState.length - 1].id }))];
                        case 5:
                            _a.sent();
                            _a.label = 6;
                        case 6:
                            currentUserState = states.filter(function (state) {
                                return state.user_id == _this.getCurrentUserLoggedIn().id;
                            });
                            console.log(currentUserState);
                            return [4 /*yield*/, PurchaseRequestStateService.update(currentUserState[currentUserState.length - 1].id, __assign(__assign({}, currentUserState[currentUserState.length - 1]), { state: "enviado" }))];
                        case 7:
                            _a.sent();
                            return [4 /*yield*/, this.putPurchaseRequest()];
                        case 8:
                            _a.sent();
                            return [4 /*yield*/, this.postPurchaseHistory({ user: inputUsers })];
                        case 9:
                            _a.sent();
                            this.recharge();
                            this.displayToast({
                                type: "success",
                                message: "Se envio la solicitud sin problemas",
                            });
                            this.dialog = false;
                            return [3 /*break*/, 12];
                        case 10:
                            error_1 = _a.sent();
                            console.log(error_1);
                            this.displayToast({
                                type: "error",
                                message: "Hubo un error al guardar, favor de reintentar",
                            });
                            return [3 /*break*/, 12];
                        case 11:
                            this.displayLoader(false);
                            return [7 /*endfinally*/];
                        case 12: return [2 /*return*/];
                    }
                });
            });
        },
        putPurchaseRequest: function () {
            return __awaiter(this, void 0, void 0, function () {
                var dataPurcaseRequest;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            dataPurcaseRequest = __assign(__assign({}, this.purchaseProp), { state: "enviado" });
                            return [4 /*yield*/, PurchaseRequestService.update(this.purchaseProp.id, dataPurcaseRequest)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        postPurchaseRequestState: function (payLoad) {
            var _a;
            return __awaiter(this, void 0, void 0, function () {
                var dataPurchaseState;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            dataPurchaseState = {
                                id: 0,
                                purchase_request_id: this.purchaseProp.id,
                                state: payLoad.state,
                                step: payLoad.step,
                                user_id: payLoad.user.id,
                                user_location_id: ((_a = payLoad.user.users_locations) === null || _a === void 0 ? void 0 : _a[0].location_id) || 0,
                            };
                            return [4 /*yield*/, PurchaseRequestStateService.create(dataPurchaseState)];
                        case 1:
                            _b.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        putPurchaseRequestState: function (payLoad) {
            var _a;
            return __awaiter(this, void 0, void 0, function () {
                var dataPurchaseState;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            dataPurchaseState = {
                                id: 0,
                                purchase_request_id: this.purchaseProp.id,
                                state: payLoad.state,
                                step: payLoad.step,
                                user_id: payLoad.user.id,
                                user_location_id: ((_a = payLoad.user.users_locations) === null || _a === void 0 ? void 0 : _a[0].location_id) || 0,
                            };
                            return [4 /*yield*/, PurchaseRequestStateService.update(payLoad.id, dataPurchaseState)];
                        case 1:
                            _b.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        postPurchaseHistory: function (payLoad) {
            return __awaiter(this, void 0, void 0, function () {
                var dataPurchaseHistory;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            dataPurchaseHistory = {
                                id: 0,
                                purchase_request_id: this.purchaseProp.id,
                                state: "solicitud enviada",
                                user_origin_id: this.getCurrentUserLoggedIn().id,
                                user_destination_id: payLoad.user.id,
                            };
                            return [4 /*yield*/, PurchaseHistoryService.create(dataPurchaseHistory)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
});
