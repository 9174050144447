import TableBuyerPending from "@/components/purchaseRequest/tables/tablesBuyer/TableBuyerPending.vue";
import TableBuyerReady from "@/components/purchaseRequest/tables/tablesBuyer/TableBuyerReady.vue";
import TableBuyerInProcess from "@/components/purchaseRequest/tables/tablesBuyer/TableBuyerInProcess.vue";
import TemplateAdministrator from "@/templates/TemplateAdministrator.vue";
import TableBuyerCdpFirm from "@/components/purchaseRequest/tables/tablesBuyer/TableBuyerCdpFirm.vue";
import Vue from "vue";
export default Vue.extend({
    components: {
        TemplateAdministrator: TemplateAdministrator,
        TableBuyerPending: TableBuyerPending,
        TableBuyerReady: TableBuyerReady,
        TableBuyerInProcess: TableBuyerInProcess,
        TableBuyerCdpFirm: TableBuyerCdpFirm
    },
    data: function () {
        return {
            tab: null,
        };
    },
});
