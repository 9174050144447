import Vue from "vue";
import CreateUserContent from "./CreateUserContent.vue";
export default Vue.extend({
    components: { CreateUserContent: CreateUserContent },
    data: function () {
        return {
            dialog: false,
        };
    }
});
