import { __extends } from "tslib";
import { BaseService } from './../../base';
var PurchaseRequestTypeService = /** @class */ (function (_super) {
    __extends(PurchaseRequestTypeService, _super);
    function PurchaseRequestTypeService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(PurchaseRequestTypeService, "entity", {
        get: function () {
            return 'purchase_request_type';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PurchaseRequestTypeService, "responseName", {
        get: function () {
            return 'purchase_request_type';
        },
        enumerable: false,
        configurable: true
    });
    return PurchaseRequestTypeService;
}(BaseService()));
export { PurchaseRequestTypeService };
