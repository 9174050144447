import { __awaiter, __extends, __generator } from "tslib";
import { BaseService } from './../../base';
var TicketService = /** @class */ (function (_super) {
    __extends(TicketService, _super);
    function TicketService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(TicketService, "entity", {
        get: function () {
            return 'ticket';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TicketService, "responseName", {
        get: function () {
            return 'ticket';
        },
        enumerable: false,
        configurable: true
    });
    TicketService.getByUserId = function (user_id, page) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_user_id/" + user_id + "?page=" + page
                    })];
            });
        });
    };
    TicketService.getByGroupId = function (group_id, page) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_group_id/" + group_id + "?page=" + page
                    })];
            });
        });
    };
    TicketService.getByDateAndGroup = function (date_start, date_end, group_id) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_date_and_group/" + date_start + "/" + date_end + "/" + group_id
                    })];
            });
        });
    };
    TicketService.getByDateAndUser = function (date_start, date_end, user_id) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_date_and_user/" + date_start + "/" + date_end + "/" + user_id
                    })];
            });
        });
    };
    TicketService.getExcelReport = function (date_start, date_end, group_id) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "export/" + date_start + "/" + date_end + "/" + group_id,
                        config: {
                            responseType: 'blob'
                        },
                        showFullResponse: true
                    })];
            });
        });
    };
    TicketService.getByTicketFilter = function (payload, page) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'post',
                        path: "find_by_ticket_filter?page=" + page,
                        data: payload
                    })];
            });
        });
    };
    return TicketService;
}(BaseService()));
export { TicketService };
