import Vue from "vue";
export default Vue.extend({
    props: {
        profile: {
            type: String,
            required: true,
        },
    },
    data: function () {
        return {
            document: [
                {
                    text: "Tickets",
                    icon: "fas fa-ticket-alt",
                    to: { name: "ticket" + this.profile },
                },
            ],
        };
    },
});
