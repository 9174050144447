import { __assign, __awaiter, __generator } from "tslib";
import { PurchaseBuyerService } from "@/services/models/purchaseRequest/purchaseBuyer";
import Vue from "vue";
import { mapActions } from "vuex";
import FormPurchaseBuyer from "./ButtonCreatePurchaseBuyer.vue/FormPurchaseBuyer.vue";
export default Vue.extend({
    components: { FormPurchaseBuyer: FormPurchaseBuyer },
    props: {
        purchaseRequestProp: {
            type: Object,
        },
        purchaseBuyerProp: {
            type: Object,
        },
    },
    data: function () {
        return {
            dialog: false,
        };
    },
    methods: __assign(__assign({}, mapActions({
        setPurchaseBuyer: 'purchaseBuyer/getPurchaseBuyer'
    })), { save: function () {
            return __awaiter(this, void 0, void 0, function () {
                var dataPurchaseBuyer, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.displayLoader(true);
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, 4, 5]);
                            dataPurchaseBuyer = this.$refs.formPurchaseBuyer.data;
                            return [4 /*yield*/, PurchaseBuyerService.update(this.purchaseBuyerProp.id, dataPurchaseBuyer)];
                        case 2:
                            _a.sent();
                            this.displayToast({
                                type: "success",
                                message: "Registro modificado",
                            });
                            this.setPurchaseBuyer(this.purchaseRequestProp.id);
                            this.dialog = false;
                            return [3 /*break*/, 5];
                        case 3:
                            error_1 = _a.sent();
                            console.log(error_1);
                            return [3 /*break*/, 5];
                        case 4:
                            this.displayLoader(false);
                            return [7 /*endfinally*/];
                        case 5: return [2 /*return*/];
                    }
                });
            });
        } }),
});
