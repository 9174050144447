import { __assign } from "tslib";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
export default Vue.extend({
    name: "TableDetailFiles",
    props: {
        detailProp: {
            type: Object,
        },
    },
    data: function () {
        return {
            headers: [
                { text: "Id", align: "start", value: "id" },
                { text: "Nombre", align: "start", value: "file_name" },
                { text: "Fecha", align: "start", value: "created_at" },
                { text: "Ver", align: "start", value: "actions" },
            ],
        };
    },
    mounted: function () {
        this.getDetailFile(this.detailProp.id);
    },
    computed: __assign({}, mapGetters({
        detailFiles: "documentDetailFile/getDetailFile",
        loading: "documentDetailFile/getLoading",
    })),
    methods: __assign({}, mapActions({
        getDetailFile: "documentDetailFile/getDetailFile",
    })),
});
