import { __awaiter, __generator } from "tslib";
import { HistoryService } from "@/services/models/document/history";
var moduleHistory = {
    namespaced: true,
    state: {
        history: [],
        historyByDetailId: [],
        loading: false,
    },
    getters: {
        getHistories: function (state) {
            return state.history;
        },
        getDocumentDetailId: function (state) {
            return state.historyByDetailId;
        },
        getLoading: function (state) {
            return state.loading;
        }
    },
    mutations: {
        setHistory: function (state, history) {
            state.history = history;
        },
        setDocumentDetailId: function (state, historyByDetailId) {
            state.historyByDetailId = historyByDetailId;
        },
        setLoading: function (state, loading) {
            state.loading = loading;
        }
    },
    actions: {
        getHistories: function (_a) {
            var commit = _a.commit;
            return __awaiter(this, void 0, Promise, function () {
                var HISTORY;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, HistoryService.getAll()];
                        case 1:
                            HISTORY = _b.sent();
                            commit("setHistory", HISTORY);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        getDocumentDetailId: function (_a, id) {
            var commit = _a.commit;
            return __awaiter(this, void 0, Promise, function () {
                var HISTORY;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, HistoryService.getByDocumentDetailId(id)];
                        case 1:
                            HISTORY = _b.sent();
                            commit("setDocumentDetailId", HISTORY);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        }
    }
};
export default moduleHistory;
