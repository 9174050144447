import { __assign } from "tslib";
import Vue from "vue";
import ButtonSend from "./ButtonSend.vue";
import TableHistories from "./tableHistories/TableHistories.vue";
import { mapActions, mapGetters } from "vuex";
import GroupButtons from "./GroupButtons.vue";
import Editor from "@tinymce/tinymce-vue";
export default Vue.extend({
    props: {
        documentProp: {
            type: Object,
        },
    },
    components: {
        editor: Editor,
        TableHistories: TableHistories,
        ButtonSend: ButtonSend,
        GroupButtons: GroupButtons,
    },
    data: function () {
        return {
            open: [],
            configurationTiny: {
                menubar: false,
                toolbar: false,
                statusbar: false,
                height: '500',
                readonly: true,
                setup: function (ed) {
                    ed.settings.readonly = true;
                }
            },
        };
    },
    mounted: function () {
        this.getDocumentsDetail(this.documentProp.id);
    },
    computed: __assign({}, mapGetters({
        getDocuments: "documentDetail/getByDocumentIdByUser",
        getLoading: "documentDetail/getLoading",
    })),
    methods: __assign(__assign({}, mapActions({
        getDocumentsDetail: "documentDetail/getByDocumentIdByUser",
    })), { hasChipRead: function (documentDetail) {
            var _this = this;
            var isRead = false;
            if (documentDetail.documents_details_reads) {
                if (this.getProfile() == "opartes") {
                    isRead = !!documentDetail.documents_details_reads.find(function (detail) {
                        return detail.location_id == _this.getUserLocation().id;
                    });
                }
                else {
                    isRead = !!documentDetail.documents_details_reads.find(function (detail) {
                        return detail.user_id == _this.getCurrentUserLoggedIn().id;
                    });
                }
            }
            if (documentDetail.user_id == this.getCurrentUserLoggedIn().id) {
                isRead = true;
            }
            return isRead;
        },
        hasChipFirmed: function (documentDetail) {
            var _this = this;
            var _a;
            var mustFirm = false;
            if ((_a = documentDetail.documents_details_firmed) === null || _a === void 0 ? void 0 : _a.length) {
                var hasFirm = documentDetail.documents_details_firmed.find(function (value) { return value.user_id == _this.getCurrentUserLoggedIn().id; });
                if (hasFirm && (hasFirm === null || hasFirm === void 0 ? void 0 : hasFirm.state) === "por firmar") {
                    mustFirm = true;
                }
            }
            return mustFirm;
        },
        hasChipChecked: function (documentDetail) {
            var _this = this;
            var _a;
            var mustCheck = false;
            if ((_a = documentDetail.documents_details_checked) === null || _a === void 0 ? void 0 : _a.length) {
                var hasCheck = documentDetail.documents_details_checked.find(function (value) { return value.user_id == _this.getCurrentUserLoggedIn().id; });
                if (hasCheck && (hasCheck === null || hasCheck === void 0 ? void 0 : hasCheck.state) === "por visar") {
                    mustCheck = true;
                }
            }
            return mustCheck;
        },
        colorChip: function (item) {
            var colorChip = {
                date: "",
                fileName: "",
            };
            if (item.state == "disponible") {
                colorChip.date = "primary lighten-1";
                colorChip.fileName = "primary";
            }
            else if (item.state == "invalido") {
                colorChip.date = "red lighten-1";
                colorChip.fileName = "error";
            }
            return colorChip;
        } }),
});
