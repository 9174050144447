var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-5",attrs:{"loading":_vm.getLoading,"headers":_vm.headers,"items":_vm.getPurchaseBuyer,"show-expand":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('button-create-purchase-buyer',{attrs:{"buyerProp":_vm.buyerProp,"purchaseProp":_vm.purchaseProp}})],1)]},proxy:true},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.state.toUpperCase())+" ")]}},{key:"item.data-table-expand",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('v-btn',{attrs:{"x-small":"","icon":"","color":"gray"},on:{"click":function($event){return expand(!isExpanded)}}},[(isExpanded)?_c('v-icon',[_vm._v("fa-chevron-up")]):_c('v-icon',[_vm._v("fa-chevron-down")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('detail-list-cdps',{staticClass:"ma-3",attrs:{"purchaseBuyerProps":item}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',[_c('button-send-purchase-buyer',{attrs:{"purchaseBuyerProp":item}})],1),(item.state=='creado')?_c('v-col',[_c('button-edit-purchase-buyer',{attrs:{"purchaseRequestProp":item.purchase_request,"purchaseBuyerProp":item}})],1):_vm._e()],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }