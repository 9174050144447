import { __assign } from "tslib";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
    methods: __assign(__assign({}, mapActions({
        refresh: "tabsDocument/getLoadTable",
    })), { refreshTable: function () {
            var dataRefresh = __assign({ user_id: this.getCurrentUserLoggedIn().id, folder_id: this.$route.params.id }, (this.getProfile() === "opartes" && {
                location_id: this.getUserLocation().location_id,
            }));
            this.refresh(dataRefresh);
        } }),
});
