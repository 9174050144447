import Vue from "vue";
import ImgAvatar from "../../perfilUserCard/ImgAvatar.vue";
export default Vue.extend({
    components: { ImgAvatar: ImgAvatar },
    props: {
        user: {
            type: Object,
        },
    },
});
