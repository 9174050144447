import Vue from "vue";
export default Vue.extend({
    props: {
        profile: {
            type: String,
            required: true,
        },
    },
    data: function () {
        return {
            document: [
                {
                    text: 'Comprador',
                    icon: "fas fa-cart-plus",
                    to: { name: "buyer" + this.profile }
                }
            ],
        };
    },
});
