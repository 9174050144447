import { __awaiter, __extends, __generator } from "tslib";
import { BaseService } from './../../base';
var PermissionService = /** @class */ (function (_super) {
    __extends(PermissionService, _super);
    function PermissionService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(PermissionService, "entity", {
        get: function () {
            return 'permission';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PermissionService, "responseName", {
        get: function () {
            return 'permission';
        },
        enumerable: false,
        configurable: true
    });
    PermissionService.getByModuleId = function (moduleId) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_module_id/" + moduleId
                    })];
            });
        });
    };
    return PermissionService;
}(BaseService()));
export { PermissionService };
