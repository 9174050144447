import Vue from "vue";
export default Vue.extend({
    props: {
        changeStep: {
            required: false,
            type: Function,
        },
        firmFile: {
            required: true,
            type: Function,
        },
        errorMessage: {
            required: false,
        }
    },
    data: function () {
        return {
            otp: null,
        };
    },
    methods: {
        validateFirm: function () {
            if (!this.otp) {
                this.displayToast({
                    type: "warning",
                    message: "Por favor ingresar código OTP",
                });
                return;
            }
            this.firmFile(this.otp);
        },
    },
});
