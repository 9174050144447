import { __extends } from "tslib";
import { BaseService } from './../../base';
var CdpArticleService = /** @class */ (function (_super) {
    __extends(CdpArticleService, _super);
    function CdpArticleService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(CdpArticleService, "entity", {
        get: function () {
            return 'cdp_article';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CdpArticleService, "responseName", {
        get: function () {
            return 'cdp_article';
        },
        enumerable: false,
        configurable: true
    });
    return CdpArticleService;
}(BaseService()));
export { CdpArticleService };
