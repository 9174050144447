import { __awaiter, __generator } from "tslib";
import { CdpService } from "@/services/models/purchaseRequest/cdp";
import { CdpArticleService } from "@/services/models/purchaseRequest/cdpArticle";
import { PurchaseBuyerService } from "@/services/models/purchaseRequest/purchaseBuyer";
import { PurchaseRequestService } from "@/services/models/purchaseRequest/purchaseRequest";
import Vue from "vue";
import FormCdp from "./ButtonCreatePurchaseBuyer.vue/cdpForm/FormCdp.vue";
export default Vue.extend({
    components: { FormCdp: FormCdp },
    props: {
        cdpProps: {
            type: Object,
        },
    },
    watch: {
        dialog: function () {
            return __awaiter(this, void 0, void 0, function () {
                var purchaseBuyer, _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (!this.dialog) return [3 /*break*/, 3];
                            return [4 /*yield*/, PurchaseBuyerService.getById(this.cdpProps.purchase_buyer_id)];
                        case 1:
                            purchaseBuyer = _b.sent();
                            _a = this;
                            return [4 /*yield*/, PurchaseRequestService.getById(purchaseBuyer.purchase_request_id || 0)];
                        case 2:
                            _a.dataPurchaseBuyer = _b.sent();
                            _b.label = 3;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
    },
    data: function () {
        return {
            dialog: false,
            dataPurchaseBuyer: {},
        };
    },
    methods: {
        save: function () {
            return __awaiter(this, void 0, void 0, function () {
                var dataCdp, purchase_request_detail, i, x, data, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.displayLoader(true);
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 11, 12, 13]);
                            dataCdp = this.$refs.formCdp.data;
                            purchase_request_detail = dataCdp.cdps_articles;
                            return [4 /*yield*/, CdpService.update(this.cdpProps.id, dataCdp)];
                        case 2:
                            _a.sent();
                            i = 0;
                            _a.label = 3;
                        case 3:
                            if (!(i < this.cdpProps.cdps_articles.length)) return [3 /*break*/, 6];
                            return [4 /*yield*/, CdpArticleService.remove(this.cdpProps.cdps_articles[i].id)];
                        case 4:
                            _a.sent();
                            _a.label = 5;
                        case 5:
                            i++;
                            return [3 /*break*/, 3];
                        case 6:
                            x = 0;
                            _a.label = 7;
                        case 7:
                            if (!(x < purchase_request_detail.length)) return [3 /*break*/, 10];
                            console.log('ahhh', purchase_request_detail[x]);
                            data = {
                                id: 0,
                                cdp_id: this.cdpProps.id,
                                purchase_request_detail_id: purchase_request_detail[x].purchase_request_detail_id,
                                budget_item_id: purchase_request_detail[x].article.budget_item_id || 0,
                                obervation: "",
                            };
                            return [4 /*yield*/, CdpArticleService.create(data)];
                        case 8:
                            _a.sent();
                            _a.label = 9;
                        case 9:
                            x++;
                            return [3 /*break*/, 7];
                        case 10:
                            this.displayToast({
                                type: 'success',
                                message: 'Cdp Modificado'
                            });
                            this.dialog = false;
                            return [3 /*break*/, 13];
                        case 11:
                            error_1 = _a.sent();
                            console.log(error_1);
                            return [3 /*break*/, 13];
                        case 12:
                            this.displayLoader(false);
                            return [7 /*endfinally*/];
                        case 13: return [2 /*return*/];
                    }
                });
            });
        },
    },
});
