import Vue from "vue";
export default Vue.extend({
    props: {
        user: {
            required: true,
            type: Object,
        },
        iconChip: {
            default: "fa-user",
            type: String,
        },
        colorChip: {
            default: null,
            type: String,
        },
    },
    data: function () {
        return {
            menu: false
        };
    }
});
