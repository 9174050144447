import { __awaiter, __generator } from "tslib";
import { BuyerService } from "@/services/models/purchaseRequest/buyer";
var moduleBuyer = {
    namespaced: true,
    state: {
        buyerAssign: [],
        buyerPending: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        buyerReady: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        buyerInProcess: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        loading: false,
    },
    getters: {
        getBuyerAssign: function (state) {
            return state.buyerAssign;
        },
        getBuyerPending: function (state) {
            return state.buyerPending;
        },
        getBuyerReady: function (state) {
            return state.buyerReady;
        },
        getBuyerInProcess: function (state) {
            return state.buyerInProcess;
        },
        getLoading: function (state) {
            return state.loading;
        }
    },
    mutations: {
        setBuyerAssign: function (state, buyerAssign) {
            state.buyerAssign = buyerAssign;
        },
        setBuyerPending: function (state, buyerPending) {
            state.buyerPending = buyerPending;
        },
        setBuyerReady: function (state, buyerReady) {
            state.buyerReady = buyerReady;
        },
        setBuyerInProcess: function (state, buyerInProcess) {
            state.buyerInProcess = buyerInProcess;
        },
        setPageBuyerPending: function (state, page) {
            state.buyerPending.current_page = page;
        },
        setPageBuyerReady: function (state, page) {
            state.buyerReady.current_page = page;
        },
        setPageBuyerInProcess: function (state, page) {
            state.buyerInProcess.current_page = page;
        },
        setLoading: function (state, loading) {
            state.loading = loading;
        }
    },
    actions: {
        getBuyerAssign: function (_a, purchaseRequestId) {
            var commit = _a.commit;
            return __awaiter(this, void 0, Promise, function () {
                var BUYER;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, BuyerService.getFindPurchaseRequestId(purchaseRequestId)];
                        case 1:
                            BUYER = _b.sent();
                            commit("setBuyerAssign", BUYER);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        getBuyerPending: function (_a, payload) {
            var commit = _a.commit, state = _a.state;
            return __awaiter(this, void 0, Promise, function () {
                var BUYER;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, BuyerService.getFindByUserBuyerIdState(payload.userId, payload.state, state.buyerPending.current_page)];
                        case 1:
                            BUYER = _b.sent();
                            commit('setBuyerPending', BUYER);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        getBuyerReady: function (_a, payload) {
            var commit = _a.commit, state = _a.state;
            return __awaiter(this, void 0, Promise, function () {
                var BUYER;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, BuyerService.getFindByUserBuyerIdState(payload.userId, payload.state, state.buyerReady.current_page)];
                        case 1:
                            BUYER = _b.sent();
                            commit('setBuyerReady', BUYER);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        getBuyerInProcess: function (_a, payload) {
            var commit = _a.commit, state = _a.state;
            return __awaiter(this, void 0, Promise, function () {
                var BUYER;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, BuyerService.getFindByUserBuyerIdState(payload.userId, payload.state, state.buyerReady.current_page)];
                        case 1:
                            BUYER = _b.sent();
                            commit('setBuyerInProcess', BUYER);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        }
    }
};
export default moduleBuyer;
