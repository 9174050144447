var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-dialog',{attrs:{"width":"60em"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":""}},Object.assign({}, tooltip, dialog)),[_c('v-icon',[_vm._v(" fa-history ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver historial de movimientos")])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-form',{ref:"formCreate",attrs:{"lazy-validation":""}},[_c('v-card-title',[_vm._v(" Historial de movimientos ")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-5",attrs:{"headers":_vm.headers,"items":_vm.listHistory,"loading":_vm.isLoading,"loading-text":"Cargando...","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.name)+" "+_vm._s(item.user.pather_lastname)+" "+_vm._s(item.user.mother_lastname)+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}}],null,true)})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cerrar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }