import { __assign, __awaiter, __generator } from "tslib";
import { DocumentDetailFirmedService } from "@/services/models/document/documentDetailFirmed";
import { DocumentStateService } from "@/services/models/document/documentState";
import { HistoryService } from "@/services/models/document/history";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
    props: {
        documentId: {
            type: Number,
            required: true,
        },
        documentDetailId: {
            type: Number,
            required: true,
        },
    },
    data: function () {
        return {
            loadingOverlay: false,
            observations: null,
        };
    },
    methods: __assign(__assign({}, mapActions({
        getDocumentsDetail: "documentDetail/getByDocumentId",
    })), { rejectFirm: function () {
            return __awaiter(this, void 0, void 0, function () {
                var documentStates, documentDetailsFirmed, documentDetailFirmed, error_1;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.loadingOverlay = true;
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 9, , 10]);
                            return [4 /*yield*/, DocumentStateService.getByDocumentIdUserId(this.documentId, this.getCurrentUserLoggedIn().id)];
                        case 2:
                            documentStates = _a.sent();
                            if (!documentStates.length) return [3 /*break*/, 4];
                            return [4 /*yield*/, DocumentStateService.update(documentStates[0].id, __assign(__assign({}, documentStates[0]), { state: "rechazado" }))];
                        case 3:
                            _a.sent();
                            _a.label = 4;
                        case 4: return [4 /*yield*/, DocumentDetailFirmedService.getByDocumentDetailId(this.documentDetailId)];
                        case 5:
                            documentDetailsFirmed = _a.sent();
                            documentDetailFirmed = documentDetailsFirmed.find(function (value) { return value.user_id == _this.getCurrentUserLoggedIn().id; });
                            if (!documentDetailFirmed) return [3 /*break*/, 7];
                            return [4 /*yield*/, DocumentDetailFirmedService.update(documentDetailFirmed.id, __assign(__assign({}, documentDetailFirmed), { state: "rechazado" }))];
                        case 6:
                            _a.sent();
                            _a.label = 7;
                        case 7: 
                        //Agregar history
                        return [4 /*yield*/, HistoryService.create({
                                user_origin_id: this.getCurrentUserLoggedIn().id,
                                document_detail_id: this.documentDetailId,
                                state: "rechazado",
                                location_origin_id: this.getUserLocation().location_id,
                                user_destination_id: this.getCurrentUserLoggedIn().id,
                                location_destination_id: this.getUserLocation().location_id,
                            })];
                        case 8:
                            //Agregar history
                            _a.sent();
                            this.displayToast({
                                type: "success",
                                message: "La firma se ha rechazado con éxito",
                            });
                            //Esto va cerrar el modal por que se re-renderiza el componente ModalFirmDocument
                            this.getDocumentsDetail(this.documentId);
                            return [3 /*break*/, 10];
                        case 9:
                            error_1 = _a.sent();
                            console.log(error_1);
                            this.displayToast({
                                type: "error",
                                message: "Lo sentimos ha ocurrido un error",
                            });
                            return [3 /*break*/, 10];
                        case 10:
                            this.loadingOverlay = false;
                            return [2 /*return*/];
                    }
                });
            });
        } }),
});
