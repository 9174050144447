import { __awaiter, __generator } from "tslib";
import { generatePDFSingleTicket } from "@/reports/reportSingleTicket";
import Vue from "vue";
export default Vue.extend({
    props: {
        ticketId: {
            type: Number,
        },
    },
    data: function () {
        return {
            dialog: false,
            isLoading: false,
            dateStart: null,
            dateEnd: null,
        };
    },
    methods: {
        generateReport: function () {
            return __awaiter(this, void 0, void 0, function () {
                var error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.isLoading = true;
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, generatePDFSingleTicket({
                                    ticketId: this.ticketId,
                                })];
                        case 2:
                            _a.sent();
                            return [3 /*break*/, 4];
                        case 3:
                            error_1 = _a.sent();
                            this.displayToast({
                                type: 'error',
                                message: 'Ha ocurrido un error al generar el reporte'
                            });
                            return [3 /*break*/, 4];
                        case 4:
                            this.isLoading = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
});
