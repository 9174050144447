import { __awaiter, __generator } from "tslib";
import { DispatchLocationService } from "@/services/models/dispatch/dispatch_location";
var moduleDispatchLocation = {
    namespaced: true,
    state: {
        dispatch: [],
        loading: false,
    },
    getters: {
        getDispatchLocation: function (state) {
            return state.dispatch;
        },
        getLoading: function (state) {
            return state.loading;
        }
    },
    mutations: {
        setDispatch: function (state, dispatch) {
            state.dispatch = dispatch;
        },
        setLoading: function (state, loading) {
            state.loading = loading;
        }
    },
    actions: {
        getDispatchLocation: function (_a, id) {
            var commit = _a.commit;
            return __awaiter(this, void 0, Promise, function () {
                var dispatch;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, DispatchLocationService.getStablishmentId(id)];
                        case 1:
                            dispatch = _b.sent();
                            commit("setDispatch", dispatch);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        },
    }
};
export default moduleDispatchLocation;
