import { __assign, __awaiter, __generator } from "tslib";
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import { UserService } from "@/services/models/user/user";
import { formatRut } from "@/thirdParty/utils";
export default Vue.extend({
    props: {
        user: {
            type: Object,
            required: true,
        },
        isEdit: {
            type: Boolean,
            default: false,
        },
        avatarFile: {
            type: String,
            required: false
        }
    },
    data: function () {
        return {
            userFile: "",
        };
    },
    computed: __assign({}, mapGetters({
        loadingRole: "roles/getLoading",
        loadingStablishment: "stablishments/getLoading",
        roles: "roles/getRoles",
        stablishments: "stablishments/getStablishments",
    })),
    methods: __assign(__assign({ 
        // eslint-disable-next-line
        handleUpload: function (e) {
            var _this = this;
            console.log("handleUpoad");
            if (e) {
                var reader = new FileReader();
                reader.onload = function (event) {
                    if (event !== null) {
                        // eslint-disable-next-line
                        _this.userFile = event.target.result;
                    }
                };
                reader.readAsDataURL(e);
            }
            else {
                this.userFile = "";
            }
        },
        formatRut: function () {
            this.user.run = formatRut(this.user.run);
        } }, mapMutations({
        chargeStablishment: "stablishments/changeSatblishmentSelected",
    })), { loadStablishment: function () {
            var _this = this;
            var stablishmentSelected = this.stablishments.find(function (stablishment) {
                return stablishment.id == _this.user.stablishment_id;
            });
            this.chargeStablishment(stablishmentSelected);
        },
        validateRutEdit: function (usersFilteredByRut) {
            var _this = this;
            var response = {
                isValid: true,
                messageValidation: "",
            };
            console.log(usersFilteredByRut, this.user.run.split(".").join(""), this.user.id);
            if (usersFilteredByRut.find(function (value) {
                return value.run == _this.user.run.split(".").join("") &&
                    value.id == _this.user.id;
            })) {
                response.isValid = true;
            }
            else {
                response.isValid = false;
                response.messageValidation =
                    "El rut ya se encuentra asociado a una cuenta de usuario";
            }
            console.log(response);
            return response;
        },
        validateEmailEdit: function (usersFilteredByEmail) {
            var _this = this;
            var response = {
                isValid: true,
                messageValidation: "",
            };
            if (usersFilteredByEmail.find(function (value) { return value.email == _this.user.email && value.id == _this.user.id; })) {
                response.isValid = true;
            }
            else {
                response.isValid = false;
                response.messageValidation =
                    "El correo electrónico ya se encuentra asociado a una cuenta de usuario";
            }
            return response;
        },
        validateForm: function () {
            return __awaiter(this, void 0, Promise, function () {
                var messageValidation, isValid, usersFilteredByRut, validation, usersFilteredByEmail, validation;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            messageValidation = "";
                            isValid = this.$refs.myForm.validate();
                            if (!this.user.run) return [3 /*break*/, 2];
                            return [4 /*yield*/, UserService.getByRut(this.user.run.split(".").join(""))];
                        case 1:
                            usersFilteredByRut = _a.sent();
                            if (usersFilteredByRut.length != 0) {
                                if (this.isEdit) {
                                    validation = this.validateRutEdit(usersFilteredByRut);
                                    isValid = validation.isValid;
                                    messageValidation = validation.messageValidation;
                                }
                                else {
                                    isValid = false;
                                    messageValidation =
                                        "El rut ya se encuentra asociado a una cuenta de usuario";
                                }
                            }
                            _a.label = 2;
                        case 2:
                            if (!(this.user.email && isValid)) return [3 /*break*/, 4];
                            return [4 /*yield*/, UserService.getByEmail(this.user.email)];
                        case 3:
                            usersFilteredByEmail = _a.sent();
                            if (usersFilteredByEmail.length != 0) {
                                if (this.isEdit) {
                                    validation = this.validateEmailEdit(usersFilteredByEmail);
                                    isValid = validation.isValid;
                                    messageValidation = validation.messageValidation;
                                }
                                else {
                                    isValid = false;
                                    messageValidation =
                                        "El correo electrónico ya se encuentra asociado a una cuenta de usuario";
                                }
                            }
                            _a.label = 4;
                        case 4:
                            this.$emit("isValid", isValid);
                            return [2 /*return*/, {
                                    isValid: isValid,
                                    message: messageValidation,
                                }];
                    }
                });
            });
        } }),
    watch: {
        stablishments: function () {
            if (this.stablishments.length) {
                this.user.stablishment_id = this.stablishments[0].id;
                this.loadStablishment();
            }
        },
        "user.avatar": function () {
            console.log('change');
            console.log(this.avatarFile);
            if (this.avatarFile) {
                this.userFile = this.avatarFile;
            }
        }
    },
});
