import { __assign, __awaiter, __generator } from "tslib";
import { generatePDFTickets } from "@/reports/reportTickets";
import { TicketService } from "@/services/models/ticket/ticket";
import moment from "moment";
import Vue from "vue";
export default Vue.extend({
    props: {
        isUser: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            dialog: false,
            isLoading: false,
            dateStart: null,
            dateEnd: null,
        };
    },
    methods: {
        generateReport: function () {
            return __awaiter(this, void 0, void 0, function () {
                var start, end, tickets;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!this.dateStart && !this.dateEnd) {
                                this.displayToast({
                                    type: "warning",
                                    message: "Por favor seleccionar fecha de inicio y fecha de término",
                                });
                                return [2 /*return*/];
                            }
                            start = moment(this.dateStart).format("YYYY-MM-DD");
                            end = moment(this.dateEnd).format("YYYY-MM-DD");
                            this.isLoading = true;
                            if (!this.isUser) return [3 /*break*/, 2];
                            return [4 /*yield*/, TicketService.getByDateAndUser(start + " 00:00:00", end + " 23:59:59", this.getCurrentUserLoggedIn().id)];
                        case 1:
                            tickets = _a.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, TicketService.getByDateAndGroup(start + " 00:00:00", end + " 23:59:59", 1)];
                        case 3:
                            tickets = _a.sent();
                            _a.label = 4;
                        case 4:
                            generatePDFTickets(__assign({ tickets: tickets, dateStart: start, dateEnd: end, user: this.getCurrentUserLoggedIn() }, (!this.isUser && { userGroup: this.getUserGroup() })));
                            this.isLoading = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
});
