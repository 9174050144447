import { __awaiter, __extends, __generator } from "tslib";
import { BaseService } from '../../base';
import Axios from "axios";
var UserService = /** @class */ (function (_super) {
    __extends(UserService, _super);
    function UserService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(UserService, "entity", {
        get: function () {
            return 'user';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserService, "responseName", {
        get: function () {
            return 'user';
        },
        enumerable: false,
        configurable: true
    });
    UserService.login = function (data) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, Axios.post(process.env.VUE_APP_API_URL + "/api/auth", data)];
            });
        });
    };
    UserService.getByState = function (state) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_state/" + state
                    })];
            });
        });
    };
    UserService.getByRut = function (rut) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_rut/" + rut
                    })];
            });
        });
    };
    UserService.getByEmail = function (email) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_email/" + email
                    })];
            });
        });
    };
    UserService.getByStablishment = function (stablishment_id) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_stablishment_id/" + stablishment_id,
                    })];
            });
        });
    };
    UserService.getBystateAndStablishment = function (state, stablishment_id) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_state_stablishment_id/" + state + "/" + stablishment_id,
                    })];
            });
        });
    };
    UserService.getChangePasswd = function (id, data) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'put',
                        path: "change_passwd/" + id,
                        data: data
                    })];
            });
        });
    };
    UserService.resetPassword = function (id, data) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'put',
                        path: "new_passwd/" + id,
                        data: data
                    })];
            });
        });
    };
    UserService.updateAvatar = function (id, data) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'post',
                        path: "update_avatar/" + id,
                        data: data,
                        config: { "Content-Type": "multipart/form-data", }
                    })];
            });
        });
    };
    UserService.getTokenKey = function (run) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "get_token_key_v2/" + run,
                        showFullResponse: true
                    })];
            });
        });
    };
    return UserService;
}(BaseService()));
export { UserService };
