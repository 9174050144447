import { __assign } from "tslib";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import ShowObservationHistory from "./ShowObservationHistory.vue";
export default Vue.extend({
    components: { ShowObservationHistory: ShowObservationHistory },
    props: {
        detailProp: {
            type: Object,
        },
    },
    data: function () {
        return {
            headers: [
                { text: "Origen", align: "start", value: "user_origin_id" },
                { text: "Destino", align: "start", value: "user_destination_id" },
                { text: "Estado", align: "start", value: "state" },
                { text: "Fecha", align: "start", value: "created_at" },
                { text: "Observación", align: "start", value: "description" },
            ],
        };
    },
    mounted: function () {
        this.getHistories(this.detailProp.id);
    },
    computed: __assign({}, mapGetters({
        histories: "history/getDocumentDetailId",
        loading: "history/getLoading",
    })),
    methods: __assign({}, mapActions({
        getHistories: "history/getDocumentDetailId",
    })),
});
