import { __extends } from "tslib";
import { BaseService } from './../../base';
var ArticleService = /** @class */ (function (_super) {
    __extends(ArticleService, _super);
    function ArticleService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ArticleService, "entity", {
        get: function () {
            return 'article';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ArticleService, "responseName", {
        get: function () {
            return 'article';
        },
        enumerable: false,
        configurable: true
    });
    return ArticleService;
}(BaseService()));
export { ArticleService };
