import { __awaiter, __generator, __spreadArrays } from "tslib";
import { listTemplates } from "@/others/templateDocuments/listTemplates";
import Editor from "@tinymce/tinymce-vue";
import VueHtml2pdf from "vue-html2pdf";
import store from "@/store";
import Vue from "vue";
import { DocumentDetailService } from "@/services/models/document/documentDetail";
export default Vue.extend({
    name: "VisorEditable",
    props: {
        contentProp: {
            type: Object,
        },
    },
    components: {
        Editor: Editor,
        VueHtml2pdf: VueHtml2pdf,
    },
    mounted: function () {
        return __awaiter(this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.contentProp) return [3 /*break*/, 2];
                        console.log(this.$refs.editorTiny);
                        return [4 /*yield*/, DocumentDetailService.getById(this.contentProp.id)];
                    case 1:
                        data = _a.sent();
                        this.content = data.file_generated;
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    },
    data: function () {
        return {
            content: "",
            configurationTiny: {
                init_instance_callback: function () {
                    store.commit("loader/setLoader", false);
                },
                height: 600,
                menubar: true,
                language: "es",
                readonly: false,
                plugins: [
                    "lists print preview anchor",
                    "searchreplace visualblocks",
                    "table paste",
                    "template",
                    "pagebreak",
                ],
                toolbar: "undo redo | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | fontsizeselect \
           bullist numlist outdent indent | pagebreak | template",
                templates: __spreadArrays(listTemplates),
            },
        };
    },
});
