import { __awaiter, __generator } from "tslib";
import { DocumentDetailService } from "@/services/models/document/documentDetail";
var moduleDocumentDetail = {
    namespaced: true,
    state: {
        documentDetail: [],
        documentDetailByDocumentId: [],
        loading: false,
    },
    getters: {
        getDocuments: function (state) {
            return state.documentDetail;
        },
        getByDocumentId: function (state) {
            return state.documentDetailByDocumentId;
        },
        getByDocumentIdByUser: function (state) {
            return state.documentDetailByDocumentId;
        },
        getLoading: function (state) {
            return state.loading;
        }
    },
    mutations: {
        setDocument: function (state, documentDetail) {
            state.documentDetail = documentDetail;
        },
        setByDocumentId: function (state, documentDetailByDocumentId) {
            state.documentDetailByDocumentId = documentDetailByDocumentId;
        },
        setByDocumentIdByUser: function (state, documentDetailByDocumentId) {
            state.documentDetailByDocumentId = documentDetailByDocumentId;
        },
        setLoading: function (state, loading) {
            state.loading = loading;
        }
    },
    actions: {
        getDocumentDetails: function (_a) {
            var commit = _a.commit;
            return __awaiter(this, void 0, Promise, function () {
                var documentDetail;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, DocumentDetailService.getAll()];
                        case 1:
                            documentDetail = _b.sent();
                            commit("setDocument", documentDetail);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        getByDocumentId: function (_a, id) {
            var commit = _a.commit;
            return __awaiter(this, void 0, Promise, function () {
                var documentDetail;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, DocumentDetailService.getByDocumentId(id)];
                        case 1:
                            documentDetail = _b.sent();
                            commit("setByDocumentId", documentDetail);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        getByDocumentIdByUser: function (_a, id) {
            var commit = _a.commit;
            return __awaiter(this, void 0, Promise, function () {
                var documentDetail;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, DocumentDetailService.getByDocumentIdByUser(id)];
                        case 1:
                            documentDetail = _b.sent();
                            commit("setByDocumentIdByUser", documentDetail);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        }
    }
};
export default moduleDocumentDetail;
