import { __assign, __awaiter, __generator, __spreadArrays } from "tslib";
import { SELECT_USER_STABLISHMENT } from "@/others/constants/permissions";
import { StablishmentService } from "@/services/models/stablishment";
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import CreateUser from "./createUser/ModalCreateUser.vue";
import ModalEditUser from "./editUser/modalEditUser.vue";
import ModalResetPassword from "./resetPassword/ModalResetPassword.vue";
import UserCard from "./UserCard.vue";
export default Vue.extend({
    components: {
        CreateUser: CreateUser,
        UserCard: UserCard,
        ModalEditUser: ModalEditUser,
        ModalResetPassword: ModalResetPassword,
    },
    data: function () {
        return {
            search: "",
            stablishment_id: 0,
            stablishments: [],
            loadingStablishments: false,
            selectStablishmentPermission: SELECT_USER_STABLISHMENT,
            headers: [
                {
                    text: "ID",
                    sortable: false,
                    value: "id",
                },
                {
                    text: "RUT",
                    sortable: false,
                    value: "run",
                },
                {
                    text: "Nombre",
                    sortable: false,
                    value: "completeName",
                },
                {
                    text: "Correo",
                    sortable: false,
                    value: "email",
                },
                {
                    text: "Acciones",
                    sortable: false,
                    value: "actions",
                },
            ],
        };
    },
    mounted: function () {
        this.getUsers();
        this.loadStablishments();
    },
    computed: __assign({}, mapGetters({
        users: "users/getUsers",
        loading: "users/getLoading",
        permissions: "userSession/getPermissions",
    })),
    methods: __assign(__assign(__assign({}, mapMutations({
        setStablishmentId: "users/setStablishmentIdSelected",
    })), mapActions({
        getUsers: "users/getUsers",
    })), { loadUsers: function () {
            this.setStablishmentId(this.stablishment_id);
            this.getUsers();
        },
        loadStablishments: function () {
            return __awaiter(this, void 0, void 0, function () {
                var stablishmentsResponse, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.loadingStablishments = true;
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            this.stablishments.push({
                                id: 0,
                                name: "Todos",
                            });
                            return [4 /*yield*/, StablishmentService.getAll()];
                        case 2:
                            stablishmentsResponse = _a.sent();
                            this.stablishments = __spreadArrays(this.stablishments, stablishmentsResponse);
                            return [3 /*break*/, 4];
                        case 3:
                            error_1 = _a.sent();
                            console.log(error_1);
                            return [3 /*break*/, 4];
                        case 4:
                            this.loadingStablishments = false;
                            return [2 /*return*/];
                    }
                });
            });
        } }),
});
