import { __awaiter, __generator } from "tslib";
import { TicketService } from "@/services/models/ticket/ticket";
var moduleTickets = {
    namespaced: true,
    state: {
        Ticket: [],
        ticketUser: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        ticketGroup: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        ticketFilter: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        dataFilterTicket: {
            id: null,
            responsable_id: null,
            user_id: null,
            state: null
        },
        loading: false,
    },
    getters: {
        getTickets: function (state) {
            return state.Ticket;
        },
        getTicketsUser: function (state) {
            return state.ticketUser;
        },
        getTicketGroup: function (state) {
            return state.ticketGroup;
        },
        getTicketFilter: function (state) {
            return state.ticketFilter;
        },
        getLoading: function (state) {
            return state.loading;
        },
    },
    mutations: {
        setTickets: function (state, Ticket) {
            state.Ticket = Ticket;
        },
        setTicketUser: function (state, ticketUser) {
            state.ticketUser = ticketUser;
        },
        setPageTicketUser: function (state, page) {
            state.ticketUser.current_page = page;
        },
        setTicketGroup: function (state, ticketGroup) {
            state.ticketGroup = ticketGroup;
        },
        setPageTicketGroup: function (state, page) {
            state.ticketGroup.current_page = page;
        },
        setTickeFilter: function (state, ticketFilter) {
            state.ticketFilter = ticketFilter;
        },
        setPageTicketFilter: function (state, page) {
            state.ticketFilter.current_page = page;
        },
        setDataFilterTicket: function (state, dataFilter) {
            state.dataFilterTicket = dataFilter;
        },
        setLoading: function (state, loading) {
            state.loading = loading;
        },
    },
    actions: {
        getTickets: function (_a) {
            var commit = _a.commit;
            return __awaiter(this, void 0, Promise, function () {
                var TICKET;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, TicketService.getAll()];
                        case 1:
                            TICKET = _b.sent();
                            commit("setTickets", TICKET);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        getTicketsUser: function (_a, user_id) {
            var commit = _a.commit, state = _a.state;
            return __awaiter(this, void 0, Promise, function () {
                var TICKET;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, TicketService.getByUserId(user_id, state.ticketUser.current_page)];
                        case 1:
                            TICKET = _b.sent();
                            commit("setTicketUser", TICKET);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        getTicketGroup: function (_a, group_id) {
            var commit = _a.commit, state = _a.state;
            return __awaiter(this, void 0, Promise, function () {
                var TICKET;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, TicketService.getByGroupId(group_id, state.ticketGroup.current_page)];
                        case 1:
                            TICKET = _b.sent();
                            commit("setTicketGroup", TICKET);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        getTicketFilter: function (_a) {
            var commit = _a.commit, state = _a.state;
            return __awaiter(this, void 0, Promise, function () {
                var TICKET;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, TicketService.getByTicketFilter(state.dataFilterTicket, state.ticketFilter.current_page)];
                        case 1:
                            TICKET = _b.sent();
                            commit("setTickeFilter", TICKET);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        }
    }
};
export default moduleTickets;
