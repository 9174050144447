import { __awaiter, __generator } from "tslib";
import { DispatchLocationService } from "@/services/models/dispatch/dispatch_location";
import { DocumentTypeService } from "@/services/models/document/document_type";
import Vue from "vue";
export default Vue.extend({
    data: function () {
        return {
            dispatch: {
                number: "",
                description: "",
                dispatch_location_id: 0,
                document_type_id: 0,
                state: "creado",
                stablishment_id: this.getCurrentUserLoggedIn().stablishment_id,
            },
            documentsTypes: [],
            locations: [],
        };
    },
    mounted: function () {
        this.loadData();
    },
    methods: {
        loadData: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            this.displayLoader(true);
                            _a = this;
                            return [4 /*yield*/, DocumentTypeService.getAll()];
                        case 1:
                            _a.documentsTypes = (_c.sent());
                            _b = this;
                            return [4 /*yield*/, DispatchLocationService.getAll()];
                        case 2:
                            _b.locations = (_c.sent());
                            this.displayLoader(false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        returnRef: function () {
            return this.$refs.formCreate.validate();
        }
    },
});
