import { __assign, __awaiter, __generator } from "tslib";
import { UserService } from "@/services/models/user/user";
var moduleUser = {
    namespaced: true,
    state: {
        users: [],
        usersFilterByState: [],
        usersFilterByStateAndStablishment: [],
        user: {},
        loading: false,
        stablishmentIdSelected: 0
    },
    getters: {
        getUser: function (state) {
            return state.user;
        },
        getUsers: function (state) {
            return state.users;
        },
        getUsersFilterByState: function (state) {
            return state.usersFilterByState;
        },
        getLoading: function (state) {
            return state.loading;
        },
        getUsersFilterByStateAndStablishment: function (state) {
            return state.usersFilterByStateAndStablishment;
        },
    },
    mutations: {
        loadUsers: function (state, users) {
            state.users = users;
        },
        changeLoading: function (state, loading) {
            state.loading = loading;
        },
        setUser: function (state, user) {
            state.user = user;
        },
        setStablishmentIdSelected: function (state, stablishmentId) {
            state.stablishmentIdSelected = stablishmentId;
        },
        setUsersFilterByState: function (state, user) {
            state.usersFilterByState = user;
        },
        setUsersFilterByStateAndStablishment: function (state, user) {
            state.usersFilterByStateAndStablishment = user;
        },
    },
    actions: {
        getUsers: function (_a) {
            var commit = _a.commit, state = _a.state;
            return __awaiter(this, void 0, Promise, function () {
                var users;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit("changeLoading", true);
                            if (!state.stablishmentIdSelected) return [3 /*break*/, 2];
                            console.log('users by stablishment');
                            return [4 /*yield*/, UserService.getByStablishment(state.stablishmentIdSelected)];
                        case 1:
                            users = _b.sent();
                            return [3 /*break*/, 4];
                        case 2:
                            console.log('all users');
                            return [4 /*yield*/, UserService.getAll()];
                        case 3:
                            users = _b.sent();
                            _b.label = 4;
                        case 4:
                            users = users.map(function (user) { return (__assign(__assign({}, user), { completeName: user.name + " " + user.pather_lastname + " " + user.mother_lastname })); });
                            commit("loadUsers", users);
                            commit("changeLoading", false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        getUser: function (_a, id) {
            var commit = _a.commit;
            return __awaiter(this, void 0, Promise, function () {
                var user;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit("changeLoading", true);
                            return [4 /*yield*/, UserService.getById(id)];
                        case 1:
                            user = _b.sent();
                            commit("setUser", user);
                            commit("changeLoading", false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        getUsersFilterByState: function (_a, stateUser) {
            var commit = _a.commit;
            return __awaiter(this, void 0, Promise, function () {
                var stablishment_id, user;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit("changeLoading", true);
                            stablishment_id = JSON.parse(window.localStorage.getItem("user") || "{}").stablishment_id;
                            return [4 /*yield*/, UserService.getBystateAndStablishment(stateUser, stablishment_id)];
                        case 1:
                            user = _b.sent();
                            commit("setUsersFilterByState", user);
                            commit("changeLoading", false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        getUsersFilterByStateAndStablishment: function (_a, payLoad) {
            var commit = _a.commit;
            return __awaiter(this, void 0, Promise, function () {
                var user, user, user;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit("changeLoading", true);
                            if (!(payLoad.stablishment_id == 0)) return [3 /*break*/, 2];
                            console.log('aqui');
                            return [4 /*yield*/, UserService.getByState(payLoad.stateUser)];
                        case 1:
                            user = _b.sent();
                            commit("setUsersFilterByStateAndStablishment", user);
                            commit("changeLoading", false);
                            return [3 /*break*/, 6];
                        case 2:
                            if (!(payLoad.stablishment_id > 0)) return [3 /*break*/, 4];
                            console.log('aqui1');
                            return [4 /*yield*/, UserService.getBystateAndStablishment(payLoad.stateUser, payLoad.stablishment_id)];
                        case 3:
                            user = _b.sent();
                            commit("setUsersFilterByStateAndStablishment", user);
                            commit("changeLoading", false);
                            return [3 /*break*/, 6];
                        case 4:
                            console.log('aqui2');
                            return [4 /*yield*/, UserService.getBystateAndStablishment(payLoad.stateUser, JSON.parse(window.localStorage.getItem("user") || "{}").stablishment_id)];
                        case 5:
                            user = _b.sent();
                            commit("setUsersFilterByStateAndStablishment", user);
                            commit("changeLoading", false);
                            _b.label = 6;
                        case 6: return [2 /*return*/];
                    }
                });
            });
        }
    }
};
export default moduleUser;
