import TableFinancialManagerPending from "@/components/purchaseRequest/tables/tablesFinancialManager/TableFinancialManagerPending.vue";
import TableFinancialManagerReady from "@/components/purchaseRequest/tables/tablesFinancialManager/TableFinancialManagerReady.vue";
import TemplateAdministrator from "@/templates/TemplateAdministrator.vue";
import Vue from "vue";
export default Vue.extend({
    components: {
        TemplateAdministrator: TemplateAdministrator,
        TableFinancialManagerPending: TableFinancialManagerPending,
        TableFinancialManagerReady: TableFinancialManagerReady,
    },
    data: function () {
        return {
            tab: null,
        };
    },
});
