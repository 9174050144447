import { __assign } from "tslib";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import DetailTablePurchaseRequest from "../DetailTablePurchaseRequest.vue";
export default Vue.extend({
    components: {
        DetailTablePurchaseRequest: DetailTablePurchaseRequest
    },
    data: function () {
        return {
            page: 1,
            headers: [
                {
                    text: "Id Solicitud",
                    sortable: false,
                    value: "purchase_request_id",
                },
                {
                    text: 'Id del Registro',
                    sortable: false,
                    value: 'id'
                },
                {
                    text: "Persona que lo asigno",
                    sortable: false,
                    value: "user_id",
                },
                {
                    text: "Estado",
                    sortable: false,
                    value: "state",
                },
            ],
        };
    },
    computed: __assign({}, mapGetters({
        getBuyers: "buyer/getBuyerReady",
        getLoading: "buyer/getLoading",
    })),
    methods: __assign(__assign({}, mapActions({
        setBuyer: "buyer/getBuyerReady",
    })), { changePage: function () {
            this.$store.commit("buyer/setPageBuyerReady", this.page);
            this.setBuyer({
                userId: this.getCurrentUserLoggedIn().id,
                state: "finalizado",
            });
        } }),
    mounted: function () {
        this.changePage();
    },
});
