import Vue from "vue";
import FormDispatch from "./FormDispatch.vue";
import FormFiles from "../../general/FormFiles.vue";
export default Vue.extend({
    name: "TabCreateDispatch",
    components: {
        FormDispatch: FormDispatch,
        FormFiles: FormFiles
    },
    data: function () {
        return {
            tab: null,
        };
    },
});
