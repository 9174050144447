import { __extends } from "tslib";
import { BaseService } from './../../base';
var ProviderService = /** @class */ (function (_super) {
    __extends(ProviderService, _super);
    function ProviderService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ProviderService, "entity", {
        get: function () {
            return 'provider';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProviderService, "responseName", {
        get: function () {
            return 'provider';
        },
        enumerable: false,
        configurable: true
    });
    return ProviderService;
}(BaseService()));
export { ProviderService };
