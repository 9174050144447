export var CREATE_TICKET_MODULE = 1;
export var TICKETS_MODULE = 2;
export var REPORT_TICKETS_MODULE = 3;
export var USER_MAINTAINER_MODULE = 4;
export var GROUP_MAINTAINER_MODULE = 5;
//MODULO DOCUMENTOS
export var DOCUMENTS_MODULE = 6;
//MODULOS DE SOLICITUDES DE COMPRA
export var PURCHASE_REQUEST_MODULE = 7;
export var PURCHASE_AUTHORIZER_MODULE = 8;
export var SUPPLY_MANAGER_MODULE = 9;
export var UNITY_MANAGER_MODULE = 10;
export var BUYER_MODULE = 11;
export var BUDGET_MANAGER_MODULE = 12;
export var BUDGET_MODULE = 13;
export var FINANCIAL_MANAGER_MODULE = 14;
export var modulesAdministrative = {
    createTicketModule: CREATE_TICKET_MODULE,
    ticketsModule: TICKETS_MODULE,
    reportTicketsModule: REPORT_TICKETS_MODULE,
    userMaintainerModule: USER_MAINTAINER_MODULE,
    groupMaintainerModule: GROUP_MAINTAINER_MODULE,
    //MODULO DOCUMENTOS
    documentModule: DOCUMENTS_MODULE,
    //MODULOS DE SOLICITUDES DE COMPRA
    purchaseRequestModule: PURCHASE_REQUEST_MODULE,
    purchaseAuthorizerModule: PURCHASE_AUTHORIZER_MODULE,
    supplyManagerModule: SUPPLY_MANAGER_MODULE,
    unityManagerModule: UNITY_MANAGER_MODULE,
    buyerModule: BUYER_MODULE,
    budgetManagerModule: BUDGET_MANAGER_MODULE,
    budgetModule: BUDGET_MODULE,
    financialManagerModule: FINANCIAL_MANAGER_MODULE
};
export var modulesAdministrator = {
    createTicketModule: CREATE_TICKET_MODULE,
    ticketsModule: TICKETS_MODULE,
    reportTicketsModule: REPORT_TICKETS_MODULE,
    userMaintainerModule: USER_MAINTAINER_MODULE,
    groupMaintainerModule: GROUP_MAINTAINER_MODULE,
    //MODULO DOCUMENTOS
    documentModule: DOCUMENTS_MODULE,
    //MODULOS DE SOLICITUDES DE COMPRA
    purchaseRequestModule: PURCHASE_REQUEST_MODULE,
    purchaseAuthorizerModule: PURCHASE_AUTHORIZER_MODULE,
    supplyManagerModule: SUPPLY_MANAGER_MODULE,
    unityManagerModule: UNITY_MANAGER_MODULE,
    buyerModule: BUYER_MODULE,
    budgetManagerModule: BUDGET_MANAGER_MODULE,
    budgetModule: BUDGET_MODULE,
    financialManagerModule: FINANCIAL_MANAGER_MODULE
};
