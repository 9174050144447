import { __awaiter, __extends, __generator } from "tslib";
import { BaseService } from '../../base';
var DocumentDetailChangesService = /** @class */ (function (_super) {
    __extends(DocumentDetailChangesService, _super);
    function DocumentDetailChangesService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(DocumentDetailChangesService, "entity", {
        get: function () {
            return 'document_detail_change';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DocumentDetailChangesService, "responseName", {
        get: function () {
            return 'document_detail_change';
        },
        enumerable: false,
        configurable: true
    });
    DocumentDetailChangesService.getByDocumentDetail = function (document_detail_id) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_document_detail/" + document_detail_id
                    })];
            });
        });
    };
    return DocumentDetailChangesService;
}(BaseService()));
export { DocumentDetailChangesService };
