var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-card',{staticClass:"ma-4",attrs:{"elevation":"6"}},[_c('v-card-text',[_c('b',[_vm._v("Descripción: ")]),_vm._v(" "+_vm._s(_vm.itemProp.subject)+" "),_c('br'),_c('b',[_vm._v("Generó el Ticket:")]),_vm._v(" "+_vm._s(_vm.itemProp.created_by.name)+" "+_vm._s(_vm.itemProp.created_by.pather_lastname)+" ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-card',{staticClass:"ma-5",attrs:{"elevation":"6"}},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headersFiles,"items":_vm.listFiles,"loading":_vm.isLoading,"dense":"","items-per-page":5},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Adjuntos")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('button-add-file-to-ticket',{attrs:{"ticketId":_vm.itemProp.id,"loadTickets":_vm.loadTickets}})],1)]},proxy:true},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_vm._v(" "+_vm._s(_vm.showUserName(item.user))+" ")])]}},{key:"item.file",fn:function(ref){
var item = ref.item;
return [_c('v-row',[(item.file_name)?_c('v-btn',{attrs:{"color":"primary","icon":"","href":_vm.getUrlFile(item.file_name),"target":"_blank"}},[_c('v-icon',[_vm._v("fas fa-file")])],1):_vm._e()],1)]}}],null,true)})],1)],1)],1),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"ma-5",attrs:{"elevation":"6"}},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headersResponsables,"items":_vm.listResponsables,"loading":_vm.isLoading,"items-per-page":5,"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Responsables")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"item.fullname",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.name)+" "+_vm._s(item.user.pather_lastname)+" "+_vm._s(item.user.mother_lastname)+" ")]}},{key:"item.observation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.observation?item.observation:'Sin observación')+" ")]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }