import { __awaiter, __extends, __generator } from "tslib";
import { BaseService } from '../../base';
var PurchaseHistoryService = /** @class */ (function (_super) {
    __extends(PurchaseHistoryService, _super);
    function PurchaseHistoryService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(PurchaseHistoryService, "entity", {
        get: function () {
            return 'purchase_request_history';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PurchaseHistoryService, "responseName", {
        get: function () {
            return 'purchase_request_history';
        },
        enumerable: false,
        configurable: true
    });
    PurchaseHistoryService.getByPurchaseId = function (id) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_purchase_request/" + id
                    })];
            });
        });
    };
    return PurchaseHistoryService;
}(BaseService()));
export { PurchaseHistoryService };
