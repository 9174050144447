import { __awaiter, __generator } from "tslib";
import store from "./../../store";
var moduleTabsDocuments = {
    namespaced: true,
    state: {
        tabName: 'tab-request',
    },
    getters: {
        getTabNumber: function (state) {
            return state.tabName;
        },
    },
    mutations: {
        setTabNumber: function (state, tabName) {
            state.tabName = tabName;
        },
    },
    actions: {
        getLoadTable: function (_a, payload) {
            var state = _a.state;
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_b) {
                    if (state.tabName == "tab-request") {
                        store.dispatch('documents/getRequestDocument', { id: payload.user_id, location_id: payload.location_id });
                    }
                    if (state.tabName == "tab-myDocument") {
                        store.dispatch('documents/getMyDocument', { id: payload.user_id, location_id: payload.location_id });
                    }
                    if (state.tabName == "tab-firm") {
                        store.dispatch('documents/getByFirmedDocument', payload.user_id);
                        store.dispatch('documents/getFirmedDocument', payload.user_id);
                        store.dispatch('documents/getRejectDocument', payload.user_id);
                    }
                    if (state.tabName == "tab-check") {
                        store.dispatch('documents/getByCheckedDocument', payload.user_id);
                        store.dispatch('documents/getCheckedDocument', payload.user_id);
                        store.dispatch('documents/getNotRelevantDocument', payload.user_id);
                    }
                    if (state.tabName == "tab-folder") {
                        store.dispatch('documents/getFolderDocument', payload);
                    }
                    return [2 /*return*/];
                });
            });
        }
    }
};
export default moduleTabsDocuments;
