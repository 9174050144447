import { __assign } from "tslib";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import ButtonHistory from "./ButtonHistory.vue";
import ButtonReOpen from "./ButtonReOpen.vue";
import ModalChatTicket from "./chat/ModalChatTicket.vue";
import ButtonClosedTicket from "./closedTicket/ButtonClosedTicket.vue";
import ButtonShowClosedTicket from "./closedTicket/ButtonShowClosedTicket.vue";
import DetailTableRequestTicket from "./DetailTableRequestTicket.vue";
export default Vue.extend({
    components: { DetailTableRequestTicket: DetailTableRequestTicket, ButtonHistory: ButtonHistory, ButtonReOpen: ButtonReOpen, ModalChatTicket: ModalChatTicket, ButtonClosedTicket: ButtonClosedTicket, ButtonShowClosedTicket: ButtonShowClosedTicket },
    data: function () {
        return {
            page: 1,
            headers: [
                {
                    text: "ID",
                    align: "start",
                    value: "id",
                    sortable: false,
                    width: 50,
                },
                { text: "Titulo", sortable: false, align: "start", value: "title" },
                {
                    text: "Responsable",
                    sortable: false,
                    align: false,
                    value: "responsable",
                },
                {
                    text: "Generó el ticket",
                    sortable: false,
                    align: "start",
                    value: "created_by",
                },
                {
                    text: "Estado",
                    sortable: false,
                    align: "start",
                    value: "state",
                },
                {
                    text: 'Acciones',
                    sortable: false,
                    align: "start",
                    value: "actions"
                }
            ],
        };
    },
    computed: __assign({}, mapGetters({
        getTickets: "ticket/getTicketsUser",
        getLoading: "ticket/getLoading",
    })),
    mounted: function () {
        this.changePage();
    },
    methods: __assign(__assign({}, mapActions({
        setTickets: "ticket/getTicketsUser",
    })), { getResponsable: function (item) {
            var _a, _b, _c, _d, _e, _f, _g;
            var returnResponsable = "Sin responsable";
            if (((_a = item.tickets_responsables) === null || _a === void 0 ? void 0 : _a.length) != 0) {
                var responsableName = item.tickets_responsables
                    ? (_c = (_b = item.tickets_responsables) === null || _b === void 0 ? void 0 : _b[0].user) === null || _c === void 0 ? void 0 : _c.name : "";
                var responsablePatherLastname = item.tickets_responsables
                    ? (_e = (_d = item.tickets_responsables) === null || _d === void 0 ? void 0 : _d[0].user) === null || _e === void 0 ? void 0 : _e.pather_lastname : "";
                var responsableMotherLastname = item.tickets_responsables
                    ? (_g = (_f = item.tickets_responsables) === null || _f === void 0 ? void 0 : _f[0].user) === null || _g === void 0 ? void 0 : _g.mother_lastname : "";
                returnResponsable = responsableName + " " + responsablePatherLastname + " " + responsableMotherLastname;
            }
            return returnResponsable;
        },
        changePage: function () {
            this.$store.commit("ticket/setPageTicketUser", this.page);
            this.setTickets(this.getCurrentUserLoggedIn().id);
        },
        getColor: function (state) {
            if (state == "cerrado")
                return "success";
            else if (state == "en proceso")
                return "warning";
            else if (state == "cerrado permanentemente")
                return "error";
            else
                return "primary";
        } }),
});
