import { __awaiter, __generator } from "tslib";
import { UserService } from "@/services/models/user/user";
import Vue from "vue";
export default Vue.extend({
    props: {
        userProp: {
            required: true,
            type: Object,
        },
    },
    data: function () {
        return {
            password: "",
            passwordConfirmation: "",
            loading: false,
        };
    },
    methods: {
        resetPassword: function () {
            return __awaiter(this, void 0, void 0, function () {
                var isFormValid, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            isFormValid = this.$refs.myForm.validate();
                            if (!isFormValid) {
                                this.displayToast({
                                    type: 'warning',
                                    message: 'Por favor complete los campos necesarios'
                                });
                                return [2 /*return*/];
                            }
                            if (this.password !== this.passwordConfirmation) {
                                this.displayToast({
                                    type: 'warning',
                                    message: 'Las contraseñas no coinciden'
                                });
                                return [2 /*return*/];
                            }
                            this.loading = true;
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, UserService.resetPassword(this.userProp.id, {
                                    password: this.password
                                })];
                        case 2:
                            _a.sent();
                            this.displayToast({
                                type: 'success',
                                message: 'La contraseña se ha cambiado correctamente'
                            });
                            this.$emit('changeDialog', false);
                            return [3 /*break*/, 4];
                        case 3:
                            error_1 = _a.sent();
                            this.displayToast({
                                type: 'error',
                                message: 'Lo sentimos, ha ocurrido un error'
                            });
                            return [3 /*break*/, 4];
                        case 4:
                            this.loading = false;
                            return [2 /*return*/];
                    }
                });
            });
        }
    }
});
