import { __assign, __awaiter, __generator } from "tslib";
import FormFiles from "@/components/general/FormFiles.vue";
import { PurchaseBuyerService } from "@/services/models/purchaseRequest/purchaseBuyer";
import TableCdps from "./cdpForm/TableCdpsSaves.vue";
import FormPurchaseBuyer from "./FormPurchaseBuyer.vue";
import { CdpService } from "@/services/models/purchaseRequest/cdp";
import { CdpArticleService } from "@/services/models/purchaseRequest/cdpArticle";
import Vue from "vue";
import { PurchaseBuyerFileService } from "@/services/models/purchaseRequest/purchaseBuyerFile";
import { PurchaseBuyerHistoryService } from "@/services/models/purchaseRequest/purchaseBuyerHistory";
import { PurchaseBuyerStateService } from "@/services/models/purchaseRequest/purchaseBuyerState";
import { mapActions } from "vuex";
import { generatePdfCdp } from "@/reports/reportCdp";
import { BuyerService } from "@/services/models/purchaseRequest/buyer";
import { PurchaseHistoryService } from "@/services/models/purchaseRequest/purchaseHistory";
export default Vue.extend({
    components: { FormPurchaseBuyer: FormPurchaseBuyer, FormFiles: FormFiles, TableCdps: TableCdps },
    props: {
        purchaseProp: {
            type: Object,
        },
        buyerProp: {
            type: Object,
        },
    },
    data: function () {
        return {
            dialog: false,
            step: 1,
            invalidStep: 0,
        };
    },
    watch: {
        invalidStep: function () {
            if (this.invalidStep != 0) {
                this.step = this.invalidStep;
            }
        },
    },
    methods: __assign(__assign({}, mapActions({
        setBuyerPending: "buyer/getBuyerPending",
        setBuyerInProcess: "buyer/getBuyerInProcess",
        setBuyerReady: "buyer/getBuyerReady",
        setPurchaseBuyer: "purchaseBuyer/getPurchaseBuyer",
    })), { validateStep: function (isValid, step) {
            if (!isValid) {
                this.invalidStep = step;
            }
        },
        save: function () {
            return __awaiter(this, void 0, void 0, function () {
                var FORMPURCHASEBUYER, TABLECDP, response, cdps, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.displayLoader(true);
                            FORMPURCHASEBUYER = this.$refs.formPurchaseBuyer;
                            TABLECDP = this.$refs.tableCdps;
                            // Se valida que los formularios este con todos los campos correspondientes
                            if (!FORMPURCHASEBUYER.validateForm())
                                return [2 /*return*/, (this.validateStep(false, 1), this.displayLoader(false))];
                            if (!TABLECDP.validateForm())
                                return [2 /*return*/, (this.validateStep(false, 2), this.displayLoader(false))];
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 9, 10, 11]);
                            return [4 /*yield*/, PurchaseBuyerService.create(FORMPURCHASEBUYER.data)];
                        case 2:
                            response = _a.sent();
                            cdps = TABLECDP.data;
                            return [4 /*yield*/, this.postCdp(response, cdps)];
                        case 3:
                            _a.sent();
                            return [4 /*yield*/, this.postPurchaseBuyerFile(response)];
                        case 4:
                            _a.sent();
                            return [4 /*yield*/, this.postPurchaseBuyerHistory(response)];
                        case 5:
                            _a.sent();
                            return [4 /*yield*/, this.postPurchaseBuyerState(response)];
                        case 6:
                            _a.sent();
                            return [4 /*yield*/, this.updateBuyer()];
                        case 7:
                            _a.sent();
                            return [4 /*yield*/, this.postPurchaseHistory(this.purchaseProp, response)];
                        case 8:
                            _a.sent();
                            this.reload();
                            this.displayToast({
                                type: "success",
                                message: "Registro ingresado",
                            });
                            this.dialog = false;
                            return [3 /*break*/, 11];
                        case 9:
                            error_1 = _a.sent();
                            console.log(error_1);
                            return [3 /*break*/, 11];
                        case 10:
                            this.displayLoader(false);
                            return [7 /*endfinally*/];
                        case 11: return [2 /*return*/];
                    }
                });
            });
        },
        postPurchaseBuyerFile: function (purchaseBuyer) {
            return __awaiter(this, void 0, void 0, function () {
                var formFiles, promise, _loop_1, i;
                return __generator(this, function (_a) {
                    formFiles = this.$refs.formFiles;
                    if (formFiles && formFiles.files.length != 0) {
                        promise = [];
                        _loop_1 = function (i) {
                            promise.push(new Promise(function (resolve, reject) {
                                var formData = new FormData();
                                formData.append("purchase_buyer_id", "" + purchaseBuyer.id);
                                formData.append("file", formFiles.files[i]);
                                PurchaseBuyerFileService.create(formData, {
                                    "Content-Type": "multipart/form-data",
                                })
                                    .then(function (response) { return resolve(response); })
                                    .catch(function (error) { return reject(error); });
                            }));
                        };
                        for (i = 0; i < formFiles.files.length; i++) {
                            _loop_1(i);
                        }
                        Promise.all(promise)
                            .then(function () {
                            return;
                        })
                            .catch(function (reason) {
                            console.log(reason);
                        });
                    }
                    return [2 /*return*/];
                });
            });
        },
        postPurchaseBuyerHistory: function (purchaseBuyer) {
            return __awaiter(this, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            data = {
                                id: 0,
                                state: "creado",
                                user_origin_id: this.getCurrentUserLoggedIn().id,
                                user_destination_id: this.getCurrentUserLoggedIn().id,
                                purchase_buyer_id: purchaseBuyer.id,
                            };
                            return [4 /*yield*/, PurchaseBuyerHistoryService.create(data)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        postPurchaseBuyerState: function (purchaseBuyer) {
            return __awaiter(this, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            data = {
                                id: 0,
                                purchase_buyer_id: purchaseBuyer.id,
                                state: "creado",
                                step: "comprador",
                                user_id: this.getCurrentUserLoggedIn().id,
                                user_location_id: this.getUserLocation().location_id,
                            };
                            return [4 /*yield*/, PurchaseBuyerStateService.create(data)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        postCdp: function (purchaseBuyer, cdps) {
            var _a;
            return __awaiter(this, void 0, void 0, function () {
                var i, responseCdp, purchase_request_detail, x, data, base64;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            i = 0;
                            _b.label = 1;
                        case 1:
                            if (!(i < cdps.length)) return [3 /*break*/, 10];
                            return [4 /*yield*/, CdpService.create(__assign(__assign({}, cdps[i]), { purchase_buyer_id: purchaseBuyer.id }))];
                        case 2:
                            responseCdp = _b.sent();
                            purchase_request_detail = cdps[i].cdps_articles;
                            console.log("asd", purchase_request_detail[0].article);
                            x = 0;
                            _b.label = 3;
                        case 3:
                            if (!(x < purchase_request_detail.length)) return [3 /*break*/, 6];
                            console.log(purchase_request_detail[x]);
                            data = {
                                id: 0,
                                cdp_id: responseCdp.id,
                                purchase_request_detail_id: purchase_request_detail[x].id,
                                budget_item_id: ((_a = purchase_request_detail[x].article) === null || _a === void 0 ? void 0 : _a.budget_item_id) || 0,
                                obervation: "",
                            };
                            return [4 /*yield*/, CdpArticleService.create(data)];
                        case 4:
                            _b.sent();
                            _b.label = 5;
                        case 5:
                            x++;
                            return [3 /*break*/, 3];
                        case 6: return [4 /*yield*/, generatePdfCdp(responseCdp, false)];
                        case 7:
                            base64 = _b.sent();
                            return [4 /*yield*/, CdpService.uploadImageCdpFile({
                                    base64: base64,
                                    id: responseCdp.id,
                                    cdp_name: responseCdp.id.toString(),
                                })];
                        case 8:
                            _b.sent();
                            _b.label = 9;
                        case 9:
                            i++;
                            return [3 /*break*/, 1];
                        case 10: return [2 /*return*/];
                    }
                });
            });
        },
        updateBuyer: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(this.buyerProp.state == "pendiente")) return [3 /*break*/, 2];
                            return [4 /*yield*/, BuyerService.update(this.buyerProp.id, __assign(__assign({}, this.buyerProp), { state: "en proceso" }))];
                        case 1:
                            _a.sent();
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        },
        reload: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.setBuyerPending({
                        userId: this.getCurrentUserLoggedIn().id,
                        state: "pendiente",
                    });
                    this.setBuyerInProcess({
                        userId: this.getCurrentUserLoggedIn().id,
                        state: "en proceso",
                    });
                    this.setBuyerReady({
                        userId: this.getCurrentUserLoggedIn().id,
                        state: "finalizado",
                    });
                    this.setPurchaseBuyer(this.purchaseProp.id);
                    return [2 /*return*/];
                });
            });
        },
        postPurchaseHistory: function (purchaseRequest, purchaseBuyer) {
            return __awaiter(this, void 0, void 0, function () {
                var dataPurchaseHistory;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            dataPurchaseHistory = {
                                id: 0,
                                purchase_request_id: purchaseRequest.id,
                                state: "registro de compra ID " + purchaseBuyer.id + " creado",
                                user_origin_id: this.getCurrentUserLoggedIn().id,
                                user_destination_id: this.getCurrentUserLoggedIn().id,
                            };
                            return [4 /*yield*/, PurchaseHistoryService.create(dataPurchaseHistory)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        } }),
});
