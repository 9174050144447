import { __awaiter, __generator } from "tslib";
import Vue from "vue";
import { PurchaseRequestTypeService } from "@/services/models/purchaseRequest/purchaseRequestType";
export default Vue.extend({
    data: function () {
        return {
            purchaseRequestType: [],
            data: {
                user_id: this.getCurrentUserLoggedIn().id,
                purchase_request_type_id: 0,
                description: "",
                estimated_use_date: "",
                state: "creado",
                user_location_id: this.getUserLocation().location_id,
            },
        };
    },
    methods: {
        loadData: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            this.displayLoader(true);
                            _a = this;
                            return [4 /*yield*/, PurchaseRequestTypeService.getAll()];
                        case 1:
                            _a.purchaseRequestType = _b.sent();
                            this.displayLoader(false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        validateForm: function () {
            if (!this.$refs.formPurchase.validate()) {
                return false;
            }
            return true;
        },
    },
    mounted: function () {
        this.loadData();
    },
});
