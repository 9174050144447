import { __assign, __awaiter, __generator } from "tslib";
import { TicketTypeService } from "@/services/models/ticket/ticketType";
import Vue from "vue";
import { mapActions } from "vuex";
import TableTicketType from "./TableTicketType.vue";
export default Vue.extend({
    components: { TableTicketType: TableTicketType },
    props: {
        itemProp: {
            type: Object,
        },
    },
    data: function () {
        return {
            ticketType: {
                name: "",
            },
            dialog: false,
        };
    },
    methods: __assign(__assign({}, mapActions({
        setTicketType: "ticketType/getTicketType",
    })), { save: function () {
            return __awaiter(this, void 0, void 0, function () {
                var dataPost;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.displayLoader(true);
                            dataPost = {
                                id: 0,
                                name: this.ticketType.name,
                                group_id: this.itemProp.id,
                            };
                            return [4 /*yield*/, TicketTypeService.create(dataPost)];
                        case 1:
                            _a.sent();
                            this.setTicketType(this.itemProp.id);
                            this.displayLoader(false);
                            return [2 /*return*/];
                    }
                });
            });
        } }),
});
