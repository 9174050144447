import { __assign, __awaiter, __generator } from "tslib";
import { UserService } from "@/services/models/user/user";
import Vue from "vue";
import { mapActions } from "vuex";
import Avatar from "./Avatar.vue";
export default Vue.extend({
    components: { Avatar: Avatar },
    props: {
        allowEdit: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            isLoading: false,
            dialog: false,
            user: {},
        };
    },
    methods: __assign(__assign({}, mapActions({
        setUser: "users/getUser",
    })), { save: function () {
            return __awaiter(this, void 0, void 0, function () {
                var dataUser, data, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!this.$refs.myForm.validate()) return [3 /*break*/, 6];
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 4, 5, 6]);
                            this.isLoading = true;
                            return [4 /*yield*/, UserService.getById(this.getCurrentUserLoggedIn().id)];
                        case 2:
                            dataUser = _a.sent();
                            data = __assign(__assign({}, dataUser), { name: this.user.name, pather_lastname: this.user.pather_lastname, mother_lastname: this.user.mother_lastname, phone: this.user.phone });
                            return [4 /*yield*/, UserService.update(this.user.id, data)];
                        case 3:
                            _a.sent();
                            this.setUser(this.user.id);
                            this.dialog = false;
                            return [3 /*break*/, 6];
                        case 4:
                            error_1 = _a.sent();
                            console.log(error_1);
                            throw error_1;
                        case 5:
                            this.isLoading = false;
                            return [7 /*endfinally*/];
                        case 6: return [2 /*return*/];
                    }
                });
            });
        } }),
    watch: {
        dialog: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (!this.dialog) return [3 /*break*/, 2];
                            this.cleanForm(this, "myForm");
                            _a = this;
                            return [4 /*yield*/, UserService.getById(this.getCurrentUserLoggedIn().id)];
                        case 1:
                            _a.user = _b.sent();
                            _b.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        },
    },
});
