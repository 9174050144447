import { __awaiter, __generator } from "tslib";
import { RoleService } from "@/services/models/user/role";
var moduleUser = {
    namespaced: true,
    state: {
        roles: [],
        loading: false,
    },
    getters: {
        getRoles: function (state) {
            return state.roles;
        },
        getLoading: function (state) {
            return state.loading;
        }
    },
    mutations: {
        loadRoles: function (state, roles) {
            state.roles = roles;
        },
        changeLoading: function (state, loading) {
            state.loading = loading;
        }
    },
    actions: {
        getRoles: function (_a) {
            var commit = _a.commit;
            return __awaiter(this, void 0, Promise, function () {
                var role;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit("changeLoading", true);
                            return [4 /*yield*/, RoleService.getAll()];
                        case 1:
                            role = _b.sent();
                            commit("loadRoles", role);
                            commit("changeLoading", false);
                            return [2 /*return*/];
                    }
                });
            });
        }
    }
};
export default moduleUser;
