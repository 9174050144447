import { __assign, __awaiter, __generator } from "tslib";
import Vue from "vue";
import { FolderService } from "@/services/models/document/folder";
import { mapActions } from "vuex";
export default Vue.extend({
    data: function () {
        return {
            dialog: false,
            folder: {},
        };
    },
    methods: __assign(__assign({}, mapActions({
        setFolders: "folders/getFolder",
    })), { save: function () {
            return __awaiter(this, void 0, void 0, function () {
                var data, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, 3, 4]);
                            this.displayLoader(true);
                            data = __assign(__assign({}, this.folder), { user_id: this.getCurrentUserLoggedIn().id });
                            return [4 /*yield*/, FolderService.create(data)];
                        case 1:
                            _a.sent();
                            this.dialog = false;
                            this.displayLoader(false);
                            this.displayToast({
                                type: "success",
                                message: "Carpeta generada con éxito",
                            });
                            this.setFolders(this.getCurrentUserLoggedIn().id);
                            return [3 /*break*/, 4];
                        case 2:
                            error_1 = _a.sent();
                            console.log(error_1);
                            return [3 /*break*/, 4];
                        case 3:
                            this.dialog = false;
                            this.displayLoader(false);
                            return [7 /*endfinally*/];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        } }),
});
