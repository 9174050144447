import { __awaiter, __generator } from "tslib";
import { CdpService } from "@/services/models/purchaseRequest/cdp";
var moduleCdp = {
    namespaced: true,
    state: {
        cdp: [],
        cdpStateUser: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        loading: false,
    },
    getters: {
        getCdp: function (state) {
            return state.cdp;
        },
        getCdpStateUser: function (state) {
            return state.cdpStateUser;
        },
        getLoading: function (state) {
            return state.loading;
        }
    },
    mutations: {
        setCdp: function (state, cdp) {
            state.cdp = cdp;
        },
        setCdpStateUser: function (state, cdpStateUser) {
            state.cdpStateUser = cdpStateUser;
        },
        setPageCdpStateUser: function (state, page) {
            state.cdpStateUser.current_page = page;
        },
        setLoading: function (state, loading) {
            state.loading = loading;
        }
    },
    actions: {
        getCdp: function (_a, id) {
            var commit = _a.commit;
            return __awaiter(this, void 0, Promise, function () {
                var CDP;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, CdpService.getPurchaseBuyerId(id)];
                        case 1:
                            CDP = _b.sent();
                            commit("setCdp", CDP);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        getCdpStateUser: function (_a, payLoad) {
            var commit = _a.commit, state = _a.state;
            return __awaiter(this, void 0, Promise, function () {
                var CDP;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, CdpService.getByStateUser(payLoad, state.cdpStateUser.current_page)];
                        case 1:
                            CDP = _b.sent();
                            commit("setCdpStateUser", CDP);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        }
    }
};
export default moduleCdp;
