import { __awaiter, __generator } from "tslib";
import { UserService } from "@/services/models/user/user";
import Vue from "vue";
export default Vue.extend({
    data: function () {
        return {
            dialog: false,
            isLoading: false,
            user: {
                current_password: "",
                password: "",
                password_confirmation: "",
            },
        };
    },
    methods: {
        save: function () {
            return __awaiter(this, void 0, void 0, function () {
                var error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!this.$refs.form.validate()) return [3 /*break*/, 7];
                            if (!(this.user.password == this.user.password_confirmation)) return [3 /*break*/, 6];
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, 4, 5]);
                            this.isLoading = true;
                            return [4 /*yield*/, UserService.getChangePasswd(JSON.parse(localStorage.getItem("user") || "{}").id, this.user)];
                        case 2:
                            _a.sent();
                            this.cleanForm(this, "form");
                            this.displayToast({
                                type: 'success',
                                message: 'Contraseña modificada con éxito'
                            });
                            this.dialog = false;
                            return [3 /*break*/, 5];
                        case 3:
                            error_1 = _a.sent();
                            this.displayToast({
                                type: "error",
                                message: "Hubo un error, favor de verificar los datos",
                            });
                            console.log(error_1);
                            return [3 /*break*/, 5];
                        case 4:
                            this.isLoading = false;
                            return [7 /*endfinally*/];
                        case 5: return [3 /*break*/, 7];
                        case 6:
                            this.displayToast({
                                type: "error",
                                message: "Ambas contraseñas no coinciden, favor de reintentar",
                            });
                            _a.label = 7;
                        case 7: return [2 /*return*/];
                    }
                });
            });
        },
    },
    watch: {
        dialog: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (this.dialog) {
                        this.cleanForm(this, "form");
                    }
                    return [2 /*return*/];
                });
            });
        },
    },
});
