import { __assign, __awaiter, __generator } from "tslib";
import { TicketTypeService } from "@/services/models/ticket/ticketType";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
export default Vue.extend({
    props: {
        groupProp: {
            type: Object,
        },
    },
    data: function () {
        return {
            headers: [
                {
                    text: "Nombre",
                    sortable: false,
                    value: "name",
                },
                {
                    text: "Acciones",
                    sortable: false,
                    value: "actions",
                },
            ],
        };
    },
    computed: __assign({}, mapGetters({
        getTicketType: "ticketType/getTicketType",
        getLoading: "ticketType/getLoading",
    })),
    methods: __assign(__assign({}, mapActions({
        setTicketType: "ticketType/getTicketType",
    })), { removeTicket: function (ticketTypeId) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.displayLoader(true);
                            return [4 /*yield*/, TicketTypeService.remove(ticketTypeId)];
                        case 1:
                            _a.sent();
                            this.setTicketType(this.groupProp.id);
                            this.displayLoader(false);
                            return [2 /*return*/];
                    }
                });
            });
        } }),
    mounted: function () {
        this.setTicketType(this.groupProp.id);
    },
});
