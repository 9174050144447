import { __assign, __awaiter, __generator } from "tslib";
import { mapActions } from "vuex";
import Vue from "vue";
import { DocumentDetailService } from "@/services/models/document/documentDetail";
import { DocumentDetailChangesService } from "@/services/models/document/documentDetailChanges";
import VisorEditable from "./VisorEditable.vue";
import moment from "moment";
export default Vue.extend({
    name: "CreateTemplateDocument",
    props: {
        documentProp: {
            type: Object,
        },
    },
    components: {
        VisorEditable: VisorEditable,
    },
    data: function () {
        return {
            show: false,
            dialog: false,
            title: null
        };
    },
    watch: {
        dialog: function () {
            var _this = this;
            if (this.dialog) {
                this.displayLoader(true);
                this.show = false;
                this.$nextTick(function () {
                    _this.show = true;
                });
            }
            else {
                this.displayLoader(false);
            }
        },
    },
    methods: __assign(__assign({}, mapActions({
        getLoader: "loader/getState",
        getDocumentsDetail: "documentDetail/getByDocumentId",
    })), { 
        //Guardar pdf
        saveDocumentDetail: function () {
            return __awaiter(this, void 0, void 0, function () {
                var data, response, dataChange, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.displayLoader(true);
                            if (this.$refs.visorEditable.content == "" || this.title == null) {
                                this.displayToast({
                                    type: "error",
                                    message: "Favor de ingresar datos en la plantilla y un titulo correspondiente",
                                });
                                this.displayLoader(false);
                                return [2 /*return*/];
                            }
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 4, 5, 6]);
                            data = {
                                id: 0,
                                document_id: this.documentProp.id,
                                file_generated: this.$refs.visorEditable.content,
                                file_generated_name: this.title + "_" + moment().valueOf(),
                                state: "disponible",
                                user_id: this.getCurrentUserLoggedIn().id,
                            };
                            return [4 /*yield*/, DocumentDetailService.create(data)];
                        case 2:
                            response = _a.sent();
                            dataChange = {
                                id: 0,
                                user_id: this.getCurrentUserLoggedIn().id,
                                document_detail_id: response.id,
                                file_generated: this.$refs.visorEditable.content,
                            };
                            return [4 /*yield*/, DocumentDetailChangesService.create(dataChange)];
                        case 3:
                            _a.sent();
                            this.getDocumentsDetail(this.documentProp.id);
                            this.displayLoader(false);
                            this.displayToast({
                                type: "success",
                                message: "Documento Generado",
                            });
                            return [3 /*break*/, 6];
                        case 4:
                            error_1 = _a.sent();
                            console.log(error_1);
                            return [3 /*break*/, 6];
                        case 5:
                            this.displayLoader(false);
                            this.dialog = false;
                            return [7 /*endfinally*/];
                        case 6: return [2 /*return*/];
                    }
                });
            });
        },
        savePdf: function (blob) {
            return __awaiter(this, void 0, void 0, function () {
                var formData;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            formData = new FormData();
                            formData.append("document_id", "13");
                            formData.append("user_id", JSON.parse(localStorage.getItem("user") || "{}").id);
                            formData.append("state", "disponible");
                            formData.append("file", blob);
                            return [4 /*yield*/, DocumentDetailService.create(formData, {
                                    "Content-Type": "multipart/form-data",
                                })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        dataURLtoFile: function (dataurl, filename) {
            var arr = dataurl.split(","), mime = arr[0].match(/:(.*?);/)[1], bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
        },
        //No Tocar >:c
        beforeDownload: function (_a) {
            var html2pdf = _a.html2pdf, options = _a.options, pdfContent = _a.pdfContent;
            return __awaiter(this, void 0, void 0, function () {
                var convertBaseToPdf, save;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            convertBaseToPdf = this.dataURLtoFile;
                            save = this.savePdf;
                            this.getLoader(true);
                            return [4 /*yield*/, html2pdf()
                                    .set(options)
                                    .from(pdfContent)
                                    .outputPdf()
                                    .then(function (pdf) {
                                    // Genera base64
                                    console.log(btoa(pdf));
                                    var file = convertBaseToPdf("data:text/plain;base64," + btoa(pdf), "hello.pdf");
                                    console.log(file);
                                    save(file);
                                })];
                        case 1:
                            _b.sent();
                            this.getLoader(false);
                            return [2 /*return*/];
                    }
                });
            });
        } }),
});
