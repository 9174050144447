import { __awaiter, __extends, __generator } from "tslib";
import { BaseService } from './../../base';
var DispatchLocationService = /** @class */ (function (_super) {
    __extends(DispatchLocationService, _super);
    function DispatchLocationService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(DispatchLocationService, "entity", {
        get: function () {
            return 'dispatch_location';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DispatchLocationService, "responseName", {
        get: function () {
            return 'dispatch_location';
        },
        enumerable: false,
        configurable: true
    });
    DispatchLocationService.getStablishmentId = function (stablishment_id) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_stablishment_id/" + stablishment_id,
                        responseName: 'dispatch'
                    })];
            });
        });
    };
    return DispatchLocationService;
}(BaseService()));
export { DispatchLocationService };
