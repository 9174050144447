import { __awaiter, __extends, __generator } from "tslib";
import { BaseService } from './../../base';
var TicketHistoryService = /** @class */ (function (_super) {
    __extends(TicketHistoryService, _super);
    function TicketHistoryService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(TicketHistoryService, "entity", {
        get: function () {
            return 'ticket_history';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TicketHistoryService, "responseName", {
        get: function () {
            return 'ticket_history';
        },
        enumerable: false,
        configurable: true
    });
    TicketHistoryService.getByTicketId = function (ticket_id) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_ticket_id/" + ticket_id
                    })];
            });
        });
    };
    return TicketHistoryService;
}(BaseService()));
export { TicketHistoryService };
