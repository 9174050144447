import { __awaiter, __generator } from "tslib";
import { TicketFileService } from "@/services/models/ticket/ticketFile";
import { TicketResponsableService } from "@/services/models/ticket/ticketResponsable";
import Vue from "vue";
import ButtonAddFileToTicket from "./ButtonAddFileToTicket.vue";
export default Vue.extend({
    name: "DetailTableRequestTicket",
    components: { ButtonAddFileToTicket: ButtonAddFileToTicket },
    props: {
        itemProp: {
            type: Object,
        },
    },
    data: function () {
        return {
            headersFiles: [
                {
                    text: "Nombre",
                    align: "start",
                    value: "file_name",
                    sortable: false,
                },
                {
                    text: "Usuario",
                    align: "start",
                    value: "user",
                    sortable: false,
                },
                {
                    text: "Archivo",
                    align: "start",
                    value: "file",
                    sortable: false,
                },
            ],
            headersResponsables: [
                {
                    text: "Nombre",
                    align: "start",
                    value: "fullname",
                    sortable: false,
                },
                {
                    text: "Observación",
                    align: "start",
                    value: "observation",
                    sortable: false,
                },
            ],
            isLoading: false,
            listFiles: [],
            listResponsables: [],
        };
    },
    mounted: function () {
        this.loadData();
    },
    methods: {
        showUserName: function (user) {
            var userName = "Sin información";
            if (user) {
                userName = user.name + " " + user.pather_lastname + " " + user.mother_lastname;
            }
            return userName;
        },
        loadData: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            this.isLoading = true;
                            return [4 /*yield*/, this.loadTickets()];
                        case 1:
                            _b.sent();
                            _a = this;
                            return [4 /*yield*/, TicketResponsableService.getByTicketId(this.itemProp.id)];
                        case 2:
                            _a.listResponsables = _b.sent();
                            this.isLoading = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
        loadTickets: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _a = this;
                            return [4 /*yield*/, TicketFileService.getByTicketId(this.itemProp.id)];
                        case 1:
                            _a.listFiles = _b.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    },
});
