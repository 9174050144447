import { __assign, __awaiter, __generator } from "tslib";
import { GroupService } from "@/services/models/group/group";
import { UserGroupService } from "@/services/models/group/userGroup";
import { TicketService } from "@/services/models/ticket/ticket";
import { TicketHistoryService } from "@/services/models/ticket/ticketHistory";
import { TicketResponsableService } from "@/services/models/ticket/ticketResponsable";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
    props: {
        itemProp: {
            type: Object,
            required: true
        },
    },
    data: function () {
        return {
            dialog: false,
            isLoading: false,
            userSelected: 0,
            listUser: [],
            listGroup: [],
            observation: "",
            transferOptions: ["Usuario", "Grupo"],
            transferBy: "Usuario",
            groupSelected: 0
        };
    },
    watch: {
        dialog: function () {
            if (this.dialog) {
                this.loadData();
            }
        },
    },
    methods: __assign(__assign({}, mapActions({
        setTickets: "ticket/getTicketFilter",
    })), { loadData: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            this.isLoading = true;
                            _a = this;
                            return [4 /*yield*/, UserGroupService.getByGroupId(this.getUserGroup().group_id)];
                        case 1:
                            _a.listUser = _c.sent();
                            _b = this;
                            return [4 /*yield*/, GroupService.getByStablishmentId(this.getCurrentUserLoggedIn().stablishment_id)];
                        case 2:
                            _b.listGroup = _c.sent();
                            if (this.listGroup.length !== 0) {
                                this.groupSelected = this.listGroup[0].id;
                            }
                            this.isLoading = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
        save: function () {
            return __awaiter(this, void 0, void 0, function () {
                var dataPost, dataPostHistory, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 6, 7, 8]);
                            this.displayLoader(true);
                            if (!(this.transferBy == "Usuario")) return [3 /*break*/, 2];
                            dataPost = {
                                user_id: this.userSelected,
                                ticket_id: this.itemProp.id,
                                observation: this.observation,
                            };
                            return [4 /*yield*/, TicketResponsableService.create(dataPost)];
                        case 1:
                            _a.sent();
                            return [3 /*break*/, 4];
                        case 2:
                            if (!(this.transferBy == "Grupo")) return [3 /*break*/, 4];
                            return [4 /*yield*/, TicketService.update(this.itemProp.id, __assign(__assign({}, this.itemProp), { group_id: this.groupSelected, state: "solicitado" }))];
                        case 3:
                            _a.sent();
                            _a.label = 4;
                        case 4:
                            dataPostHistory = {
                                user_id: this.getCurrentUserLoggedIn().id,
                                ticket_id: this.itemProp.id,
                                state: "Solicitud transferida",
                            };
                            return [4 /*yield*/, TicketHistoryService.create(dataPostHistory)];
                        case 5:
                            _a.sent();
                            this.setTickets();
                            this.dialog = false;
                            return [3 /*break*/, 8];
                        case 6:
                            error_1 = _a.sent();
                            console.log(error_1);
                            return [3 /*break*/, 8];
                        case 7:
                            this.displayLoader(false);
                            return [7 /*endfinally*/];
                        case 8: return [2 /*return*/];
                    }
                });
            });
        } }),
});
