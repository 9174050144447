import { __awaiter, __generator } from "tslib";
import Vue from 'vue';
import { ErrorWrapper } from "./util";
//BaseService es una función genérica que retorna una clase declarada localmente. 
//La clase retornada no es genérica, por lo tanto sus propiedades y métodos estáticos 
//tienen tipos concretos para T.
// eslint-disable-next-line
export function BaseService() {
    var BaseService = /** @class */ (function () {
        function BaseService() {
        }
        Object.defineProperty(BaseService, "entity", {
            get: function () {
                throw new Error('entity getter not defined');
            },
            enumerable: false,
            configurable: true
        });
        Object.defineProperty(BaseService, "responseName", {
            get: function () {
                throw new Error('name getter not defined');
            },
            enumerable: false,
            configurable: true
        });
        Object.defineProperty(BaseService, "profile", {
            get: function () {
                var profile = window.localStorage.getItem("perfil");
                return profile;
            },
            enumerable: false,
            configurable: true
        });
        /**
         * The method used to perform an AJAX-request.
         *
         * @param {string}      requestType The request type.
         * @param {string}      url         The URL for the request.
         * @param {string|null} name        The name of the object response.
         * @param {Record<string, unknown> |null} data        The data to be send with the request.
         * @param {Object|null} config      The config object to be sent with the request.
         *
         * @returns {Promise} The result in a promise.
         */
        BaseService.submit = function (submitData) {
            return __awaiter(this, void 0, Promise, function () {
                var path, response, responseName, data, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            path = submitData.isFullPath ? submitData.path : this.profile + "/" + this.entity + "/" + submitData.path;
                            return [4 /*yield*/, Vue.$http[submitData.method](path, submitData.method == "get" ? submitData.config : submitData.data, submitData.config)];
                        case 1:
                            response = _a.sent();
                            responseName = submitData.responseName ? submitData.responseName : this.responseName;
                            data = void 0;
                            data = response;
                            if (responseName && !submitData.showFullResponse) {
                                data = responseName ? response.data[responseName] : response.data;
                            }
                            if (submitData.config == null) {
                                return [2 /*return*/, data];
                            }
                            else {
                                if (submitData.config.showResponseWrapper) {
                                    return [2 /*return*/, data];
                                    // resolve(new ResponseWrapper(response, data));
                                }
                                else {
                                    return [2 /*return*/, data];
                                }
                            }
                            return [3 /*break*/, 3];
                        case 2:
                            error_1 = _a.sent();
                            if (error_1) {
                                // reject(new ErrorWrapper(error, ""));
                                throw new ErrorWrapper(error_1, "");
                                // reject(response);
                                // throw new ErrorWrapper(response)
                            }
                            else {
                                throw "Error";
                            }
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        };
        /**
         * ------------------------------
         * @HELPERS
         * ------------------------------
         */
        // static responseWrapper(...rest: any[]) {
        // 	return new ResponseWrapper(...rest)
        // }
        // static errorWrapper(...rest: any[]) {
        // 	return new ErrorWrapper(...rest)
        // }
        /**
         * ----------------------------
         * BASIC API CALLS
         * ----------------------------
         */
        BaseService.getAll = function () {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, this.submit({
                            method: 'get',
                            path: this.profile + "/" + this.entity,
                            isFullPath: true
                        })];
                });
            });
        };
        BaseService.getById = function (id) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, this.submit({
                            method: 'get',
                            path: "" + id,
                        })];
                });
            });
        };
        BaseService.create = function (data, config) {
            if (config === void 0) { config = {}; }
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, this.submit({
                            method: 'post',
                            path: this.profile + "/" + this.entity,
                            data: data,
                            config: config,
                            isFullPath: true
                        })];
                });
            });
        };
        BaseService.update = function (id, data) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, this.submit({
                            method: 'put',
                            path: "" + id,
                            data: data
                        })];
                });
            });
        };
        BaseService.remove = function (id) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, this.submit({
                            method: 'delete',
                            path: "" + id,
                        })];
                });
            });
        };
        BaseService.getTrash = function () {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, this.submit({
                            method: 'get',
                            path: "trash",
                        })];
                });
            });
        };
        BaseService.restore = function (id) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, this.submit({
                            method: 'delete',
                            path: "activate/" + id
                        })];
                });
            });
        };
        return BaseService;
    }());
    return BaseService;
}
