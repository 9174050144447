import { __awaiter, __generator } from "tslib";
import { StablishmentService } from "@/services/models/stablishment";
var moduleUser = {
    namespaced: true,
    state: {
        stablishments: [],
        loading: false,
        stablishmentSelected: null
    },
    getters: {
        getStablishments: function (state) {
            return state.stablishments;
        },
        getLoading: function (state) {
            return state.loading;
        },
        getStablishmentSelected: function (state) {
            return state.stablishmentSelected;
        }
    },
    mutations: {
        loadRoles: function (state, stablishments) {
            state.stablishments = stablishments;
        },
        changeLoading: function (state, loading) {
            state.loading = loading;
        },
        changeSatblishmentSelected: function (state, stablishmentSelected) {
            state.stablishmentSelected = stablishmentSelected;
        }
    },
    actions: {
        getStablishments: function (_a) {
            var commit = _a.commit;
            return __awaiter(this, void 0, Promise, function () {
                var stablishment;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('changeLoading', true);
                            return [4 /*yield*/, StablishmentService.getAll()];
                        case 1:
                            stablishment = _b.sent();
                            commit("loadRoles", stablishment);
                            commit('changeLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        }
    }
};
export default moduleUser;
