import { __assign, __awaiter, __generator } from "tslib";
import { DocumentStateService } from "@/services/models/document/documentState";
import { FolderService } from "@/services/models/document/folder";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
    props: {
        documentProp: {
            type: Object,
        },
    },
    data: function () {
        return {
            dialog: false,
            items: [],
            folderIdSelected: 0,
        };
    },
    watch: {
        dialog: function () {
            if (this.dialog) {
                this.loadData();
            }
        },
    },
    methods: __assign(__assign({}, mapActions({
        setDocument: "tabsDocument/getLoadTable",
    })), { loadData: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            this.displayLoader(true);
                            _a = this;
                            return [4 /*yield*/, FolderService.getByUserId(this.getCurrentUserLoggedIn().id)];
                        case 1:
                            _a.items = _b.sent();
                            this.displayLoader(false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        save: function () {
            return __awaiter(this, void 0, void 0, function () {
                var responseState, dataPut, dataRefresh, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.displayLoader(true);
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 4, 5, 6]);
                            return [4 /*yield*/, DocumentStateService.getByDocumentIdUserId(this.documentProp.id, this.getCurrentUserLoggedIn().id)];
                        case 2:
                            responseState = _a.sent();
                            dataPut = __assign(__assign({}, responseState[0]), { folder_id: this.folderIdSelected });
                            return [4 /*yield*/, DocumentStateService.update(responseState[0].id, dataPut)];
                        case 3:
                            _a.sent();
                            dataRefresh = {
                                user_id: this.getCurrentUserLoggedIn().id,
                                folder_id: this.$route.params.id,
                            };
                            this.setDocument(dataRefresh);
                            this.displayLoader(false);
                            return [3 /*break*/, 6];
                        case 4:
                            error_1 = _a.sent();
                            console.log(error_1);
                            return [3 /*break*/, 6];
                        case 5:
                            this.displayLoader(false);
                            return [7 /*endfinally*/];
                        case 6: return [2 /*return*/];
                    }
                });
            });
        } }),
});
