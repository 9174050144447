import { __awaiter, __extends, __generator } from "tslib";
import { BaseService } from './../../base';
var BuyerService = /** @class */ (function (_super) {
    __extends(BuyerService, _super);
    function BuyerService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(BuyerService, "entity", {
        get: function () {
            return 'buyer';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BuyerService, "responseName", {
        get: function () {
            return 'buyer';
        },
        enumerable: false,
        configurable: true
    });
    BuyerService.getPurchaseRequestIdUserBuyerId = function (purchaseRequestId, userBuyerId) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_purchase_request_id_user_buyer_id/" + purchaseRequestId + "/" + userBuyerId
                    })];
            });
        });
    };
    BuyerService.getFindPurchaseRequestId = function (purchaseRequestId) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_purchase_request_id/" + purchaseRequestId
                    })];
            });
        });
    };
    BuyerService.getFindByUserBuyerIdState = function (userId, state, page) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_user_buyer_id/" + userId + "/" + state + "?page=" + page
                    })];
            });
        });
    };
    return BuyerService;
}(BaseService()));
export { BuyerService };
