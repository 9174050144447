import { __awaiter, __generator } from "tslib";
import { ModuleService } from "@/services/models/user/module";
import Vue from "vue";
export default Vue.extend({
    name: "UserModules",
    data: function () {
        return {
            modules: [],
            loadingModule: false,
            usersModules: [],
            module_id: 0,
            headers: [
                {
                    text: "Módulo",
                    sortable: false,
                    value: "module.name",
                },
                {
                    text: "Permisos",
                    sortable: false,
                    value: "permission",
                },
                {
                    text: "Eliminar",
                    sortable: false,
                    value: "delete",
                },
            ],
        };
    },
    mounted: function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.loadingModule = true;
                        _a = this;
                        return [4 /*yield*/, ModuleService.getAll()];
                    case 1:
                        _a.modules = _b.sent();
                        this.loadingModule = false;
                        return [2 /*return*/];
                }
            });
        });
    },
    methods: {
        deleteModule: function (userModule) {
            this.usersModules = this.usersModules.filter(function (userModuleValue) { return userModuleValue.module_id != userModule.module_id; });
        },
        addModule: function () {
            var _this = this;
            if (!this.module_id) {
                this.displayToast({
                    type: "warning",
                    message: "Por favor seleccionar un módulo",
                });
                return;
            }
            var isModuleAdded = this.usersModules.find(function (userModule) { return userModule.module_id == _this.module_id; });
            if (isModuleAdded) {
                this.displayToast({
                    type: "warning",
                    message: "El usuario ya tiene asociado este módulo",
                });
                return;
            }
            var moduleSelected = this.modules.find(function (module) { return module.id == _this.module_id; });
            this.usersModules.push({
                id: 0,
                name: "test",
                module_id: this.module_id,
                user_id: 0,
                module: moduleSelected,
            });
            this.$emit("loadUsersModules", this.usersModules);
            this.$refs.myForm.reset();
        },
    },
});
