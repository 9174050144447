import Vue from "vue";
import TableListCdp from "./TableListCdp.vue";
export default Vue.extend({
    components: { TableListCdp: TableListCdp },
    props: {
        purchaseBuyerProps: {
            type: Object
        }
    },
    data: function () {
        return {
            dialog: false,
        };
    }
});
