import { __assign, __awaiter, __generator } from "tslib";
import { DispatchService } from "@/services/models/dispatch/dispatch";
import { DispatchFileService } from "@/services/models/dispatch/dispatch_file";
import Vue from "vue";
import { mapActions } from "vuex";
import TabCreateDispatch from "./TabCreateDispatch.vue";
export default Vue.extend({
    components: { TabCreateDispatch: TabCreateDispatch },
    data: function () {
        return {
            dialog: false,
        };
    },
    methods: __assign(__assign({}, mapActions({
        setDocument: "dispatch/getDispatch",
    })), { save: function () {
            var _a;
            return __awaiter(this, void 0, void 0, function () {
                var dataDispatch, dataFiles, response, i, formData, error_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (!this.$refs.tabCreate.$refs.formDispatch.returnRef()) {
                                this.displayToast({
                                    type: "error",
                                    message: "Favor de llenar todos los campos requeridos",
                                });
                                return [2 /*return*/];
                            }
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, 7, 8, 9]);
                            this.displayLoader(true);
                            dataDispatch = this.$refs.tabCreate.$refs.formDispatch.dispatch;
                            dataFiles = ((_a = this.$refs.tabCreate.$refs.formFiles) === null || _a === void 0 ? void 0 : _a.files) || [];
                            return [4 /*yield*/, DispatchService.create(dataDispatch)];
                        case 2:
                            response = _b.sent();
                            i = 0;
                            _b.label = 3;
                        case 3:
                            if (!(i < dataFiles.length)) return [3 /*break*/, 6];
                            formData = new FormData();
                            formData.append("dispatch_id", "" + response.id);
                            formData.append("file", dataFiles[i]);
                            return [4 /*yield*/, DispatchFileService.create(formData, {
                                    "Content-Type": "multipart/form-data",
                                })];
                        case 4:
                            _b.sent();
                            _b.label = 5;
                        case 5:
                            i++;
                            return [3 /*break*/, 3];
                        case 6:
                            this.setDocument(this.getCurrentUserLoggedIn().stablishment_id);
                            this.displayToast({
                                type: "success",
                                message: "Se guardo el despacho sin problemas",
                            });
                            this.dialog = false;
                            return [3 /*break*/, 9];
                        case 7:
                            error_1 = _b.sent();
                            console.log(error_1);
                            this.displayToast({
                                type: "error",
                                message: "Error al guardar",
                            });
                            return [3 /*break*/, 9];
                        case 8:
                            this.displayLoader(false);
                            return [7 /*endfinally*/];
                        case 9: return [2 /*return*/];
                    }
                });
            });
        } }),
});
