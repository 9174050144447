import Vue from "vue";
import AddDocumentDetail from "./AddDocumentDetail.vue";
import CreateTemplateDocument from "./createTemplateDocument/CreateTemplateDocument.vue";
export default Vue.extend({
    name: "MenuAddDocument",
    components: { AddDocumentDetail: AddDocumentDetail, CreateTemplateDocument: CreateTemplateDocument },
    props: {
        documentProp: {
            required: true,
        },
    },
    data: function () {
        return {
            menu: false,
        };
    },
});
