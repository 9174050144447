import { __awaiter, __generator } from "tslib";
import { generatePDFTickets } from "@/reports/reportTickets";
import { GroupService } from "@/services/models/group/group";
import { TicketService } from "@/services/models/ticket/ticket";
import moment from "moment";
import Vue from "vue";
export default Vue.extend({
    data: function () {
        return {
            startDate: null,
            endDate: null,
            groups: [],
            loadingGroups: false,
            groupId: null,
        };
    },
    mounted: function () {
        this.getUserGroups();
    },
    methods: {
        getUserGroups: function () {
            var _a;
            return __awaiter(this, void 0, void 0, function () {
                var groupsByStablishment, userLoggedIn;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            this.loadingGroups = true;
                            return [4 /*yield*/, GroupService.getByStablishmentId(this.getCurrentUserLoggedIn().stablishment_id)];
                        case 1:
                            groupsByStablishment = _b.sent();
                            userLoggedIn = this.getCurrentUserLoggedIn();
                            if ((_a = userLoggedIn.users_groups) === null || _a === void 0 ? void 0 : _a.length) {
                                this.groups = groupsByStablishment.filter(function (group) { var _a; return (_a = userLoggedIn.users_groups) === null || _a === void 0 ? void 0 : _a.map(function (user_group) { return user_group.group_id; }).includes(group.id); });
                                if (this.groups.length) {
                                    this.groupId = this.groups[0].id;
                                }
                            }
                            this.loadingGroups = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
        downloadReport: function () {
            return __awaiter(this, void 0, void 0, function () {
                var minDate, maxDate, excelResponse, a, url, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!this.startDate || !this.endDate || !this.groupId) {
                                this.displayToast({
                                    type: "warning",
                                    message: "Por favor seleccione grupo y rango de fecha",
                                });
                                return [2 /*return*/];
                            }
                            this.displayLoader(true);
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            minDate = moment(this.startDate).format("YYYY-MM-DD") + " 00:00:00";
                            maxDate = moment(this.endDate).format("YYYY-MM-DD") + " 23:59:59";
                            return [4 /*yield*/, TicketService.getExcelReport(minDate, maxDate, this.groupId)];
                        case 2:
                            excelResponse = _a.sent();
                            console.log(excelResponse);
                            a = document.createElement("a");
                            url = window.URL.createObjectURL(excelResponse.data);
                            a.href = url;
                            a.download = "REPORTE_" + moment(this.startDate).format("DD-MM-YYYY") + "_" + moment(this.endDate).format("DD-MM-YYYY") + ".xlsx";
                            document.body.append(a);
                            a.click();
                            a.remove();
                            window.URL.revokeObjectURL(url);
                            return [3 /*break*/, 4];
                        case 3:
                            error_1 = _a.sent();
                            this.displayToast({
                                type: "error",
                                message: "Los sentimos, ha ocurrido un error",
                            });
                            return [3 /*break*/, 4];
                        case 4:
                            this.displayLoader(false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        downloadPdfReport: function () {
            return __awaiter(this, void 0, void 0, function () {
                var start, end, tickets;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!this.startDate || !this.endDate || !this.groupId) {
                                this.displayToast({
                                    type: "warning",
                                    message: "Por favor seleccione grupo y rango de fecha",
                                });
                                return [2 /*return*/];
                            }
                            start = moment(this.startDate).format("YYYY-MM-DD");
                            end = moment(this.endDate).format("YYYY-MM-DD");
                            this.displayLoader(true);
                            return [4 /*yield*/, TicketService.getByDateAndGroup(start + " 00:00:00", end + " 23:59:59", this.groupId)];
                        case 1:
                            tickets = _a.sent();
                            generatePDFTickets({
                                tickets: tickets,
                                dateStart: start,
                                dateEnd: end,
                                user: this.getCurrentUserLoggedIn(),
                                groupId: this.groupId,
                            });
                            this.displayLoader(false);
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
});
