import { __awaiter, __generator } from "tslib";
import { PurchaseBuyerService } from "@/services/models/purchaseRequest/purchaseBuyer";
import Vue from "vue";
export default Vue.extend({
    props: {
        purchaseProp: {
            type: Object,
        },
        preData: {
            type: Object
        }
    },
    mounted: function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.preData) return [3 /*break*/, 2];
                        this.displayLoader(true);
                        _a = this;
                        return [4 /*yield*/, PurchaseBuyerService.getById(this.preData.id)];
                    case 1:
                        _a.data = _b.sent();
                        this.displayLoader(false);
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    },
    data: function () {
        return {
            menuProps: {
                disabled: false,
            },
            data: {
                id: 0,
                user_id: this.getCurrentUserLoggedIn().id,
                purchase_request_id: this.purchaseProp.id,
                observation: "",
                n_order: "",
                n_licitation: "",
                state: "creado",
            },
        };
    },
    methods: {
        validateForm: function () {
            if (!this.$refs.formPurchaseBuyer.validate()) {
                return false;
            }
            return true;
        },
    },
});
