import { __assign } from "tslib";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import DetailTableCdp from "../DetailTableCdp.vue";
export default Vue.extend({
    components: {
        DetailTableCdp: DetailTableCdp,
    },
    data: function () {
        return {
            page: 1,
            headers: [
                {
                    text: "Id del CDP",
                    sorteable: false,
                    value: "id",
                },
                {
                    text: 'Id del Registro',
                    sorteable: false,
                    value: 'purchase_buyer_id'
                },
                {
                    text: "Comprador",
                    sorteable: false,
                    value: "user",
                },
                {
                    text: "Firmante",
                    sorteable: false,
                    value: "firmUser",
                },
                {
                    text: "Acciones",
                    sorteable: false,
                    value: "actions",
                },
            ],
        };
    },
    computed: __assign({}, mapGetters({
        getCdp: "cdp/getCdpStateUser",
        getLoading: "cdp/getLoading",
    })),
    methods: __assign(__assign({}, mapActions({
        setCdp: "cdp/getCdpStateUser",
    })), { changePage: function () {
            this.$store.commit("cdp/setPageCdpStateUser", this.page);
            this.setCdp({
                state: "firmado",
                user: this.getCurrentUserLoggedIn().id,
            });
        } }),
    mounted: function () {
        this.changePage();
    },
});
