import { __awaiter, __generator } from "tslib";
import { PurchaseRequestService } from "@/services/models/purchaseRequest/purchaseRequest";
var moduleSupplyManager = {
    namespaced: true,
    state: {
        pending: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        ready: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        loading: false,
    },
    getters: {
        getPending: function (state) {
            return state.pending;
        },
        getReady: function (state) {
            return state.ready;
        },
        getLoading: function (state) {
            return state.loading;
        }
    },
    mutations: {
        setPending: function (state, pending) {
            state.pending = pending;
        },
        setReady: function (state, ready) {
            state.ready = ready;
        },
        setPagePending: function (state, page) {
            state.pending.current_page = page;
        },
        setPageReady: function (state, page) {
            state.ready.current_page = page;
        },
        setLoading: function (state, loading) {
            state.loading = loading;
        }
    },
    actions: {
        getPending: function (_a, payload) {
            var commit = _a.commit, state = _a.state;
            return __awaiter(this, void 0, Promise, function () {
                var pending;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, PurchaseRequestService.getByStateStep(payload, state.pending.current_page)];
                        case 1:
                            pending = _b.sent();
                            commit("setPending", pending);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        getReady: function (_a, payload) {
            var commit = _a.commit, state = _a.state;
            return __awaiter(this, void 0, Promise, function () {
                var ready;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, PurchaseRequestService.getByStateStep(payload, state.ready.current_page)];
                        case 1:
                            ready = _b.sent();
                            commit("setReady", ready);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        }
    }
};
export default moduleSupplyManager;
