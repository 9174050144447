import { __assign, __awaiter, __generator } from "tslib";
import { UserService } from "@/services/models/user/user";
import Vue from "vue";
export default Vue.extend({
    props: {
        changeStep: {
            required: false,
            type: Function,
        },
        changeUserLocationSelected: {
            required: true,
            type: Function,
        },
    },
    data: function () {
        return {
            userLocations: [],
            loadingProfiles: false,
            userLocationSelected: {},
        };
    },
    mounted: function () {
        this.loadingProfiles = true;
        this.loadUserLocations();
        this.loadingProfiles = false;
    },
    methods: {
        verifyUserLocation: function () {
            if (!this.userLocationSelected.id) {
                this.displayToast({
                    type: "warning",
                    message: "Por favor seleccione un perfil antes de continuar",
                });
                return;
            }
            this.changeStep(2);
            this.changeUserLocationSelected(this.userLocationSelected);
        },
        loadUserLocations: function () {
            return __awaiter(this, void 0, void 0, function () {
                var user, locations;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, UserService.getById(this.getCurrentUserLoggedIn().id)];
                        case 1:
                            user = _a.sent();
                            locations = [];
                            if (user.users_locations) {
                                locations = user.users_locations.map(function (location) { return (__assign(__assign({}, location), { completeName: (location.position ? location.position.name : "") + " " + (location.location ? location.location.name : "") })); });
                            }
                            this.userLocations = locations;
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
});
