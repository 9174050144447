import { __assign, __awaiter, __generator } from "tslib";
import InputSelectUsers from "@/components/document/ShowDocument/panelDetail/InputSelectUsers.vue";
import { UserGroupService } from "@/services/models/group/userGroup";
import Vue from "vue";
import { mapActions } from "vuex";
import TableListUsers from "./TableListUsers.vue";
import InputSelectStablishment from "@/components/document/ShowDocument/panelDetail/InputSelectStablishment.vue";
import { SELECT_GROUP_STABLISHMENT } from "@/others/constants/permissions";
export default Vue.extend({
    name: "ButtonListUsers",
    components: { TableListUsers: TableListUsers, InputSelectUsers: InputSelectUsers, InputSelectStablishment: InputSelectStablishment },
    props: {
        itemProp: {
            type: Object,
        },
        permissions: {
            type: Array
        }
    },
    data: function () {
        return {
            dialog: false,
            stablishmentPermission: SELECT_GROUP_STABLISHMENT
        };
    },
    mounted: function () {
        console.log(this.permissions.includes(this.stablishmentPermission));
    },
    methods: __assign(__assign({}, mapActions({
        setUserGroup: "userGroup/getUserGroup",
    })), { save: function () {
            return __awaiter(this, void 0, void 0, function () {
                var users, i, dataPost;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.displayLoader(true);
                            users = this.$refs.selectFunctinary.usersSelects;
                            i = 0;
                            _a.label = 1;
                        case 1:
                            if (!(i < users.length)) return [3 /*break*/, 4];
                            dataPost = {
                                id: 0,
                                user_id: users[i].id,
                                group_id: this.itemProp.id,
                            };
                            return [4 /*yield*/, UserGroupService.create(dataPost)];
                        case 2:
                            _a.sent();
                            _a.label = 3;
                        case 3:
                            i++;
                            return [3 /*break*/, 1];
                        case 4:
                            this.setUserGroup(this.itemProp.id);
                            this.displayLoader(false);
                            return [2 /*return*/];
                    }
                });
            });
        } }),
});
