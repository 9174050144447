var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-data-table',{staticClass:"elevation-5",attrs:{"headers":_vm.headers,"items":_vm.getTickets.data,"page":_vm.getTickets.current_page,"items-per-page":_vm.getTickets.per_page,"server-items-length":_vm.getTickets.total,"loading":_vm.getLoading,"hide-default-footer":"","dense":"","single-expand":false,"show-expand":""},on:{"update:page":function($event){return _vm.$set(_vm.getTickets, "current_page", $event)}},scopedSlots:_vm._u([{key:"item.data-table-expand",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('v-btn',{attrs:{"x-small":"","icon":"","color":"gray"},on:{"click":function($event){return expand(!isExpanded)}}},[(isExpanded)?_c('v-icon',[_vm._v("fa-chevron-up")]):_c('v-icon',[_vm._v("fa-chevron-down")])],1)]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Solicitudes Generadas")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"item.responsable",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getResponsable(item))+" ")]}},{key:"item.created_by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_by.name)+" "+_vm._s(item.created_by.pather_lastname)+" ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-chip',{attrs:{"color":_vm.getColor(item.state),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.state.toUpperCase())+" ")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('detail-table-request-ticket',{attrs:{"itemProp":item}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('button-history',{attrs:{"itemProp":item}})],1),(item.state=='cerrado')?_c('v-col',{attrs:{"cols":"2"}},[_c('button-re-open',{attrs:{"itemProp":item}})],1):_vm._e(),(item.state=='cerrado')?_c('v-col',{attrs:{"cols":"2"}},[_c('button-closed-ticket',{attrs:{"itemProp":item}})],1):_vm._e(),(item.state!='cerrado permanentemente')?_c('v-col',{attrs:{"cols":"2"}},[_c('modal-chat-ticket',{attrs:{"itemProp":item}})],1):_vm._e(),(item.state=='cerrado permanentemente')?_c('v-col',{attrs:{"cols":"2"}},[_c('button-show-closed-ticket',{attrs:{"itemProp":item}})],1):_vm._e()],1)]}}],null,true)}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.getTickets.last_page},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }