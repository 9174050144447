import { __awaiter, __extends, __generator } from "tslib";
import { BaseService } from './../../base';
var PurchaseRequestService = /** @class */ (function (_super) {
    __extends(PurchaseRequestService, _super);
    function PurchaseRequestService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(PurchaseRequestService, "entity", {
        get: function () {
            return 'purchase_request';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PurchaseRequestService, "responseName", {
        get: function () {
            return 'purchase_request';
        },
        enumerable: false,
        configurable: true
    });
    /**
     * para filtrar las solicitudes asociadas al usuario segun su estado y etapa
     * @param payload user_id del usuario logeado, state y step del purchase_request_state
     * @returns
     */
    PurchaseRequestService.getByStateStep = function (payload, page) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_state_step/" + payload.user_id + "/" + payload.state + "/" + payload.step + "?page=" + page
                    })];
            });
        });
    };
    PurchaseRequestService.getByUserId = function (user_id, page) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_user/" + user_id + "?page=" + page
                    })];
            });
        });
    };
    return PurchaseRequestService;
}(BaseService()));
export { PurchaseRequestService };
